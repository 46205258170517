import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../styles/module/reactivateModal.module.scss";
import {HalfCircleSpinner} from "react-epic-spinners";
import reactivateSubscriptionService from "../api/services/reactivateSubscriptionService";

const ReactivateModal = (props) => {

  const { onClose, open, planName } = props;
  const [showLoader, setShowloader] = useState(false);
  const [apiErrors, setapiErrors] = useState("")

  const handleClose = () => {
    setapiErrors("");
    onClose(false);
  };

  const reactivateMemberShip = () => {
    setShowloader(true)
    let data = { subscription_id: props.subscription_id };
    
    // calling reactive subscription api
    reactivateSubscriptionService.reactivateMember(data).then((response) => {
      if (response.status === 200) {
        props.setIsSucess(true);
        setShowloader(false)

        // setting the success msg when the status=200
        props.setSuccessTextHeading(`Success: Subscription Renewed`);
        props.setSuccessTextMsg(`<p>You’ve successfully reactivated the account’s current subscription: ( <strong className="text3"> ${planName} </strong> )</p>
        <p>The account’s current subscription status is now Current and auto-renewal billing has been turned back on.</p>
        <div><strong className="text3">Note:</strong> Click Continue to view the account’s reactivated subscription details.</div>`);
        
        handleClose();
      } else {
        setShowloader(false)
        setapiErrors(response.message);
      }
    });
  };

  return (
    <div data-testid = "reactive_subscription_modal" >
      <Modal
        show={open}
        size="lg"
        className={styles.popupContainer}
        centered
        onHide={handleClose}
      >
        <Modal.Header className={styles.hedaerAlign}>
          {
            apiErrors !== "" && (
              <span data-testid="api_error" >{apiErrors}</span>
            )
          }
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={styles.motalTitle}
          >
            Enable Subscription
            <p>
              You can reactivate an account’s previously canceled subscription
              anytime.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBodyContent}>
          <p>
            The account’s current subscription is in{" "}
            <b className={styles.noteLabel}>PENDING CANCELATION</b>  status
            and auto-renewal billing for the account is turned off.
          </p>
          <p>
            Don’t worry though, you can quickly reactivate the account’s
            subscription by clicking the Enable Subscription button below.
          </p>
          <p>
            <b className={styles.noteLabel}>Note:</b> Upon reactivation, the
            account’s current subscription will change from Pending Cancelation to
            Current and auto-renewal billing will be turned back on.
          </p>
        </Modal.Body>
        <Modal.Footer className={styles.footerStyles}>
          <button data-testid = "go_back_btn" className={styles.btnStyles} onClick={handleClose}>
            Go Back
          </button>
          <button
            data-testid="reactive_subscription_button"
            className={styles.btnStylesCancel}
            onClick={reactivateMemberShip}
            disabled={showLoader}
          >
            {showLoader && (
              <HalfCircleSpinner size={18}
              style={{
                display: "inline-block",
                marginRight: "5px",
              }}
            />
            )}              
            Enable Subscription
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReactivateModal;
