import $ from "jquery";

$(window).on("scroll", function () {
  if ($(this).scrollTop() > 50) {
    $("#pageHeader").addClass("positionSticky");
  } else {
    $("#pageHeader").removeClass("positionSticky");
  }
  
	if($(window).scrollTop() > 200){
		$('.btnHelp').css({"opacity": "1", "visibility": "visible"});
	} else{
    $('.btnHelp').css({"opacity": "0", "visibility": "hidden"});
  }
});

$(document).ready(function(){

  if($(window).width() < 768){
  	$('.navbarNav > li.dropdown > a').on("click", function(e){
      e.preventDefault();
      $('.dropDownMenu').removeClass('show');
      $('.navbarNav > li.dropdown').removeClass('show');
      $(this).next('.dropDownMenu').parent('li').toggleClass('show');
      $(this).next('.dropDownMenu').toggleClass('show');
   });
  }
  
  $(document).on('click', '.coupOpener', function (e) {
    e.preventDefault();

    if ($(this).parent('.coupenSetWrap').hasClass('active')) {
      $(this).parent('.coupenSetWrap').removeClass('active');
    } else {
      $(this).parent('.coupenSetWrap').addClass('active');
    }
  });
  
  if(process?.env?.REACT_APP_PAYMNET_REBRANDING === 'true') {
    $('body').addClass('paymentRebranding')
  }
});
