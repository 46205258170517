import httpClient from "../httpClient";
import { API_VALIDATE_RECAPTCHA } from "../config";
import {RECAPTCHA_TOKEN_PARAM} from "../../utility/retrieveReCaptcha";

const ValidateReCaptchaService = {
	/**
	 * Request backend validation of Recaptcha attempt.
	 *
	 * @param {string} token
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
  validateReCaptcha: async (token) => httpClient.post(API_VALIDATE_RECAPTCHA, {
    [RECAPTCHA_TOKEN_PARAM]: token,
  }),
};

export default ValidateReCaptchaService;
