import React from 'react'
import styles from "../../styles/module/dashboard.module.scss";
import classNames from "classnames";
import infoIcn from "../../assets/images/info-icon.svg";

// const Dependantslist = ({ user, dashboard_json }) => {
const Dependantslist = ({user}) => {
    
    return (
        <div data-testid = "dependent_list" className='depTableWrap'>
            {
                Boolean(user?.Dependents.length) &&
                <div className={classNames(styles.membershipWrapper, "pt-0")}>
                    <div
                        className={classNames(
                            styles.paymentHeadingWrapper,
                            "d-flex justify-content-between"
                        )}
                    >
                        <div>
                            <h1 className={styles.memSubHeading}>
                                Dependents
                                <div className={styles.tooltip}>
                                    <img
                                        src={infoIcn}
                                        alt="info-icon"
                                        width={20}
                                    />
                                    <span className={styles.tooltiptext}>
                                        Refer to the Subscription Details section below to add dependents to an active or inactive account
                                    </span>
                                </div>
                            </h1>
                        </div>
                    </div>
                    <div className={`${styles.tableContainer} dpTable`}>
                        <ul className="dpndtList">
                            {
                                user.Dependents.map((dep, index) => (
                                    <li key={index}><strong>Dependant #{index + 1}:</strong> {dep.person.first_name} {dep.person.last_name}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}

export default Dependantslist
