import React, { useState, useEffect, useRef } from "react";
import { Drawer } from "../drawer";
import styles from "../../styles/module/adddependent.module.scss";
import classNames from "classnames";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "../shared-components/paymentDetails/PaymentDetails";
import addDepedentsService from "../../api/services/addDependentsService";
import { HalfCircleSpinner } from "react-epic-spinners";
import ErrorHandler from "../ErrorHandler";
import AddDependentsInput from '../addDependents'
import ApplyCoupon from '../shared-components/applyCoupon/applyCoupon';

export const currencyFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
}).format(value);

export const AddDependentDrawer = (props) => {

  const [stipePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY))
  const [addDependents, setAddDependents] = useState(0);
  const [radioValue, setRadioValue] = useState(props?.card !== null ? "existingCard" : "newCard");
  const [lastDigit, setLastDigitadioValue] = useState(props?.card !== null ? props?.card : "");
  const [errors, setErrors] = useState({});
  const [cardSource, setCardSource] = useState(null);
  const [stripeError, setStripeError] = useState({});
  const [formSubmitted, setSubmitted] = useState(0);
  const [Addandcharge, setAddandcharge] = useState(true);
  const [apiErrors, setApiErrors] = useState();
  const [loader, setLoader] = useState(false);
  const [pricingDetails, setPricingDetails] = useState();
  const [existingDep, setExistingDep] = useState(props.user.Dependents.length ? props.user.Dependents.length : 0)
  const [numberOfMaxDep, setNumberOfMaxDep] = useState(props?.membershipDetails?.plan?.numberOfMaxDependents)

  let failureRef = useRef();
  const addDependentsRef = useRef()
  
  // coupon
  const [coupon, setCoupon] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(null)
  const [discountPercentage, setDiscountPercentage] = useState(null)

  // state to manage the price cap 
  const [priceCap, setPriceCap] = useState(props?.membershipDetails?.plan?.cap_limit !==null ? props?.membershipDetails?.plan?.cap_limit : 0)
  
  
  // handle price cap
  const priceCapHandle = () => { 
    if(priceCap === null || priceCap === 0){
      return addDependents
    }
    else {
      if(addDependents > priceCap - existingDep){
        return priceCap - existingDep
      }
      else {
        return addDependents
      }
    }
  }
  
  // this function will be called from the addDependent (child component to set the addDependent counts)
  const addDepCountHandler = (event) => {  
    setAddDependents(event);

    if (event >= 1) {
      setAddandcharge(false);
    }
  }

  useEffect(() => {
    let pricingDetails = {};
    props.membershipDetails.plan.price.map((prices) => {
      if (prices.frequency === "Annual") {
        if (prices.member_type === "Primary") {
          pricingDetails["annualPrimary"] = prices.price;
        } else {
          pricingDetails["annualDependent"] = prices.price;
        }
      } else if (prices.frequency === "Monthly") {
        if (prices.member_type === "Primary") {
          pricingDetails["monthlyPrimary"] = prices.price;
        } else {
          pricingDetails["monthlyDependent"] = prices.price;
        }
      } else {
        if (prices.member_type === "Primary") {
          pricingDetails["yearlyPrimary"] = prices.price;
        } else {
          pricingDetails["yearlyDependent"] = prices.price;
        }
      }
    });

    setPricingDetails(pricingDetails);
  }, [props.membershipDetails]);

  const findDifPeriod = () => {
    const endDate = new Date(props.membershipDetails?.EndDate);
    const currentDate = new Date();

    const diffInTime = endDate.getTime() - currentDate.getTime();

    const diffInDays = diffInTime / (1000 * 3600 * 24);
    if (diffInDays <= 30) {
      return true;
    } else if (diffInDays > 30) {
      return false;
    }
  };

  const calculateTotalAmount = (amount) => {
    if(discountAmount){
      amount = amount - discountAmount;
    }else if(discountPercentage){
      amount = amount -(amount * (discountPercentage /100 ))
    }
    return currencyFormat(amount);
  };
  
  const currencyFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (radioValue !== "existingCard") {
      if (validateForm()) {
        setSubmitted((prevState) => prevState + 1);
      } else {
        setLoader(false);
      }
    } else {
      submitForm();
    }
  };

  useEffect(() => {
    if (failureRef.current !== undefined) {
      failureRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [apiErrors]);

  useEffect(() => {
    if (errors) {
      let errorNodes = document.getElementsByClassName("errors-depend");
      let firstNode = errorNodes[0];
      firstNode?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [errors]);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // call the addDependents components validations
    if(addDependentsRef.current){
      isValid = addDependentsRef.current.addDependentsValidations()
    }

    setErrors(errors);
    return isValid;
  };

  const submitForm = () => {
    if (validateForm()) {
      setLoader(true);
      let memberDetails = [];
      
      if(addDependentsRef.current){
        memberDetails = addDependentsRef.current.getDependentsData()
      }
     
      let card, payment_method, is_last30days;

      if (findDifPeriod) {
        is_last30days = 0;
      } else {
        is_last30days = 1;
      }

      card = JSON.stringify(props?.card);

      if (radioValue === "newCard") {
        card = JSON.stringify(cardSource?.card);
        payment_method = cardSource?.id;
      }

      let requestBody = {
        members: JSON.stringify(memberDetails),
        card,
        payment_method,
        is_last30days,
        coupon: coupon
      };
  
      // calling add dependent api
      addDepedentsService.addDependents(requestBody).then((response) => {
        if (response.status === 200) {
          props.setOpen(false);
          document.body.style.overflow = "scroll";
          
          // set success msg when status is 200
          props.setIsSucess(true);
          setLoader(false);
          props.setSuccessTextHeading(`Success: New Dependents Added`);
          props.setSuccessTextMsg(`<p>You’ve successfully added new dependents to the account’s associated subscription. The new dependents are immediately eligible to receive your practice’s member-only discounts.</p>
          <div><strong className="text3">Note:</strong> If needed, please refer to your practice’s Member Fee Schedule to view a list of your practice’s members discounts by procedure type.</div>`);
        } else {
          setApiErrors(response.message);
          setLoader(false);
        }
      });
    }
  };

  useEffect(() => {
    if (cardSource) {
      submitForm();
    }
  }, [cardSource]);
  
  // console.log(pricingDetails);
  return (
    <>
      {
        props.open && (
          <Drawer open={props.open}>
            <div className={styles.DependentMainContainer} data-testid = "add_dependent_drawer">
              <div className="">
                <header className={styles.dependentHeader}>
                  <div className="d-flex align-items-start justify-content-between ">
                    <div className="">
                      <h2 className={styles.addHeading}>Add Dependent(s)</h2>
                      <p className={styles.addsubHeading}>
                        You may add new dependents to your annual subscription at any
                        time.
                      </p>
                    </div>
                    <div className={styles.closeWrapper}>
                      <button
                        data-testid="cancel_button_svg"
                        aria-label="Close panel"
                        className={styles.closebtn}
                        onClick={() => {
                          document.body.style.overflow = "scroll";
                          props.setOpen(false);
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={"adddependent_closeIcon__1q-il"}>
                          <path d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </header>

                {/* <!-- Divider container --> */}
                <form className="addDepDrawerForm" onSubmit={(event) => handleSubmit(event)}>
                  {apiErrors && (
                    <div className={styles.dependdivContainer} ref={failureRef}>ErrorHandler
                      <ErrorHandler apiErrors={apiErrors} />
                    </div>
                  )}
                  <div className="">
                    <div className={styles.dependdivContainer}>
                      <strong className={styles.mouthHeading}>
                        More mouths, more savings.
                      </strong>
                      <div className={styles.mouthPara}>
                        {`Currently, you have a total of ${existingDep} member(s) participating in
                        your membership.`}
                      </div>
                    </div>

                    <div className={styles.dependdivContainer}>
                      <div className="">
                        <div className={styles.dependentLabel}>
                        Would you like to add any dependents?
                        </div>
          
                        {/* add dependent component (in which we add the dependent details) */}
                        <AddDependentsInput 
                          ref = {addDependentsRef}
                          handleDepCount = {addDepCountHandler}
                          maxDep = {numberOfMaxDep - existingDep}
                        />
                      </div>
                    </div>
                    
                  </div>
                  
                  <div className={styles.cost}>
                    Adding dependents could increase subscription fee (see Checkout
                    Summary for details)
                  </div>
                  
                  <div className={styles.dependdivContainer}>
                    <div className={styles.reciept}>
                      <div className={styles.recptWrapper}>
                        <div className={styles.recptContainer}>
                          <div className={classNames(styles.rcptCont)}>
                            <div className="text-center">
                              <h1 className={classNames(styles.rcptHeading)}>
                                Checkout Summary
                              </h1>
                            </div>

                            <>
                              {props.membershipDetails?.frequency === "Monthly" ? (
                                <>
                                  <div
                                    className={classNames(
                                      styles.headingContext,
                                      styles.rightHeading,
                                      styles.upfront
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        "d-flex justify-content-between"
                                      )}
                                    >
                                      <h2
                                        className={classNames(
                                          styles.billingContext,
                                          styles.memberHead
                                        )}
                                      >
                                        {findDifPeriod() && "Upcoming"} Annual Fee
                                      </h2>
                                      <h2
                                        className={classNames(
                                          styles.billingContext,
                                          styles.memberContext,
                                          styles.memberHead,
                                          "text-right"
                                        )}
                                      >
                                        Amount
                                      </h2>
                                    </div>
                                    {findDifPeriod() && (
                                      <div
                                        className={classNames(
                                          styles.billing,
                                          styles.billingContext,
                                          styles.memberContext
                                        )}
                                      >
                                        {props.membershipDetails?.plan.price.map(
                                          (prices, index) => {
                                            if (
                                              prices.frequency ===
                                              "YearlyOneTimeSubscription"
                                            ) {
                                              if (prices.member_type === "Primary") {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="d-flex justify-content-between pb-2"
                                                  >
                                                    <div className={styles.leftHeading}>
                                                      Primary Member
                                                    </div>
                                                    <div
                                                      className={styles.rightHeading}
                                                    >
                                                      {currencyFormat(prices.price)}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          }
                                        )}
                                      </div>
                                    )}
                                    <div
                                      className={classNames(
                                        styles.billing,
                                        styles.billingContext,
                                        styles.memberContext
                                      )}
                                    >
                                      {props.membershipDetails?.plan.price.map(
                                        (prices, index) => {
                                          if (
                                            prices.frequency ===
                                            "YearlyOneTimeSubscription"
                                          ) {
                                            if (prices.member_type === "Dependent") {
                                              return (
                                                <div
                                                  key={index}
                                                  className="d-flex justify-content-between pb-2"
                                                >
                                                  <div className={styles.leftHeading}>
                                                    {addDependents} dependent(s) @ $
                                                    {prices.price}/yr
                                                  </div>
                                                  <div className={styles.rightHeading}>
                                                    {currencyFormat(
                                                      priceCapHandle() * prices.price
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    className={classNames(
                                      styles.headingContext,
                                      styles.rightHeading,
                                      styles.upfrontMonthly
                                    )}
                                  >
                                    Monthly Recurring
                                    {findDifPeriod() && (
                                      <div
                                        className={classNames(
                                          styles.billing,
                                          styles.billingContext,
                                          styles.memberContext
                                        )}
                                      >
                                        {props.membershipDetails.plan.price.map(
                                          (prices, index) => {
                                            if (prices.frequency === "Monthly") {
                                              if (prices.member_type === "Primary") {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="d-flex justify-content-between pb-2"
                                                  >
                                                    <div className={styles.leftHeading}>
                                                      Primary Member
                                                    </div>
                                                    <div
                                                      className={styles.rightHeading}
                                                    >
                                                      {currencyFormat(prices.price)}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          }
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={classNames(                                      
                                      styles.headingContext,
                                      styles.rightHeading,
                                      styles.upfront
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        styles.billing,
                                        styles.billingContext,
                                        styles.memberContext
                                      )}
                                    >
                                      {props.membershipDetails.plan.price.map(
                                        (prices, index) => {
                                          if (prices.frequency === "Monthly") {
                                            if (prices.member_type === "Dependent") {
                                              return (
                                                <div
                                                  key={index}
                                                  className="d-flex justify-content-between pb-2"
                                                >
                                                  <div className={styles.leftHeading}>
                                                    {addDependents} dependent(s) @ $
                                                    {prices.price}/mo
                                                  </div>
                                                  <div className={styles.rightHeading}>
                                                    {currencyFormat(
                                                      priceCapHandle() * prices.price
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                  {
                                    discountAmount || discountPercentage ?
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        styles.total,
                                        "d-flex justify-content-between py-2 pt-4"
                                      )}
                                    >
                                      <div className={classNames(styles.leftHeading)}>
                                        Discount: 
                                      </div>
                                      {
                                        discountAmount && currencyFormat(discountAmount)
                                      }
                                      {
                                        discountPercentage && discountPercentage + "%"
                                      }
                                    </div> : ""
                                  }
                                  <div
                                    className={classNames(
                                      styles.billingContext,
                                      styles.total,
                                      "d-flex justify-content-between py-2 pt-4"
                                    )}
                                  >
                                    <div className={classNames(styles.leftHeading)}>
                                      Total Cost: 
                                    </div>
                                    {findDifPeriod()
                                      ? calculateTotalAmount(
                                          pricingDetails?.yearlyPrimary +
                                            pricingDetails?.monthlyPrimary +
                                            priceCapHandle() *
                                              pricingDetails?.monthlyDependent +
                                              priceCapHandle() *
                                              pricingDetails?.yearlyDependent
                                        )
                                      : calculateTotalAmount(
                                        priceCapHandle() *
                                            pricingDetails?.monthlyDependent +
                                            priceCapHandle() *
                                              pricingDetails?.yearlyDependent
                                        )}
                                  </div>
                                  {/* {findDifPeriod() && (
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        styles.total,
                                        "d-flex justify-content-between border-0 p-0 "
                                      )}
                                    >
                                      <div className={classNames(styles.leftHeading)}>
                                        Current Period Cost:
                                      </div>
                                      $0.00
                                    </div>
                                  )} */}
                                  {findDifPeriod() && (
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        styles.total,
                                        "d-flex justify-content-between py-2 pt-4"
                                      )}
                                    >
                                      <div className={classNames(styles.leftHeading)}>
                                        Grand Total:
                                      </div>
                                      {calculateTotalAmount(
                                        pricingDetails?.yearlyPrimary +
                                          pricingDetails?.monthlyPrimary +
                                          priceCapHandle() *
                                            pricingDetails?.monthlyDependent +
                                            priceCapHandle() *
                                            pricingDetails?.yearlyDependent
                                      )}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div
                                    className={classNames(
                                      styles.headingContext,
                                      styles.rightHeading,
                                      styles.upfront
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        "d-flex justify-content-between"
                                      )}
                                    >
                                      <h2
                                        className={classNames(
                                          styles.billingContext,
                                          styles.memberHead
                                        )}
                                      >
                                        {findDifPeriod() && "Upcoming"} Annual Fee
                                      </h2>
                                      <h2
                                        className={classNames(
                                          styles.billingContext,
                                          styles.memberHead,
                                          "text-right"
                                        )}
                                      >
                                        Amount
                                      </h2>
                                    </div>
                                    {findDifPeriod() && (
                                      <div
                                        className={classNames(
                                          styles.billing,
                                          styles.billingContext,
                                          styles.memberContext
                                        )}
                                      >
                                        {props.membershipDetails.plan.price.map(
                                          (prices) => {
                                            if (prices.frequency === "Annual") {
                                              if (prices.member_type === "Primary") {
                                                return (
                                                  <div className="d-flex justify-content-between pb-2">
                                                    <div
                                                      className={styles.leftHeading}
                                                    >
                                                      Primary Member
                                                    </div>
                                                    <div
                                                      className={styles.rightHeading}
                                                    >
                                                      {currencyFormat(prices.price)}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          }
                                        )}
                                      </div>
                                    )}
                                    <div
                                      className={classNames(
                                        styles.billing,
                                        styles.billingContext,
                                        styles.memberContext
                                      )}
                                    >
                                      {props.membershipDetails.plan.price.map(
                                        (prices, index) => {
                                          if (prices.frequency === "Annual") {
                                            if (prices.member_type === "Dependent") {
                                              return (
                                                <div className="d-flex justify-content-between pb-2" key={index}>
                                                  <div className={styles.leftHeading}>
                                                    {addDependents} dependent(s) @ $
                                                    {prices.price}/yr
                                                  </div>
                                                  <div
                                                    className={styles.rightHeading}
                                                  >
                                                    {" "}
                                                    {currencyFormat(
                                                      priceCapHandle() * prices.price
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                  
                                  {
                                    discountAmount || discountPercentage ?
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        styles.total,
                                        "d-flex justify-content-between py-2 pt-4"
                                      )}
                                    >
                                      <div className={classNames(styles.leftHeading)}>
                                        Discount: 
                                      </div>
                                      {
                                        discountAmount && currencyFormat(discountAmount)
                                      }
                                      {
                                        discountPercentage && discountPercentage + "%"
                                      }
                                    </div> : ""
                                  }
                                  
                                  <div
                                    className={classNames(
                                      styles.billingContext,
                                      styles.total,
                                      "d-flex justify-content-between py-2 pt-4"
                                    )}
                                  >
                                    <div className={classNames(styles.leftHeading)}>
                                      Total Cost:{" "}
                                    </div>
                                    {
                                      findDifPeriod()
                                      ? calculateTotalAmount(
                                          pricingDetails?.annualPrimary +
                                          priceCapHandle() *
                                            pricingDetails?.annualDependent
                                      )
                                      : calculateTotalAmount(
                                        priceCapHandle() *
                                          pricingDetails?.annualDependent
                                      )
                                    }

                                  </div>
                                  {/* {findDifPeriod() && (
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        styles.total,
                                        "d-flex justify-content-between border-0 p-0"
                                      )}
                                    >
                                      <div className={classNames(styles.leftHeading)}>
                                        Current Period Cost:
                                      </div>
                                      $0.00
                                    </div>
                                  )} */}
                                  {findDifPeriod() && (
                                    <div
                                      className={classNames(
                                        styles.billingContext,
                                        styles.total,
                                        "d-flex justify-content-between py-2 pt-4"
                                      )}
                                    >
                                      <div className={classNames(styles.leftHeading)}>
                                        Grand Total:
                                      </div>
                                      {calculateTotalAmount(
                                        pricingDetails?.annualPrimary +
                                        priceCapHandle() *
                                            pricingDetails?.annualDependent
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className={styles.dependdivContainer}>
                    <ApplyCoupon
                      coupon = {setCoupon}
                      discountAmount = {setDiscountAmount}
                      percentageAmount = {setDiscountPercentage}
                    />
                  </div>
                  
                  <div className={styles.dependdivContainer}>
                    <div className={styles.mouthPara}>
                    {
                      props?.card !== null &&  (
                      <>
                      To add dependents to your membership, simply select your card on
                      file or add a new card:
                      <div
                        className={classNames(
                          styles.radioWrapper,
                          "radioRowWrap"
                        )}
                      >
                        {props?.card !== false && (
                          <>
                            <div
                              className={classNames(
                                styles.radioContainer,
                                "customRadioBox"
                              )}
                            >
                              <input
                                data-testid="already_added_card_radio"
                                id="existingCard"
                                type="radio"
                                className={classNames(
                                  styles.radioBtn,
                                  "fakeInput"
                                )}
                                value="existingCard"
                                checked={radioValue === "existingCard"}
                                onChange={(event) => setRadioValue(event.target.value)}
                              />
                              <label
                                htmlFor="existingCard"
                                className={classNames(
                                  styles.labelRadioCard,
                                  "fakeLable"
                                )}
                              >
                                Charge {lastDigit?.card_type} ending in {lastDigit?.last_4}
                              </label>
                            </div>
                          </>
                        )}
                        <div
                          className={classNames(
                            styles.radioContainer,
                            "customRadioBox"
                          )}
                        >
                          <input
                            data-testid="add_new_card_radio"
                            id="newCard"
                            name="cardinput"
                            type="radio"
                            className={classNames(
                              styles.radioBtn,
                              "fakeInput"
                            )}
                            value="newCard"
                            checked={radioValue === "newCard"}
                            onChange={(event) => setRadioValue(event.target.value)}
                          />
                          <label
                            htmlFor="newCard"
                            className={classNames(
                              styles.labelRadioAdd,
                              "fakeLable"
                            )}
                          >
                            Add New Card
                          </label>
                        </div>
                      </div>
                      </>
                      )
                    }
                      <div className={styles.newPaymentWrapper} data-testid="add_new_card_div">
                        {radioValue === "newCard" && (
                          <Elements stripe={stipePromise}>
                            <PaymentDetails
                              setCardSource={setCardSource}
                              stripeError={stripeError}
                              setStripeError={setStripeError}
                              formSubmitted={formSubmitted}
                              setLoader={setLoader}
                            />
                          </Elements>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.dependdivContainer}>
                    <div className={styles.mouthPara}>
                      <span className="text3">Note:</span> Adding dependents will not change your membership's annual
                      renewal date
                    </div>
                  </div>
                  
                  {/* <!-- Action buttons --> */}
                  <div className="">
                    <div
                      className={classNames(
                        styles.btnWrapper,
                        " d-flex justify-content-end"
                      )}
                    >
                      <div className={styles.cancelWrapper}>
                        <button
                          data-testid="cancel_button"
                          type="button"
                          className={styles.cancelBtn}
                          onClick={() => {
                            document.body.style.overflow = "scroll";
                            props.setOpen(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className={styles.addWrapper}>
                        <button
                        data-testid="add_charge_btn"
                          type="submit"
                          className={classNames(
                            Addandcharge || addDependents < 1
                              ? classNames(styles.addBtnDisable, styles.addBtn)
                              : styles.addBtn
                          )}
                          disabled={Addandcharge || loader || addDependents < 1}
                        >
                          {loader && (
                            <HalfCircleSpinner
                              size={20}
                              style={{
                                display: "inline-block",
                                marginRight: "8px",
                              }}
                            />
                          )}
                          Add &amp; Charge Now
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Drawer>
        )
      }
  </>
    
  );
};
