import React, { useState, useEffect } from "react";
import { useInput } from "../../hooks/useInput";
import { HalfCircleSpinner } from "react-epic-spinners";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import ResetPasswordService from '../../api/services/resetPasswordService';
import Modal from "react-bootstrap/Modal";
import popuptickicn from "../../assets/images/popup-tick.svg";
import styles from "../../styles/module/login.module.scss";
import popupStyles from "../../styles/module/resetPassword.module.scss";
import img01 from "../../assets/images/banner-img.jpg";
import EmailVerification from '../../api/services/verifyEmail';

// export default function ResetPassword(props) {
const ResetPassword = (props) => {
  const [Newpassword, bindNewPassword] = useInput("");
  const [Confirmpassword, bindConfirmPassword] = useInput("");
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  
  const [token] = useState(new URLSearchParams(props.location.search));
  
  const [show, setShow] = useState(false);

  const validateResetPwd = () => {
    let errors = {};
    let isValid = true;

    if (!Newpassword) {
      isValid = false;
      errors["Newpassword"] = "Please enter your New password.";
    }
    if (!Confirmpassword) {
      isValid = false;
      errors["Confirmpassword"] = "Please Confirm your password.";
    }else if(Newpassword !== Confirmpassword){
      isValid = false;
      errors['resetPwdErr'] = "Password not matching."
    }else { 
    }

    setErrors(errors);
    return isValid;
  };

  // Password Reset Email Verification
  useEffect(() => {
    let data = {token : token.get('token')}
    if (data.token !== '') {
      EmailVerification.emailVerification(data)
    }
  }, [])

  const resetPwdHandler = (event) => {
    event.preventDefault();
    let errors = {}
    if (validateResetPwd()) {
        setShowLoader(true);
        let data = { password : Newpassword, token : token.get('token'), group_key: ["smileselectmemberhip", "vantageonesavingsplan", "dentaldepot"] };
        
        ResetPasswordService.resetPass(data).then((response) => {            
          if (response.status === 200) {
            errors['successMsg'] = response.message;
            setShowLoader(false)
            setShow(true);
            
          }else {
            errors['resetPwdErr'] = response.message;
            setShowLoader(false) 
            setShow(false);
          }
        });
        setErrors(errors);
    }
  };
  
  const handleClose = () => {
    window.location.replace('/')
  };

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Reset Password | membersy";  
	}, []);

    return (

        <section className="container containerLarge login-wrapper">
            <div className="login-img">
                <img src={img01} className="img-fluid" alt="img description"/>
            </div>
            <div className="loginformbox">
              <div className="loginCol">
                <h1 id="main-heading-text" className="loginTitle text-center">Reset Password</h1>
                  {showLoader && (
                    <HalfCircleSpinner className="mx-auto" color="#fff" />
                  )}
                  <div className="loginFormWrapper">
                    <Form
                      onSubmit={(event) => resetPwdHandler(event)}
                      className={classNames(styles.loginForm)}
                    >
                      <div className='formContainer'>
                        <div className='inputWrapper'>
                          <label className='ccLabelText'>Password</label>
                          <input
                            type="password"
                            className={classNames(
                              'customInput formControl',
                              errors.Newpassword
                                ? "fcError"
                                : ""
                            )}
                            {...bindNewPassword}
                          />

                          {errors.Newpassword && (
                            <div className="formErrors">
                              {errors.Newpassword}
                            </div>
                          )}
                        </div>

                        <div className='inputWrapper'>
                          <label className='ccLabelText'>Confirm Password</label>
                          <input
                            type="password"
                            className={classNames(
                              'customInput formControl',
                              errors.Confirmpassword
                                ? "fcError"
                                : ""
                            )}
                            {...bindConfirmPassword}
                          />

                          {errors.Confirmpassword && (
                            <div className="formErrors">
                              {errors.Confirmpassword}
                            </div>
                          )}
                        </div>
                      </div>
                      {errors.resetFail && (
                        <div className="formContainerError">
                          {errors.resetFail}
                        </div>
                      )}

                      {errors.resetPwdErr && (
                          <div className="formContainerError">
                            <span>{errors.resetPwdErr}</span>
                          </div>
                      )}

                      <button 
                        type="submit"
                        className="loginBtn btn btnPrimary"
                        disabled={showLoader}
                        onClick={() => setShow()}
                        >
                            {showLoader && (
                            <HalfCircleSpinner
                                size={18}
                                style={{
                                display: "inline-block",
                                marginRight: "5px",
                                }}
                            />
                            )}
                            Reset Password
                      </button>
                    </Form>
                  </div>
                  <div data-testid="">
                    <Modal
                        show={show}
                        className={classNames(
                          popupStyles.popupContainer,
                          "resetPasswordModal"
                        )}
                        size="lg"
                        centered
                        dialogClassName={popupStyles.popupDialogue}
                        onHide={() => setShow(false)}
                        backdrop="static"
                    >
                        <Modal.Body 
                          className={classNames(styles.modalBodyContent, "pt-0 pb-4")}
                        >
                          <div className={popupStyles.popupImg}>
                            <img
                              src={popuptickicn}
                              alt="password-reset-successfull"
                            />
                          </div>
                          <h3 className={popupStyles.popupHeading}>
                            Password Changed!
                          </h3>
                          {
                            errors['successMsg'] && <p className="success_message mb-0">{errors['successMsg']}.</p>
                          }
                        </Modal.Body>
                        <Modal.Footer className={styles.footerStyles}>
                          <div className="w-100 text-center">
                            <a
                              href="/"
                              type="submit"
                              className={classNames(popupStyles.btnSecondary, "btn btnSecondary")}
                              >
                              Back to Home Page
                            </a>
                            <button
                              type="submit"
                              className={classNames(popupStyles.btnPrimary, "btn btnPrimary mx-3")}
                              onClick={()=>{
                                handleClose()
                              }}
                            >
                              Login
                            </button>
                          </div>
                        </Modal.Footer>
                    </Modal>
                  </div>
              </div>
            </div>
        </section>
    );
  }
  
export default ResetPassword;
