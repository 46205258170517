import React from "react";
import classNames from "classnames";
// import styles from "../styles/module/dependent/adddependent.module.scss";
import styles from "../styles/module/drawer.module.scss"

export const Drawer = (props) => {
  return (
    <>
      {props.open && (
        
          <div className={styles.drawerWrapper}>
          <div className={styles.drawerContainer}>
            <section className={styles.drawerOpen}>
              {/* <!--
        Slide-over panel, show/hide based on slide-over state.

        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-0"
          To: "translate-x-full"
      --> */}
              <div
                className={classNames(` ${
                  props.open ? styles.translateOpen : styles.translateClose
                } `)}
              >
                {props.children}
                {/* <CardDetailsDrawer open={props.open} setOpen={props.setOpen} /> */}
              </div>
            </section>
          </div>
          </div>
       
      )}
    </>
  );
};
