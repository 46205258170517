import React, { useState } from "react";
import HFD_logo from "../../assets/images/HFD_logo.svg";
import { SearchSelect } from "../shared-components/searchSelect";
import {HalfCircleSpinner} from "react-epic-spinners";
import { hfdDropdownOptions, hfdDropdownStyles, statesUS } from "../../data/join-now";
import { hfdRequestBody, getHFDauthenticLink } from "../../api/services/getHFDauthenticLink";

const HfdWidget = (props) => {
    const [errors, setErrors] = useState(false);
    const [totalCost, setTotalCost] = useState("");
    const [termsChecked, setTermsChecked] = useState(false);
    const [showLoader, setShowloader] = useState(false);
    const [disable, setDisable] = useState(true);

    const getProviderLink = () => {
        setShowloader(true)
        setErrors("")

        const selectedState = statesUS.filter((e) => e.label == props.user.state);
        props.user.StatoId = selectedState[0].value;
        props.user.TotalCost = totalCost.value;

        const requestBody = hfdRequestBody(props.user)

        getHFDauthenticLink.getLink(requestBody).then((res) => {
            setShowloader(false)
            setDisable(false)

            if (res.status === 200) {
                setTermsChecked(false)
                setTotalCost("")
                setDisable(true)
                let url = res.data
                window.open(url, '_blank', 'noopener, noreferrer');
            } else {
                setErrors(res.message)
            }
        })
    }

    return (
        <div className="hfdWidget">
            <h1 className="hfdMainTitle fontDmSans">Need help with financing for your next dental procedure?</h1>
            <article className="hfdCard">
                <div className="hfdCardImg">
                    <img src={HFD_logo} className="img-fluid" alt="Healthcare Finance Direct"/>
                </div>
                <div className="hfdCardContent">
                    <form className="hfdForm">
                        <div className="formRow">
                            <label className="formLabel">How much financing do you need?</label>
                            <div className="selectWrap">
                                <SearchSelect
                                    styles={hfdDropdownStyles}
                                    placeholder="Select Financing Amount..."
                                    options={hfdDropdownOptions}
                                    value={totalCost}
                                    onChange={(event) => setTotalCost(event)}
                                    theme={(theme) => ({...theme,
                                        borderRadius: 0,
                                        colors: {...theme.colors,
                                        primary: "#2563EB",
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="formRow">
                            <div className="fakeWrap">
                                <input 
                                    type="checkbox" 
                                    className="fakeInput"
                                    id="hfdTerm"
                                    checked={termsChecked}
                                    onChange={() => setTermsChecked(!termsChecked)}
                                />
                                <label htmlFor="hfdTerm" className="fakeLabel">By checking this box, I am confirming that the financing amount needed for my dental procedure was the amount giving to me by my dental provider.</label>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                type="button"
                                className="btn hfdBtnSecondary"
                                onClick={() => {
                                    props.setShowHfd(true)
                                    props.setShow(false)
                                }}
                            >Learn More</button>
                            <button
                                type="button"
                                className="btn hfdBtnPrimary"
                                disabled={(!termsChecked || totalCost === '') ? disable : showLoader}
                                onClick={() => { getProviderLink() }}
                            >
                                {showLoader && (
                                    <HalfCircleSpinner size={15}
                                        style={{
                                            display: "inline-block",
                                            marginRight: "10px",
                                            verticalAlign: "middle",
                                        }}
                                    />
                                )}
                                Apply Now
                            </button>
                        </div>
                        {errors && (
                            <small className="d-block w-100 text-center text-danger mt-2">{errors}</small>
                        )}
                    </form>
                </div>
            </article>
        </div>
    );
};

export default HfdWidget;