import React, { useEffect } from 'react';


const Custom404 = (props) => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "404 | membersy";
	}, []);

    return (       
        <section className="notFoundBlock">
            <div className="container">
                <h1><span className="nfWatermark">404</span>Page Not Found!</h1>
                <p>The page you are looking for could not found. <br />It might have been removed, renamed, or did not exist in the first place.</p>
                <a href="https://membersy.com/dental-professionals/" className="btn btnPrimary">Back to Home</a>
            </div>
        </section>
    );
}

export default Custom404;
