import React from "react";
import styles from "../styles/module/ErrorHandler.module.scss";
import ReactHtmlParser from "react-html-parser";
import classNames from "classnames";

function ErrorHandler(props) {
  return (
    <div data-testid= "error_message_handler" className={classNames(styles.failedWrapper, props.noMargin && "mb-0")}>
      <p className={styles.failHeading}>Unable to Process</p>
      <p className={styles.failReason}>
        Reason: {ReactHtmlParser(props.apiErrors)}
      </p>
    </div>
  );
}

export default ErrorHandler;
