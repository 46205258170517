import React, { useState, useEffect } from 'react'
import classNames from "classnames";
import moment from "moment";
import styles from "../../styles/module/dashboard.module.scss";
import { paymentsColumns } from "../../data/dashboard";
import { CardDetailsDrawer } from "../drawers/cardDetailsDrawer";
import SuccessModal from "../successModal";
import getPaymentInovice from "../../api/services/getPaymentInovice";
import {HalfCircleSpinner} from "react-epic-spinners";
import infoIcn from "../../assets/images/info-icon.svg";
import viewIcn from "../../assets/images/viewicn.svg";
import retrieveCustomerBalance from "../../api/services/retrieveCustomerBalance";

const PaymentTable = ({ dashboard_json, user}) => {

    const [openCardDetailsDrawer, setOpenCardDetailsDrawer] = useState(false);
    const [successTextHeading, setSuccessTextHeading] = useState("");
    const [successTextMsg, setSuccessTextMsg] = useState("");
    const [isSucess, setIsSucess] = useState(false);
    const [showLoader, setShowloader] = useState([]);
    const [customerBalance, setCustomerBalance] = useState('0.00');

    const paymentPdf = (e, id) => {
        e.preventDefault();
        const loader = [...showLoader];
        loader[id] = true;
        setShowloader(loader);
        getPaymentInovice.getInvoicePdf(id).then((res) => {
            if (res?.data) {
                if(res?.data?.receipt_url){
                    const pdfLink= res?.data?.receipt_url;
                    if(pdfLink){
                        const downloadLink = document.createElement("a");
                        downloadLink.href = pdfLink;
                        downloadLink.target = "_blank";
                        downloadLink.click();
                        downloadLink.remove();
                    }
                }
            }
            const loader = [...showLoader];
            loader[id] = false;
            setShowloader(loader);
        });
    }    

    useEffect(() => {
        if (process?.env?.REACT_APP_DISABLE_EARLYRENEW === 'true') {
            retrieveCustomerBalance.retrieveBalance().then((res) => {
                setCustomerBalance(res?.data?.customer_balance)
            });
        }
    }, [])

    return (
        <div className={classNames( styles.membershipWrapper, "payTableWrap")} data-testid = "payment_table">
            <div className="tableInnerWrap">
                <div
                    className={classNames(
                        styles.paymentHeadingWrapper,
                        "d-flex justify-content-between",
                        customerBalance !== "0.00" ? 'changeLayout' : ''
                    )}
                >
                    <div>
                        <h1 className={styles.memSubHeading}>
                            Payment Details
                            <div className={styles.tooltip}>
                                <img
                                    src={infoIcn}
                                    alt="info-icon"
                                    width={20}
                                />
                                <span className={styles.tooltiptext}>
                                    View previous subscription charges and account’s
                                    designated card on file for auto-renewal payments
                                </span>
                            </div>
                            <span className="cardWrap">
                                {user?.card ? (
                                    <>                       
                                        Card on file:&nbsp;
                                        <span className="ccInfo">
                                            <span className='ccVender'>
                                                {user?.card?.card_type}
                                            </span>
                                                &nbsp;ending in&nbsp;
                                            {user?.card?.last_4} (exp
                                            {" "}{user?.card?.expiry_date})
                                        </span>
                                    </>
                                ) : <>{" "}Currently there’s no designated card on file for this account</>
                                }
                                
                            </span>
                        </h1>
                    </div>
                    <div className={classNames(styles.deptBtnContainer, "noFlexShrink")}>
                        <div className="actBtnRow pb-0">
                            {customerBalance !== "0.00" && (
                                <div className='creditBlance'>Available Credit: ${customerBalance}</div>
                            )}
                            {user?.Memberships[0].status === "Pending Cancelation" || user?.Memberships[0].status === "Current" ? (
                                <>
                                    <button
                                        data-testid="update_card_detail_button"
                                        type="button"
                                        className={classNames(
                                            styles.btns,
                                            styles.wid100,
                                            "tbActBtn p-0 border-0"
                                        )}
                                        onClick={() => {
                                            document.body.style.overflow = "hidden";
                                            setOpenCardDetailsDrawer(!openCardDetailsDrawer);
                                        }}
                                    >
                                        Update Billing
                                    </button>
                                </>
                            ) : ""
                        }
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.tableContainer, "phTable dashbordTable")}>
                    <table className={classNames(styles.tableWrapper)}>
                        <thead>
                        <tr>
                            {paymentsColumns.map(({ title }) => (
                                <th className={styles.tabledivHeading} key={title}>
                                    {title}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody className="">
                        {user?.Memberships?.map((member) => {
                            return member.Payments?.map((payment, index) => {

                                let transactionType = payment.transaction_type.replace('_', ' ');
                                
                                // checking transaction type
                                if (payment.transaction_type === 'subscription') {
                                    transactionType = 'new';
                                }else if (payment.transaction_type === 'yearly one time' || payment.transaction_type === 'credit' || payment.transaction_type === 'payment method' || payment.transaction_type === 'dispute' || payment.transaction_type === 'free add on'){
                                    transactionType = '';
                                }

                                return (
                                <tr key={index}>
                                    <td className={styles.tablePara}>
                                        {moment(payment.created_at).format("MM-DD-YYYY")}
                                    </td>
                                    <td className={styles.tablePara}>
                                        ${payment.amount / 100}
                                    </td>
                                    <td className={styles.tablePara}>
                                        <span className='sbType statuSpan text-capitalize'>{transactionType}</span>
                                    </td>
                                    <td
                                        className={classNames(
                                            styles.tablePara,
                                            styles.paymentStatus
                                        )}
                                    >
                                        {
                                            payment.is_cc === "Check" && payment.check_cleared_at === null ?
                                            <span 
                                                className={classNames(
                                                    styles.statusBtn, 
                                                    styles.pendingBtn,
                                                    "statuSpan"
                                                )}
                                            >Pending</span>
                                            :
                                            <div className={classNames("statuSpan", payment.status === "Success" ? styles.statusBtn : classNames(styles.inactiveBtn, styles.statusBtn))}>
                                                {payment.status}
                                            </div>
                                        }
                                    </td>
                                    <td className={styles.tablePara}>
                                        <button 
                                            onClick={(e)=>{paymentPdf(e,payment?.id)}}
                                            target="_blank"
                                            disabled={showLoader[payment?.id]}
                                            className={classNames(styles.viewLink, "pdfLink p-0 bg-transparent border-0 position-relative")}
                                        >
                                            {showLoader[payment?.id] && (
                                                <HalfCircleSpinner size={20}
                                                    style={{
                                                        display: "inline-block",
                                                        marginRight: "0",
                                                        position: "absolute",
                                                        right: "3px",
                                                        top: "-2px"
                                                    }}
                                                />
                                            )}
                                            <img src={viewIcn} alt="View-icon" />
                                        </button>
                                    </td>
                                </tr>
                                )
                            }
                        );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            {openCardDetailsDrawer && (
                <CardDetailsDrawer
                    userData={user}
                    open={openCardDetailsDrawer}
                    setOpen={setOpenCardDetailsDrawer}
                    setSuccessTextHeading={setSuccessTextHeading}
                    setSuccessTextMsg={setSuccessTextMsg}
                    setIsSucess={setIsSucess}
                    card={user?.card}
                />
            )}

            {/* showing success modal when success is true */}
            {
                isSucess && (
                    <SuccessModal
                        open={isSucess}
                        onClose={() => setIsSucess(false)}
                        heading={successTextHeading}
                        msg={successTextMsg} 
                    />  
                )
            } 
        </div>
    )
}

export default PaymentTable
