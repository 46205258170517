import React, { useState, useEffect, useRef } from "react";
import { Drawer } from "../drawer";
import { DrawerInput } from "./drawerInput";
import { useInput } from "../../hooks/useInput";
import styles from "../../styles/module/adddependent.module.scss";
import classNames from "classnames";
import { statesUS } from "../../data/join-now";
import updateMemberService from "../../api/services/updateMemberService";
import ErrorHandler from "../ErrorHandler";
import { HalfCircleSpinner } from "react-epic-spinners";
import { SearchSelect } from "../shared-components/searchSelect";
import GooglePlacesAutocomplete from '../googlePlacesAutocomplete';

export const PrimaryDetailsDrawer = (props) => {

  const [firstName, bindFirstName] = useInput(props.userData.first_name);
  const [lastName, bindLastName] = useInput(props.userData.last_name);
  const [phone, bindPhone] = useInput(props.userData.phone);
  const [email, bindEmail] = useInput(props.userData.email);
  const [password, bindPassword] = useInput("");
  const [city, setCity] = useState(props.userData.city);
  const [state, bindState] = useState(props.userData.state);
  const [zip, bindZip] = useState(props.userData.zip);
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState();
  const [showLoader, setShowLoader] = useState(false);

  // Google Places Autocomplete
  const [inputValue, setInputValue] = useState(props.userData.address1);
  const [googleInput, setGoogleInput] = useState(props.userData.address1);
  const placeholder = "Street Address";
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState(props?.userData?.address2);
  const [stateName, setStateName] = useState([]);
  const [stepThreeError, setStepThreeError] = useState({});

  let failureRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {

      setShowLoader(true);
      const requestBody = {
        password,
        phone,
        address1: address2 ? (address2 + ' ' + inputValue) : inputValue,
        city,
        state: state[0]?.value,
        zip,
      };
      // console.log("Request data ==> ", requestBody)
      // set success text msg to empty because if fails the api it does'nt have any thing to display
      props.setSuccessTextMsg("")

      // calling api to update the member details
      updateMemberService.updateDetails(requestBody).then((response) => {
        if (response.status === 200) {
          props.setOpen(props.close);
          document.body.style.overflow = "scroll";
          props.setIsSucess(true);
          props.setSuccessTextHeading(`Success: Account Details Updated`);
          props.setSuccessTextMsg(`<p>You’ve successfully updated the account’s details.</p>
          <div><strong className="text3">Note:</strong> Click Continue to view the updated account’s details.</div>`);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          setApiErrors(response.message);
        }
      });
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!googleInput) {
      isValid = false;
      errors["googleInput"] = "Please enter your Address.";
    }
    if (!city) {
      isValid = false;
      errors["city"] = "Please enter your City.";
    }
    if (!state) {
      isValid = false;
      errors["state"] = "Please enter your State.";
    }
    if (!zip) {
      isValid = false;
      errors["zip"] = "Please enter your Zipcode.";
    }
    if (zip) {
      let res = zip.replace(/\D/g, "");
      if (res.length > 9) {
        isValid = false;
        errors["zip"] = "Please enter a Valid ZipCode";
      }
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    if (failureRef.current !== undefined) {
      failureRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [apiErrors]);

  useEffect(() => {
    if (errors) {
      let errorNodes = document.getElementsByClassName("support-error");
      let firstNode = errorNodes[0];
      firstNode?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [errors]);

  useEffect(() => {
    if (stateName !== '') {
      bindState(stateName);
    }
  }, [stateName])

  useEffect(() => {
    if (props.userData.state.length < 3) {
      const states = statesUS.filter((x) => x.value === props.userData.state);
      bindState(states[0]);
    } else {
      const states = statesUS.filter((y) => y.label === props.userData.state);
      bindState(states[0]);
    }
  }, [props.userData.state]);

  const handleStateChange = (event) => {
    const states = statesUS.filter((USstate) => USstate.value == event.value);
    bindState(states);
  };
  
  const colourStyles2 = {
    menu: (provided) => ({
      ...provided,
      borderRadius: '10px',
    }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected, placeholder}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? '#006f90' : isFocused ? "#006f9026" : null,
        color: isDisabled ? '#f5f5f5' : isSelected ? '#fff' : isFocused ? "#006f90" : "#006f90",
        fontSize: "16px",
        fontWeight: "600",
        transition: "all 0.35s ease",
        padding: "10px 12px",
        cursor: 'pointer',
      };
    },
    placeholder: (styles) => ({ ...styles, color: '#003b4d80' }),
  }

  return (
    <> 
      {
        props.open && (
          <Drawer open={props.open}>
                <div data-testid = "primary_details_drawer" className={styles.DependentMainContainerUpdate}>
                  <header className={styles.dependentHeader}>
                    <div className="d-flex align-items-start justify-content-between">
                      <div>
                        <h2 className={styles.addHeading}>Update Primary Details</h2>
                        <p className={styles.addsubHeading}>
                          Update details below. To update the primary member name, please
                          call (877) 545-4188.
                        </p>
                      </div>
                      <div className={styles.closeWrapper}>
                        <button
                          aria-label="Close panel"
                          className={styles.closebtn}
                          onClick={() => {
                            document.body.style.overflow = "scroll";
                            props.setOpen(props.close);
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="adddependent_closeIcon__1q-il"><path d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                      </div>
                    </div>
                  </header>

                  {/* <!-- Divider container --> */}
                  <form onSubmit={(event) => handleSubmit(event)}>
                    {apiErrors && (
                      <div data-testid="api_error" ref={failureRef} className={styles.cvContainerInput}>
                        <ErrorHandler apiErrors={apiErrors} noMargin={true} />
                      </div>
                    )}
                    <div className="">

                      {/* <!-- Primary first name --> */}
                      <DrawerInput
                        testid="primary_first_name"
                        id="primary_first_name"
                        label="Primary first name"
                        placeholder="First Name"
                        bindInput={bindFirstName}
                      />

                      {/* <!-- Primary last name --> */}
                      <DrawerInput
                        testid="primary_last_name"
                        id="primary_last_name"
                        label="Primary last name"
                        placeholder="Last Name"
                        bindInput={bindLastName}
                      />

                      {/* <!-- Phone --> */}
                      <DrawerInput
                        testid="primary_phone"
                        id="phone"
                        label="Phone Number"
                        bindInput={bindPhone}
                        placeholder="Phone Number"
                      />

                      {/* <!-- Email --> */}
                      <DrawerInput
                        testid="primary_email"
                        id="email"
                        label="Email"
                        bindInput={bindEmail}
                        placeholder="example@gmail.com"
                      />
                      {errors.email && (
                        <small data-testid="email_error" className="support-error">{errors.email}</small>
                      )}

                      {/* <!-- Password --> */}
                      <DrawerInput
                        testid="primary_password"
                        id="password"
                        label="Password"
                        placeholder="password"
                        bindInput={bindPassword}
                      />
                      {errors.password && (
                        <small data-testid="password_error" className="support-error">{errors.password}</small>
                      )}

                      {/* <!-- Shipping Address --> */}
                      <div className={styles.cvContainerInput}>
                        <label className={styles.labelName}>Address</label>
                        <GooglePlacesAutocomplete
                          bindZip={bindZip}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          setGoogleInput={setGoogleInput}
                          placeholder={placeholder}
                          setAddress1={setAddress1}
                          setCity={setCity}
                          setStateName={setStateName}
                          bindState={bindState}
                          stepThreeError={stepThreeError}
                          setAddress2={setAddress2}
                        />
                      </div>
                      {errors.googleInput && (
                        <small data-testid="address_error" className="support-error">{errors.googleInput}</small>
                      )}
                      {/* <!-- Address 2 || Optional Address Field --> */}
                      <div className={styles.cvContainerInput}>
                        <label className={styles.labelName}>Apt, Suite, Etc. (Optional)</label>
                        <input
                          data-testid="opt_address_field"
                          id="address2"
                          className={classNames(styles.formName, "form-input")}
                          value={address2}
                          onChange={(event) => setAddress2(event.target.value)}
                          placeholder="Apt, Suite, Etc."
                        />
                      </div>

                      {/* <!-- City --> */}
                      <div className={styles.cvContainerInput}>
                        <label className={styles.labelName}>City</label>
                        <input
                          data-testid="city_field"
                          id="city"
                          className={classNames(styles.formName, "form-input")}
                          value={city}
                          onChange={(event) => setCity(event.target.value)}
                          placeholder="City"
                        />
                      </div>
                      {errors.city && (
                        <small data-testid="city_error" className="support-error">{errors.city}</small>
                      )}

                      {/* <!-- State --> */}
                      <div className={styles.cvContainerInput}>
                        <label className={styles.labelName}>State</label>
                        <div className="addDependentSelectWrap m-0">
                          <div className={classNames(styles.selectWrapper, "memPlanSelect")}>
                             <SearchSelect
                              styles={colourStyles2} 
                              placeholder="Select&hellip;"
                              value={state}
                              options={statesUS}
                              onChange={(event) => handleStateChange(event)}
                            />
                          </div>
                        </div>
                      </div>
                      {errors.state && (<small data-testid="state_error" className="support-error">{errors.state}</small>)}

                      {/* <!-- Zip Code --> */}
                      <div className={styles.cvContainerInput}>
                        <label className={styles.labelName}>Zip</label>
                        <input
                          data-testid="zip_field"
                          // id="zip"
                          type="text"
                          pattern="^[0-9-]+$"
                          className={classNames(styles.formName, "form-input")}
                          value={zip}
                          onChange={(event) => bindZip(event.target.value)}
                          placeholder="Zip"
                        />
                      </div>
                      {errors.zip && <small data-testid="zip_error" className="support-error">{errors.zip}</small>}
                    </div>

                    {/* <!-- Action buttons --> */}
                    <div className="">
                      <div
                        className={classNames(
                          styles.btnprimaryContainer,
                          " d-flex justify-content-end"
                        )}
                      >
                        <div className={styles.cancelWrapper}>
                          <button
                            data-testid="cancel_btn"
                            type="button"
                            className={styles.cancelBtn}
                            onClick={() => {
                              document.body.style.overflow = "scroll";
                              props.setOpen(props.close);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className={styles.addWrapper}>
                          <button
                            data-testid="update_detail_btn"
                            type="submit"
                            className={styles.addBtn}
                            disabled={showLoader}
                          >
                            {showLoader && (
                              <HalfCircleSpinner
                                size={18}
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                }}
                              />
                            )}
                            Update Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Drawer>
        )
      }
    </>
    
  );
};
