const CheckLocationStatus = (data) => {
    if (data?.locationStatus === 0) {
        return false;
    }
    if (data?.locationStatus !== 0 && data?.deprecationDate) {
        const currentDate = new Date();
        const deprecationDate = new Date(data?.deprecationDate);
    
        const diffInTime = deprecationDate.getTime() - currentDate.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);

        if (diffInDays <= 0) {
            return false;
        } else if (diffInDays > 0) {
            return true;
        }
    } else {
        return true;
    }
}
export default CheckLocationStatus;