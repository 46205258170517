import React, {createContext, useState, useContext, useEffect} from 'react';
import Cookies from 'js-cookie';
import memberInfoService from './memberInfoService';
import memberLoginService from './memberLoginService';
import memberLogoutService from './memberLogoutService';
import httpClient from '../httpClient';

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {

    const [user, setUser] = useState(null);
    const [LoginError, setLoginError] = useState();
    const [loading, setLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [showApiLoader, setShowApiLoader] = useState(false);

    useEffect(() => {
        async function loadUserFromCookies() {
            const token = Cookies.get('membersyToken');
            if (token) {
                setShowApiLoader(true);
                memberInfoService.memberInfo().then((response) => {
                    setUser(response.data);
                    setShowApiLoader(false);
                });
            }
        }

        loadUserFromCookies();
    }, []);

    const login = async (email, password, group_key) => {
        setLoginError('');
        const data = {username: email, password, group_key};
        memberLoginService.memberLoginInfo(data).then((response) => {
            if (response.status === 200) {
                Cookies.set('membersyToken', response.data);
                memberInfoService.memberInfo().then((response) => {
                    setShowLoader(false);
                    if (response.status === 200) {
                        setUser(response.data);
                    } else {
                        throw response.message
                    }
                });
            } else {
                setShowLoader(false);
                setLoginError(response.message);
            }
        });
    };

    const logout = async () => {
        memberLogoutService.memberLogout().then((response) => {
            if (response.status === 200) {
                setUser(null);
                window.location.pathname = '/logout';
                Cookies.remove('membersyToken');
                delete httpClient.defaults.headers.Authorization;
            }
        });
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: !!user,
                user,
                login,
                logout,
                loading,
                LoginError,
                showLoader,
                setShowLoader,
                showApiLoader,
                setShowApiLoader
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
