// Auth
export const API_AUTHENTICATE = "/login";

// PRE LOGIN APIs
export const API_CREATE_MEMBER = "/member/create";
export const API_PLAN_BY_LOCATIONS = "/plansByLocation";
export const API_DENTIST_LOCATIONS = "/locations";
export const API_MEMBER_LOGIN = "/member/login";
export const API_RESET_PASS = "/member/reset-password";
export const FORGOT_PASS = "/member/forgot-password";
export const VERIFY_EMAIL = "/email/verification";
export const APPLY_COUPON = "/check/coupon";
export const GET_HFD_FLAG = "/location/hfd";
export const UPDATE_PAYMENT_DETAIL = "/member/update/billing/source";
export const GET_PAYMENT_DETAIL = "/member/hash";
export const GET_HFD_LINK = "/hfd/generate-provider-url";
export const GET_CUSTOMER_BALANCE = "/member/retrieve-customer-balance";
export const GET_EMAIL_VERIFICATION = "/verify-email";
export const API_VALIDATE_RECAPTCHA = "/reCaptcha/token/validation";

//POST LOGIN APIs
export const API_MEMBER_INFO = "/member/info";
export const API_MEMBER_LOGOUT = "/member/logout";
export const API_CANCEL_MEMBER = "/member/cancelmembership";
export const API_REACTIVATE_SUBSCRIPTION = "/member/reactivate/cancel";
export const API_RENEW_SUBSCRIPTION = "/member/subscription/renew";
export const API_ADD_DEPENDENTS = "/member/dependent/add";
export const API_UPDATE_BILLING = "/member/updateBilling";
export const API_UPDATE_MEMBER = "/member/update";
export const API_REACTIVATE_INACTIVE = "/member/reactivate/inactive";
export const API_UPGRADE = "/member/upgrade-plan";
export const GET_MEMBER_PDF = "/member/id/card";
export const GET_INVOICE_PDF = "/member/receipt";