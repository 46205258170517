import React, {useState, useEffect} from 'react';
import {Col, Form} from 'react-bootstrap';
import {useAuth} from '../api/services/auth';
import {useInput} from '../hooks/useInput';
import {HalfCircleSpinner} from 'react-epic-spinners';
import ForgotPasswordForm from "./forgotPassowordForm";
import Cookies from 'js-cookie';
import memberInfoService from '../api/services/memberInfoService';
import memberLoginService from '../api/services/memberLoginService';
import ValidateEmail from "../utility/emailRegExp";

const LoginForm = (props) => {
    
    // const {group_key} = props

    const [email, bindEmail, resetEmail] = useInput("");
    const [password, bindPassword, resetPassword] = useInput("");
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
    const [errors, setErrors] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const {login, LoginError } = useAuth();
    
    // array of group keys
    // const [groupKeyArr, setGroupKeyArr] = useState(group_key?.includes("|") ? group_key?.split("|") : new Array(group_key))

    useEffect(() => {
        if (LoginError) {
            setShowLoader(false);
        }
    }, [LoginError]);


    const validateLogin = () => {
        let errors = {};
        let isValid = true;
        if (!email) {
            isValid = false;
            errors['email'] = 'Please enter your email Address.';
        }
        if (email) {
            var pattern = new RegExp(ValidateEmail.RegExp);
            if (!pattern.test(email)) {
                isValid = false;
                errors['email'] = 'Please enter valid Email Address.';
            }
        }
        if (!password) {
            isValid = false;
            errors['password'] = 'Please enter your Password.';
        }
        setErrors(errors);
        return isValid;
    };
    
    const loginHandler = (event) => {
        event.preventDefault();
        if (validateLogin()) {
            setShowLoader(true);
            if (email && password) {
                login(email, password, ["marketplace"]);
            }
        }
    };
    
    return (
        <div className="loginformbox">
            <div className="loginCol">
                {!showForgotPasswordForm && (
                    <>
                        <div className="lgnTitleWrap">
                            <h1 id="main-heading-text" className="loginTitle text-center">Member Login</h1>
                            <p>Access your personal dashboard, edit account details, or tweak subscription settings.</p>
                        </div>
                    </>
                )}
                {showForgotPasswordForm && (
                    <>
                        <div className="lgnTitleWrap">
                            <h1 className="loginTitle text-center">Forgot Password</h1>
                            <p>Please enter the email address associated with your account. For assistance, call <a href="tel:8775454188">(877) 545-4188</a></p>
                        </div>
                    </>
                )}
                    <>
                        {!showForgotPasswordForm && (
                            <div className="loginFormWrapper">
                                <Form data-testid="memberLoginForm" onSubmit={(event) => loginHandler(event)} className="loginForm">
                                    <div className="formContainer">
                                        <div className="inputWrapper">
                                            <label className={"ccLabelText"}>Email</label>
                                            <input 
                                                type="email"
                                                placeholder="jane@example.com"
                                                className={`email customInputError customInput formControl  ${errors.email && "fcError"}`}
                                                {...bindEmail}
                                            />
                                            {errors.email && (
                                                <div data-testid="emailError" className="formErrors">{errors.email}</div>
                                            )}

                                        </div>
                                        <div className="inputWrapper">
                                            <label className={"ccLabelText"}>Password</label>
                                            <input data-testid="loginFormPwd" type="password" className={`password customInputError customInput formControl ${errors.password && "fcError"}`}
                                                    {...bindPassword}
                                            />
                                            {errors.password && (
                                                <div ata-testid="pwdError" className="formErrors">{errors.password}</div>
                                            )}
                                        </div>
                                    </div>
                                    <Form.Row>
                                        <Col className="rememberMe">
                                            <label className="customCheckbox">
                                                <input data-testid="rememberMe" type="checkbox" label="Remember me?" className="custoControlInput" id="rememberMe" />
                                                <span className="ccbox"></span>
                                                Remember me
                                            </label>
                                        </Col>
                                        <Col>
                                            <a data-testid="forgotPwdBtn" variant="link" 
                                                className="forgotPassword" 
                                                onClick={() => {
                                                    setErrors('')
                                                    resetEmail()
                                                    resetPassword()
                                                    setShowForgotPasswordForm(true)
                                                }} 
                                            >
                                                Forgot your password?
                                            </a>
                                        </Col>
                                    </Form.Row>
                                    {LoginError && (
                                        <div data-testid="loginErrorMsg" className="formContainerError">{LoginError}</div>
                                    )}
                                    <button 
                                        type="submit"
                                        data-testid= "loginFormSubmit"
                                        className="loginBtn btn btnPrimary"
                                        disabled={showLoader}
                                        >
                                            {showLoader && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                display: "inline-block",
                                                marginRight: "5px",
                                                }}
                                            />
                                            )}
                                        Login
                                    </button>
                                </Form>
                            </div>
                        )}
                        {showForgotPasswordForm && (
                            <>
                                <ForgotPasswordForm 
                                    setShowForgotPasswordForm = {setShowForgotPasswordForm} 
                                />
                            </>
                        )}
                    </>
                </div>
            </div>
    );
}
export default LoginForm;
