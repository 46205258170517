import React, {useEffect, useState} from "react";
import "../../assets/css/earlyRenew.css";
import img01 from "../../assets/images/icoParticipatingOffice.png";
import img02 from "../../assets/images/icoMembershipPlan.png";
import img03 from "../../assets/images/icoPlanBenefit.png";
import icnSuccessfull from "../../assets/images/icnSuccessfull.svg";
import loderIcon from "../../assets/images/loader.gif";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "../../components/shared-components/paymentDetails/PaymentDetails";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import validateReCaptchaService from "../../api/services/validateReCaptchaService";
import {isReCaptchaEnabled, RECAPTCHA_TOKEN_PARAM} from "../../utility/retrieveReCaptcha";

export const currencyFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
}).format(value);

const EarlyRenew = (props) => {
	const [errors, setErrors] = useState({});
	const [termsChecked, setTermsChecked] = useState(false);
	const [addDependents, setAddDependents] = useState(0);
	const [userData, setUserData] = useState(null)
	const [loader, setLoader] = useState(false);

	const [stipePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY));
	const [formSubmitted, setSubmitted] = useState(0);
	const [cardSource, setCardSource] = useState(null);
	const [token] = useState(new URLSearchParams(props.location.search));
	
	const [show, setShow] = useState(false);
	const [errModel, setErrModelShow] = useState(false);
	const [errMessage, setErrMessage] = useState('Something went wrong!');
	const [errDescription, setErrDescription] = useState('');
	const [disable, setDisable] = useState(false);

	const { executeRecaptcha } = useGoogleReCaptcha();

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Early Renew | membersy";
	}, []);
	
	useEffect(() => {

		axios.get(process.env.REACT_APP_Api_Url + '/load/data', {
			headers:{authorization: 'Bearer ' + token.get('token')}
			
		}).then((res)=>{
			setUserData(res.data.data);
			setAddDependents(userData?.no_of_dependents);
			
		}).catch((err) => {
			setErrModelShow(true);
			setErrMessage('Token Error!')
			setErrDescription('It looks like you have an empty token.')
		});
		
	}, []);

	const showBaseError = async () => {
		setLoader(false);
		setErrModelShow(true)
		setErrMessage('Something went wrong!')
		setErrDescription('Something went wrong when processing your request.')
	}
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		if(validateForm()) {
			// Attempt recaptcha if enabled.
			if (isReCaptchaEnabled()) {
				try {
					const token = await executeRecaptcha('early_renew')
					await validateReCaptchaService.validateReCaptcha(token)
				} catch (e) {
					console.error(e)
					await showBaseError()
				}
			}

			// Trigger form submission
			setSubmitted((prevState) => prevState + 1);
		} else {
			setLoader(false);
		}
	};
	
	useEffect(() => {
		if (errors) {
		  let errorNodes = document.querySelectorAll(".support-error,.checkError");
		  let firstNode = errorNodes[0];
		}
	}, [errors]);
	
	const validateForm = () => {
		let errors = {};
		let isValid = true;
		
		if (!termsChecked) {
			isValid = false;
			errors["termsChecked"] = "Please agree with the terms and conditions.";	
			setLoader(false);
		}
		setErrors(errors);
		return isValid;
	};
	
	const submitForm = async () => {
		if (validateForm()) {
			setDisable(true);
			let recaptchaToken = null;

			// Attempt recaptcha if enabled
			if (isReCaptchaEnabled()) {
				try {
					recaptchaToken =  await executeRecaptcha('early_renew')
				} catch (e) {
					console.error(e)
					await showBaseError()
					return;
				}
			}
			
			const requestBody = {
				payment_method: cardSource?.id,
				card : cardSource?.card,
				[RECAPTCHA_TOKEN_PARAM]: recaptchaToken
			};

			axios.post(process.env.REACT_APP_Api_Url + '/member/renew/before/renewal', requestBody, {
				headers:{authorization: 'Bearer ' + token.get('token')}
			}).then(response => {
				setShow(true);
				setLoader(false);
			}).catch(error => {
				showBaseError()
				console.error(error)
			})
		}
	}
	
	useEffect(() => {
		if (cardSource) {
		    submitForm();
		}
	}, [cardSource]);
    
    return (
        <>
            {/* updBillInfo */}
            <section className="updBillInfo">
                <div className="container">
                    <div className="ubiWrap bgGrayLight">
                        <header className="headerWrap clrDarkBlue">
                            <h1 className="h1">Update Billing Information</h1>
                            <p>By updating your card information, your plan will renew with an effective date of {userData?.end_date} and give you continued access to savings on all the dental services you need.</p>
                        </header>
                        <form className="earlyRenewForm" onSubmit={(event) => handleSubmit(event)}>
                            <div className="payDetWrap">
    							<div className="mpRow rowReverse">
    								<div className="pyCol pyCol40 pyColLg40 positionStatic">
    		                            <div className="checSumWrap">
    		                                <h2 className="h2 textCenter clrWhite">Checkout Summary</h2>
    		                                <div className="csBillingContext">
    		                                    <div className="h3 csHeading clrWhite">
        		                                    <img 
        		                                      src={img01} 
        		                                      className="icn" 
        		                                      alt="icon"
        		                                    />
        		                                    Participating Office
    		                                    </div>
    		                                    <div className="csSummary txtCapitalize">{userData?.location_name}</div>
    		                                </div>
    		                                <div className="csBillingContext">
    		                                    <div className="h3 csHeading clrWhite">
        		                                    <img
        		                                      src={img02} 
        		                                      className="icn" 
        		                                      alt="icon"
        		                                    />
        		                                    Membership Plan
    		                                    </div>
    		                                    <div className="csSummary txtCapitalize">{userData?.plan_name}</div>
    		                                </div>
    		                                <div className="csBillingContext">
    		                                    <div className="h3 csHeading clrWhite">
        		                                    <img
        		                                      src={img03} 
        		                                      className="icn" 
        		                                      alt="icon"
        		                                    />
        		                                    Plan Benefits
    		                                    </div>
    		                                    <div className="csSummary planBenefits">{userData?.plan_benefits}</div>
    		                                </div>
    		                                <div className="csBillingContext anuFeeBox dflBox aligItemCenter">
    		                                    <div className="h4 csHeadingAlt">Annual Fee(s)</div>
    		                                    <div className="h4 csHeadingAlt">Amount</div>
    		                                </div>
    		                                <div className="csMemberContext dflBox aligItemCenter">
                                                <div className="csSummary clrWhite">Primary Member:</div>
                                                <div className="csSummary clrWhite">{currencyFormat(userData?.primary_price)}</div>
    		                                </div>
    		                                {addDependents === 0 ? (
													''
				                                ) : (
	    		                                <div className="csMemberContext dflBox aligItemCenter">
	    		                                    <div className="csSummary clrWhite">Dependent(s):</div>
	    		                                    <div className="csSummary clrWhite">{currencyFormat(userData?.total_dependent_price)}</div>
	    		                                </div>
											)}
    		                                <div className="csTotalContext dflBox">
                                                <div className="csSummary clrWhite">Total Cost:</div>
                                                <div className="totalCost clrWhite">{currencyFormat(userData?.total_price)}</div>
    		                                </div>
    		                                <div className="joinNowContext">
    		                                    <button 
	    		                                    type="submit"
	    		                                    className="btn btnWhite"
													disabled={disable}
    		                                    >
    		                                        Renew Membership <i className="icomoon-icoMembershipPlan icn"><span className="sr-only">icon</span></i>
    		                                    </button>
    		                                </div>
    		                            </div>
    								</div>
    								<div className="pyCol pyCol60 pyColLg60">
    		                            <div className="payStepsWrap">
        								    <ul className="stepList">
        								        <li className="active">
        								            Details
        								        </li>
        								        <li>
        								            Confirmation
        								        </li>
        								    </ul>
    		                                <h3 className="h3 mpHeadingMain clrDarkBlue">Payment Details 
    		                                    <div className="toolTip">
    		                                        <img 
    		                                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFYSURBVHgBZVJLTgJBEH01PeOaGwg30B3BDe6MicnoBZQjeALkBnoCOIGOURN34wbCkhuAN5glBnua1zUfBqhkUtNdr6rrvSrBoXXjNsTGENeCkwxuk2D+vWpCZAe+asNEY/71Abegz/i1CDmjn8BuRlVyUCcEUUowYE0H089z2HBQeJ7hfMFUcXWSiYZsZ0XQJbAGejdLGLvEBb0/6z3jRSdMKrIftLIWCH1goS+Ie6+AZbyP3t1pAIli5TBPCrIufMSJGeg5z8mNgnjTOHHu7zZUlSBZLcgsWajvXVMAMyTNUUO4DCKtQGWtqu2ZeYPkL5h9TBqXHIPLmGQSlbVURq0fs4gdYfr1vDcSj3NREpS9/tSEva1ZUczYk949rPGJx4e1MoFNKXHKIRYidOMO5q+/jaG3qejgYCO4Pub/iVf3xUZUXP1GsBNfuFRYjvgf7R45V+MobQurspWxykTwQAAAAABJRU5ErkJggg==" 
    		                                          alt="info-icon" 
    		                                          width="13"
    		                                        />
    		                                        <span className="toolTipTxt">We'll mail you a member welcome kit and ID cards (upon request) and a renewal notification letter each year prior to your annual renewal date.</span>
    		                                    </div>
    		                                </h3>
    		                                <div className="mpMemContext mpRow">
    		                                    <div className="pyCol pyCol50">
                                                    <label>Primary Name</label>
                                                    <p className="mp_userDetails txtCapitalize">{userData?.primary_name}</p>
    		                                    </div>
    		                                    <div className="pyCol pyCol50">
                                                    <label>Primary ID</label>
                                                    <p className="mp_userDetails">{userData?.primary_id}</p>
    		                                    </div>
    		                                </div>
    		                                <div className="mpCardContext mpRow">
	    		                                <div className="pyCol">
													<Elements stripe={stipePromise}>
								                        <PaymentDetails
								                          setCardSource={setCardSource}
														  formSubmitted={formSubmitted}
														  setLoader={setLoader}
								                        />
								                    </Elements>
							                    </div>
    		                                </div>
    		                                <div className="mpCheckingContext">
    		                                    <label className="inputContainer">
                                                    <input type="checkbox" 
                                                        id="termsChecked" 
                                                        checked={termsChecked} 
                                                        onChange={() => setTermsChecked(!termsChecked)}
                                                    />
													<span
					                                className={classNames(
					                                  errors.termsChecked
					                                    ? "checkError checkmark"
					                                    : "checkmark"
					                                )}
					                                ></span>
    		                                    </label>
    		                                    <label htmlFor="termsChecked" className="mpCheckTerms">
                                                    The account’s primary member understands and agrees to the <a href={"https://connect.membersy.com/" + `${userData?.term_condition}`} target="_blank" rel="noreferrer noopener" className="mtocLink">Member Terms and Conditions.</a>
    		                                    </label>
						                        <small className="support-error">
						                            {errors.termsChecked}
						                        </small>
    		                                </div>
    		                                <div className="mpBottomnote">
                                                Note: If you need to add or remove dependents from your account, please contact us at <a href="tel:8775454188">(877) 545-4188</a>.
    		                                </div>
    		                            </div>
    								</div>
    							</div>
                            </div>
                        </form>
						<Modal
	                        show={show}
	                        className="successModal generalPopupStyles"
	                        centered
	                        onHide={() => setShow(false)}
	                        backdrop="static"
	                        >
	                        <Modal.Body>
								<div className="icnWrap">
									<img
										src={icnSuccessfull}
										className="imgFluid"
										alt="forget-pasword-successfull"
									/>
								</div>
								<h3 className="h3 modalHeading"> Card Added Successfully!</h3>
								<div className="memberContextBox">
									<div className="mySubHead">Primary Name: <span className="mycDesc txtCapitalize">{userData?.primary_name}</span></div>
									<div className="mySubHead">Primary ID: <span className="mycDesc">{userData?.primary_id}</span></div>
								</div>
								<div className="text-center btnWrap pt-4">
									<a href="https://membersy.com/" className="btn btnPrimary text-capitalize">Back to home</a>
								</div>
	                        </Modal.Body>
	                    </Modal>
						<Modal
	                        show={errModel}
	                        className="errorModal generalPopupStyles"
	                        centered
	                        onHide={() => setErrModelShow(false)}
	                        backdrop="static"
	                        >
	                        <Modal.Body>
								<div className="icnWrap">
								<i className="icn fa fa-times-circle"></i>
								</div>
								<h3 className="h3 modalHeading">{errMessage}</h3>
								<div className="memberContextBox">
									<div className="mySubHead">{errDescription} If you need any help please contact us at <a href="tel:8775454188">(877) 545-4188</a>.</div>
								</div>
								<div className="text-center btnWrap pt-4">
									<a href="https://membersy.com/" className="btn btnPrimary text-capitalize">Back to home</a>
								</div>
	                        </Modal.Body>
	                    </Modal>
                    </div>
                </div>
            </section>
            {loader === true ? (
	            <div 
	                className="loderWrap true"
	            >
					<img 
	        		    src={loderIcon} 
	        		    className="icn" 
	        		    alt="icon"
	        		/>
	            </div>     
            ):(
                ""
            )}
        </>
    );
};

export default EarlyRenew;
