import React, { useState } from 'react'
import classNames from "classnames";
import styles from "../../styles/module/dashboard.module.scss";
import MemberIdCardModal from "./memberIdCardModal/memberIdCardModal";
import {HalfCircleSpinner} from "react-epic-spinners";
import {GET_MEMBER_PDF} from "../../api/config";
import Cookies from "js-cookie";
import axios from "axios";

const MemberIdCard = ({user}) => {

    const [showLoader, setShowloader] = useState(false);
    const getMemberCard = () => {
        setShowloader(true);
        const token = Cookies.get("membersyToken");
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_Api_Url}${GET_MEMBER_PDF}/${user?.id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            responseType:'blob',
        };
        axios(config)
            .then(function (response) {
                const pdf=response?.data;
                if(pdf){
                    const url = window.URL.createObjectURL(pdf);
                    const downloadLink = document.createElement("a");
                    const fileName = "file.pdf";
                    downloadLink.href = url;
                    downloadLink.target = "_blank";
                    downloadLink.click();
                    setShowloader(false);
                    downloadLink.remove();
                    
                }
            });
    };

    return (
        <>
            <div className={classNames(styles.whiteBlock, styles.sidebar, "memberIDwidget")}>
            <div className="">
                <h1 className={styles.questionHeading}>Have Questions?</h1>
                <h5 className={styles.reachHeading}>Don't worry. Contact one of our experts.</h5>
                <div className={styles.imgWrapper}>
                    <div className={styles.imgContainer}>
                        <div className={styles.imgIcons}>
                            <img
                                src="/images/phoneicn.svg"
                                className="img-fluid"
                                alt="phone-logo"
                            />
                        </div>
                        <div className={classNames(styles.iconPara, "pl-2")}>
                            <a href="tel:+8775454188">(877) 545-4188</a>
                        </div>
                    </div>
                    <div className={styles.imgContainer}>
                        <div className={styles.imgIcons}>
                            <img 
                                src="/images/email.svg" 
                                className="img-fluid"
                                alt="email-logo"
                            />
                        </div>
                        <div className={classNames(
                                styles.iconPara,
                                styles.emailWrap,
                                "pl-2"
                            )}
                        >
                            <a 
                                href="mailto:member@membersy.com"
                                title="member@membersy.com"
                            >
                                member@membersy.com
                            </a>
                        </div>
                    </div>
                    <div className={styles.imgContainer}>
                        <div className={styles.imgIcons}>
                            <img
                                src="/images/time.svg"
                                className="img-fluid"
                                alt="time-logo"
                            />
                        </div>
                        <div className={classNames(styles.iconPara, "pl-2")}>
                            Mon-Fri | 8am-6pm CST
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.buttonWrapper, "pt-4")}>
                    <button
                        data-testid="member_id_card_btn"
                        type="button"
                        onClick={getMemberCard}
                        className={classNames(styles.memberBtn, styles.wid100)}
                        disabled={showLoader}
                    >
                        {showLoader && (
                            <HalfCircleSpinner size={15}
                                style={{
                                   display: "inline-block",
                                   marginRight: "10px",
                                }}
                            />
                        )}
                        Member ID Card
                    </button>
                </div>
                <div className={styles.linkWrapper}>
                    {(user?.fee_schedule || user?.term_condition) ? (
                        <span className='txtSpan'>Please refer to our</span>
                    ) : ''}
                    {user?.fee_schedule && (
                        <a
                            href={user?.fee_schedule}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.pdfLinks}
                        >
                            {" "}Fee Schedule
                        </a>
                    )}
                    {(user?.fee_schedule && user?.term_condition) && (
                        <span className={styles.andTag}> &amp; </span>
                    )}
                    {user?.term_condition && (
                        <a
                            href={user?.term_condition}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.pdfLinks}
                        >
                            Terms and Conditions
                        </a>
                    )}
                </div>
            </div>
        </div>
    </>
        
    )
}

export default MemberIdCard
