import React, {useEffect} from 'react';
import {useAuth} from '../../api/services/auth';
import img01 from "../../assets/images/banner-img.jpg";
import "../../assets/css/login.css";
import LoginForm from '../../components/loginForm';


// export default function Login(props) {
const Login = (props) => {

    const {isAuthenticated} = useAuth();

    // if user is authenticated, redirect to the dashboard page
    useEffect(() => {
        if (isAuthenticated) {
            window.location.replace('/dashboard')
        }
    }, [isAuthenticated]);

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Member Login | membersy";  
	}, []);

    return (       
        <section className="container containerLarge login-wrapper">
            <div className="login-img">
                <img src={img01} className="img-fluid" alt="img description"/>
            </div>
            <LoginForm />
        </section>
    );
}

export default Login;
