import React, { useState } from "react";
// import axios from "axios";
import styles from "../../../styles/module/join-now.module.scss";
import applyCouponServices from "../../../api/services/applyCouponServices";


export default function ApplyCoupon(props) {
  // coupon
  const [coupon, setCoupon] = useState()
  const [couponMsg, setCouponMsg] = useState(null)
  const [couponErrMsg, setCouponErrMsg] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(null)
  const [discountPercentage, setDiscountPercentage] = useState(null)

  // apply coupon
  const applyCoupon = async (e) => {
    e.preventDefault()
    
    // request body for the coupon 
    let requestBody = { 
        coupon
    }
    
    // couponMsg ? requestBody.coupon = coupon : ""

    // calling apply coupon api 
    applyCouponServices.applyCoupon(requestBody).then((response) => {
        //console.log(response)
        if(response.status === 200){
          if(response.data.amount_off){
            setDiscountPercentage(null)
            setDiscountAmount(response.data.amount_off)
            props.discountAmount(response.data.amount_off)
            props.percentageAmount(null)
	    props.coupon(coupon)
          }

          if(response.data.percent_off){
            setDiscountAmount(null)
            setDiscountPercentage(response.data.percent_off)
            props.percentageAmount(response.data.percent_off)
            props.discountAmount(null)
	    props.coupon(coupon)
          }
          setCouponMsg("Coupon Applied!")
        }else {
          setCouponErrMsg("No Such Coupon!")
        }
    });
  }
  
	return(
	    <div>
	        <div className="coupenSetWrap">
	            <a href="#" className="coupOpener">Apply Coupon</a>
	            <div className="cswWrap">
	                <input className="form-input" 
	                    onChange={(e) => { 
	                        setCoupon(e.target.value) 
	                        setCouponMsg(null)
	                        setCouponErrMsg(null)
	                    }}
	                  
	                    disabled = {couponMsg ? true : false}
	                />
	                <button className="btn btnSecondary" 
	                    disabled = {couponMsg ? true : false}
	                    onClick={(e) => {applyCoupon(e)}}>
	                    Apply Coupon
	                </button>
	            </div>
	            {
	                couponErrMsg && <p className="support-error">{couponErrMsg}</p>
	            }
	            {
	                couponMsg && <p className="successText">{couponMsg}</p>
	            }
	        </div>
	    </div>
    )
}
