import React, { useState, useEffect, useRef } from "react";
import { Drawer } from "../drawer";
import { SearchSelect } from "../shared-components/searchSelect";
import { dependentsDropdown, colourStylesPost } from "../../data/join-now";
import stylesSheet from "../../styles/module/adddependent.module.scss";
import styles from "../../styles/module/join-now.module.scss";
import classNames from "classnames";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "../shared-components/paymentDetails/PaymentDetails";
import { HalfCircleSpinner } from "react-epic-spinners";
import planByLocationService from "../../api/services/planByLocationService";
import { Image } from "cloudinary-react";
import purchaseSubscriptionService from "../../api/services/purchaseSubscriptionService";
import ErrorHandler from "../ErrorHandler";
import AddDependentsInput from '../addDependents'
import axios from "axios";
import {useAuth} from '../../api/services/auth';

export const currencyFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
}).format(value);

const ExistingDependentInput = ({ existingUsers, setExistingUsers, personID, rowIndex,  setAddDependents }) => {
  let IndividualData = existingUsers.filter(
      (user, index) => index === rowIndex
  );

  const date = new Date(IndividualData[0].person.dob);
  const dateISO = date?.toISOString()?.substring(0, 10);

  let usersExisting = [...existingUsers];

  const changeState = (inputName, personID, value) => {
    usersExisting.forEach((user, index) => {
      if (index === rowIndex) {

        user.person[inputName] = value;
      }
    });

    setExistingUsers(usersExisting);
  };

  const deleteData = (personID) => {
    let remaingUsers = usersExisting.filter(
        (user, index) => index !== rowIndex
    );
    setExistingUsers(remaingUsers);
    setAddDependents((prevState) => prevState-1)
  };

  return (
      <div
          className={classNames(
              stylesSheet.dependentElements,
              "d-flex justify-content-between"
          )}
      >
        <div className={stylesSheet.dependentFormSub}>
          <input
              placeholder="First Name"
              value={IndividualData[0].person.first_name}
              className="form-input"
              onChange={(event) =>
                  changeState("first_name", personID, event.target.value)
              }
          />
        </div>

        <div className={stylesSheet.dependentFormSub}>
          <input
              placeholder="Last Name"
              value={IndividualData[0].person.last_name}
              className="form-input"
              onChange={(event) =>
                  changeState("last_name", personID, event.target.value)
              }
          />
        </div>
        <div className={stylesSheet.dependentFormMobile}>
          <div className={classNames(stylesSheet.dependentMobileDate)}>
            <input
                type="date"
                placeholder="Date of Birth"
                value={dateISO}
                className={classNames(styles.formName, "form-input")}
                onChange={(event) =>
                    changeState("dob", personID, event.target.value)
                }
            />
          </div>
          <div className={stylesSheet.mobileDeleteIcon}>
            <Image
                className={classNames(stylesSheet.trashIcon)}
                src="/images/delete-subscription.svg"
                alt="open"
                onClick={() => deleteData(personID)}
            />
          </div>
        </div>
        <Image
            className={classNames(
                stylesSheet.trashIcon,
                stylesSheet.mobileTrashIcon
            )}
            src="/images/delete-subscription.svg"
            alt="open"
            onClick={() => deleteData(personID)}
        />
      </div>
  );
};

export const PurchaseSubscriptionDrawer = (props) => {

  const {setShowApiLoader} = useAuth();
  const [stipePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY))
  const [addDependents, setAddDependents] = useState(props?.user?.Dependents?.length);
  const [errors, setErrors] = useState({});
  const [cardSource, setCardSource] = useState(null);
  const [formSubmitted, setSubmitted] = useState(0);
  const [plan, setPlan] = useState();
  const [isSucess, setIsSucess] = useState(false);
  const [apiErrors, setApiErrors] = useState();
  const [loader, setLoader] = useState(false);
  const [planDropdown, setPlanDropDown] = useState();
  const [planData, setPlanData] = useState();
  const [annualBilling, setAnnualBilling] = useState("Annual");
  const [activePlan, setActivePlan] = useState();
  const [existingUsers, setExistingUsers] = useState();
  const [dropdownDependent, setDropdownDependent] = useState();

  // how many dependent would add a member through dropdown of add dependent 
  const [depDropDownCount, setDepDropDownCount] = useState(props?.membershipDetails?.plan?.numberOfMaxDependents - props?.user?.Dependents?.length)


  let failureRef = useRef();
  const addDependentsRef = useRef()

  // state to manage the price cap 
  const [priceCap, setPriceCap] = useState(props?.membershipDetails?.plan?.cap_limit)
   // handle price cap
   const priceCapHandle = () => { 
    if(priceCap === null || priceCap === 0){
      return addDependents
    }
    else {
      if(addDependents > priceCap){
        return priceCap
      }else if (addDependents < priceCap){
        return addDependents
      }else {
        return addDependents
      }
    }
  }

  const addDepCountHandler = (event) => {
    setAddDependents(event + existingUsers.length);
  }

  const currencyFormat = (value) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitted((prevState) => prevState + 1);      
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (existingUsers) {
      let dropDownList = [...dependentsDropdown];
      if (existingUsers.length !== 0) {
        dropDownList.splice(-Math.abs(existingUsers.length));
      }
      setDropdownDependent(dropDownList);
    }
  }, [existingUsers]);

  useEffect(() => {
    if (failureRef.current !== undefined) {
      failureRef?.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [apiErrors]);

  useEffect(() => {
    if (errors) {
      let errorNodes = document.getElementsByClassName("errors-depend");
      let firstNode = errorNodes[0];
      firstNode?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [errors]);

  const validateForm = () => {
    let errors = {};
    let isValid = true;
    
    // call the addDependents components validations
    if(addDependentsRef.current){
      isValid = addDependentsRef.current.addDependentsValidations()
    }
    setErrors(errors);
    return isValid;
  };

  const submitForm = () => {
    if (validateForm()) {
      setLoader(true);
      let memberDetails = [];
      if(addDependentsRef.current){
        memberDetails = addDependentsRef.current.getDependentsData()
      }
  
      const formattedExisting = existingUsers.map((users) => {
        return {
          first_name: users.person["first_name"],
          last_name: users.person["last_name"],
          pms_number: "",
          dob: users.person["dob"],
        };
      });

      const combinedMembership = [...formattedExisting, ...memberDetails];

      let requestBody = {
        member_id: props.memberID,
        plan_id: activePlan.value,
        total_members: addDependents + 1,
        members: combinedMembership,
        payment_type: "0",
        platform: "3",
        check_number: null,
        frequency: annualBilling,
        payment_method: cardSource.id,
        card: cardSource.card,
      };
      
      // console.log("API Request Body : ",requestBody)

      purchaseSubscriptionService
          .purchaseSubscription(requestBody)
          .then((response) => {
            setLoader(false);
            if (response.status === 200) {
              props.setOpen(props.close);
              document.body.style.overflow = "scroll";
              setApiErrors("");
              props.setIsSucess(true);
              props.setSuccessTextHeading("Subscription is purchased successfully.");
              props.setSuccessTextMsg(`<p>Congratulations! You’ve successfully created a new account and your new member(s) are immediately eligible to receive your practice’s member-only discounts on dental services rendered. If needed, please refer to your practice's member fee schedule to view your practice's member discount by procedure type.</p>`);
            } else {
              setApiErrors(response.message);
            }
          });
    }
  };

  useEffect(() => {
    if (cardSource) {
      submitForm();
    }
  }, [cardSource]);

  const mapAsMembershipDropdownList = (data) => {
    let dropDownList = data.map((plan) => {
      return {
        value: plan["plan_id"],
        label: plan["full_name"],
        frequencies: plan["frequencies"],
        disabled: false,
      };
    });
    return dropDownList;
  };
  
  const [router] = useState(new URLSearchParams(props.location?.search));

  const getPlans = async (data) => {
    setShowApiLoader(true);
    let plansList=[]
    planByLocationService.planByLocation(data).then((response) => {
      plansList = mapAsMembershipDropdownList(response.data);
      setPlanDropDown(plansList);

      // if planId in query params
      if(router?.query?.planId){
        plansList?.map((e)=> {
          if(e.value === router.query.planId){
            setActivePlan(e);
            getPlanById(e.value)
          }
        })
      }else {
        setActivePlan(plansList[0]);
        getPlanById(plansList[0].value)
      }
      setShowApiLoader(false);
    }).catch((err)=>{
      setActivePlan(plansList[0]);
      getPlanById(plansList[0]?.value)
    });
  };
  
  // get plan by Id and frequency
  const getPlanById = (planId) => {
    let url = ""
    if(activePlan?.frequencies?.length >=1){
      url = `${process.env.REACT_APP_Api_Url}/plan/${planId}?frequency=${annualBilling}`
    }else {
      url = `${process.env.REACT_APP_Api_Url}/plan/${planId}?frequency=Annual`
    }
    axios.get(url).then((res)=>{
      if(res.data.data){
        // console.log(res.data.data)
        setPlan(res?.data?.data[0])
        setPriceCap(res?.data?.data[0]?.cap_limit)

      }
    })
  }

  // call getPlanById when frequency changed
  useEffect(()=>{
    getPlanById(activePlan?.value)
  },[annualBilling])
  
  function handlePlanSelect(event) {
    planDropdown.map((plan) => {
      if(plan.value === event.value){
        setActivePlan(plan);
        setAnnualBilling("Annual")
        getPlanById(plan.value);
        return ;
      }
    });
  }

  useEffect(() => {
    if (planData) {
      const selectedPlan = planData.filter(
          (plan) => plan.id === activePlan.value
      );
      setPlan(selectedPlan[0]);
      if (selectedPlan[0].id === 1) {
        setAnnualBilling("annual");
      }
    }
  }, [planData]);

  useEffect(() => {
    let planData_request = { location_id: String(props.user.location_id) };
    getPlans(planData_request);
    setExistingUsers(props.user?.Dependents);
  }, []);

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: `1px solid #ccc`,
      borderRadius: "8px",
      fontSize: "15px",
      width: "100%",
      height: "35px",
      cursor: 'pointer',
      textTransform: 'capitalize',
      // fontWeight: "700",
      transition: "all 0.35s ease",
      color: "#006f90",
      "&:hover": { borderColor: " #006f90", },
  
      boxShadow: "none",
    }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected, placeholder}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? '#006f90' : isFocused ? "#006f90" : null,
        color: isDisabled ? '#f5f5f5' : isSelected ? '#fff' : isFocused ? "#fff" : "#006f90",
        fontSize: "14px",
        // fontWeight: "700",
        transition: "all 0.35s ease",
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        textTransform: 'capitalize'
      };
    },
    placeholder: (styles) => ({ ...styles, color: '#006f90', textTransform: 'capitalize', }),
  }

  return (
    <>
      {
        props.open && (
          <Drawer open={props.setOpen}>
            <div className={stylesSheet.DependentMainContainer} data-testid="purchase_subscription_drawer">
              <div className="">
                <header className={stylesSheet.dependentHeader}>
                  <div className="d-flex align-items-start justify-content-between ">
                    <div className="">
                      <h2 className={stylesSheet.addHeading}>New Subscription</h2>
                      <p className={stylesSheet.addsubHeading}>
                        Complete the form below to purchase a new subscription for the
                        associated account.
                      </p>
                    </div>
                    <div className={stylesSheet.closeWrapper}>
                      <button
                          aria-label="Close panel"
                          className={stylesSheet.closebtn}
                          onClick={() => {
                            document.body.style.overflow = "scroll";
                            props.setOpen(props.close);
                          }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="adddependent_closeIcon__1q-il"><path d="M6 18L18 6M6 6l12 12"></path></svg>
                      </button>
                    </div>
                  </div>
                </header>

                {/* <!-- Divider container --> */}
                <form onSubmit={(event) => handleSubmit(event)}>
                  <div>
                    <div className={classNames(stylesSheet.dependdivContainer)}>
                      {apiErrors && (
                          <div ref={failureRef}>
                            <ErrorHandler apiErrors={apiErrors} />
                          </div>
                      )}
                      <h2 className={classNames(stylesSheet.personalHeading, "mb-2")}>
                        Select Subscription Type:{" "}
                      </h2>
                      <SearchSelect
                          id="subsType"
                          styles={colourStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#0B4DA2",
                            },
                          })
                        }
                          value={activePlan}
                          options={planDropdown}
                          onChange={(event) => handlePlanSelect(event)}
                      />
                      {/* exchange by the custom select*/}
                      {/* <div className="customSelectWrap">
                        <select data-testid="state_select"
                            value={activePlan}
                            onChange={(event) => handlePlanSelect(event)}>
                            { 
                                planDropdown && planDropdown.map((e, index)=> {
                                  return <option key={index} value={e.value}>{e.label}</option>
                                })
                            }
                        </select>
                      </div> */}
                    </div>


                    {/* Subscription Frequency */}
                    <div
                      className={classNames(
                        styles.subFrequencyWrapper,
                        "my-2"
                      )}
                    >
                      <fieldset>
                        <div>
                          <div
                            className={classNames(
                              styles.billingWrapper,
                              "d-flex align-items-center fkLableWrap"
                            )}
                          >

                            {/* frequency radio buttons */}
                              {
                                activePlan?.frequencies?.map((e, index)=> {
                                  return (
                                    <div className={styles.buttonContainer} key={index}>
                                      <div className={styles.buttonWrapper}>
                                      <label>
                                        <input
                                          id={e.frequency}
                                          name={e.frequency}
                                          type="button"
                                          className={annualBilling === e.frequency ? "checked" : "unchecked"}
                                          value={e.frequency}
                                          onClick={(event) => {
                                            setAnnualBilling(event.target.value)
                                          }}
                                        />
                                        <span className="fkInput">{e.frequency}</span>
                                      </label>
                                    </div>
                                  </div>
                                  )
                                })
                              }
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className={stylesSheet.dependdivContainer}>
                      {existingUsers?.map((dependent, index) => {
                        return (
                            <ExistingDependentInput
                                key = {index}
                                existingUsers={existingUsers}
                                setExistingUsers={setExistingUsers}
                                setAddDependents={setAddDependents}
                                personID={dependent.person_id}
                                rowIndex={index}
                            />
                        );
                      })}
                    <div className="pt-3 pb-2">
                      <h2 className={stylesSheet.personalHeading}>
                        Would you like to add new dependents?
                      </h2>
                      <div
                          className={classNames(
                              styles.selectWrapper,
                              stylesSheet.selectDropDown
                          )}
                      >
                          {/* add dependent component (in which we add the dependent details) */}
                          <AddDependentsInput 
                            ref = {addDependentsRef}
                            handleDepCount = {addDepCountHandler}
                            maxDep = {depDropDownCount}
                          />
                          
                      </div>

                    </div>

                    {/* checkout summary */}
                    <div className={stylesSheet.dependcheckContainer}>
                      <div className={stylesSheet.reciept}>
                        <div className={stylesSheet.recptWrapper}>
                          <div className={stylesSheet.recptContainer}>
                            <div className={classNames(stylesSheet.rcptCont)}>
                              <div className="text-center">
                                <h1 className={classNames(stylesSheet.rcptHeading, 'mb-2')}>
                                  Checkout Summary
                                </h1>
                              </div>
                              <div className={classNames(styles.billingContext, 'mb-2')}>
                                <h3 className={classNames(stylesSheet.mainHeading, 'mb-2')}>
                                  <Image
                                      className={stylesSheet.joinIcon}
                                      src="/images/membership-type-join.svg"
                                      alt="office-icon"
                                  />
                                  Subscription Type
                                </h3>
                                <div className={classNames(
                                    styles.iconPara,
                                    "text-capitalize"
                                  )}
                                >
                                  {plan?.full_name}
                                </div>
                              </div>
                              {annualBilling === "Annual" && (
                                  <>
                                    <div className={classNames(styles.billingContext, 'mb-2')}>
                                      <h3 className={classNames(stylesSheet.mainHeading, 'mb-2')}>
                                        <Image
                                            className={stylesSheet.joinIcon}
                                            src="/images/billingfrequency-join.svg"
                                            alt="office-icon"
                                        />
                                        Billing Frequency
                                      </h3>
                                      <div className={styles.iconParaAnnual}>
                                        {annualBilling}
                                      </div>
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.memberContext,
                                            styles.billingContext,
                                            "d-flex justify-content-between mb-3"
                                        )}
                                    >
                                      <h3
                                          className={classNames(
                                              styles.billingContext,
                                              stylesSheet.memberHead,
                                          )}
                                      >
                                        Annual Upfront
                                      </h3>
                                      <h3
                                          className={classNames(
                                              styles.billingContext,
                                              stylesSheet.memberHead,
                                              "text-right"
                                          )}
                                      >
                                        Amount
                                      </h3>
                                    </div>

                                    <div
                                        className={classNames(
                                            styles.memberContext,
                                            styles.billingContext,
                                            "d-flex justify-content-between mb-2"
                                        )}
                                    >
                                      <div className={classNames(styles?.leftHeading?.colorgrey, 'clrLight')}>
                                        Primary Member:
                                      </div>
                                      <div className={styles.rightHeading}>
                                        {currencyFormat(plan?.primary)}
                                      </div>
                                    </div>
                                    {addDependents === 0 || addDependents === null?(
                                        " "
                                    ) : (
                                        <div
                                            className={classNames(
                                                styles.memberContext,
                                                styles.billingContext,
                                                "d-flex justify-content-between mb-4"
                                            )}
                                        >
                                          <div className={classNames(styles.leftHeading.colorgrey, 'clrLight')}>
                                            {addDependents} dependent(s) @ $
                                            {plan?.dependent}/yr
                                          </div>
                                          <div className={styles.rightHeading}>
                                            {currencyFormat(
                                                priceCapHandle() * plan?.dependent
                                            )}
                                          </div>
                                        </div>
                                    )}
                                    {addDependents === 0 ? (
                                        <>
                                          <div
                                              className={classNames(
                                                  styles.billingContext,
                                                  styles.total,
                                                  "d-flex justify-content-between py-3"
                                              )}
                                          >
                                            <div className={classNames(styles?.leftHeading?.colorgrey, 'clrLight')}>
                                              Total Annual Cost:{" "}
                                            </div>
                                            <div
                                                className={classNames(styles.rightHeading)}
                                            >
                                              {currencyFormat(plan?.primary)}
                                            </div>
                                          </div>
                                          <div
                                              className={classNames(
                                                  styles.billingContext,
                                                  styles.billingContextFinal,
                                                  styles.padTop4,
                                                  "d-flex justify-content-between "
                                              )}
                                          >
                                            <div className={styles.leftHeading}>
                                              Total Due Now:{" "}
                                            </div>
                                            <div
                                                className={classNames(
                                                    stylesSheet.totalRight
                                                )}
                                            >
                                              {currencyFormat(plan?.primary)}
                                            </div>
                                          </div>
                                        </>
                                    ) : (
                                        <>
                                          <div
                                              className={classNames(
                                                  styles.billingContext,
                                                  styles.total,
                                                  "d-flex justify-content-between py-3"
                                              )}
                                          >
                                            <div className={classNames(styles?.leftHeading?.colorgrey, 'clrLight')}>
                                              Total Annual Cost:{" "}
                                            </div>
                                            <div
                                                className={classNames(styles.rightHeading)}
                                            >
                                              {currencyFormat(
                                                  plan?.primary +
                                                  priceCapHandle() *
                                                  plan?.dependent
                                              )}

                                            </div>
                                          </div>
                                          <div
                                              className={classNames(
                                                  styles.billingContext,
                                                  styles.billingContextFinal,
                                                  styles.padTop4,
                                                  "d-flex justify-content-between "
                                              )}
                                          >
                                            <div className={styles.leftHeading}>
                                              Total Due Now:{" "}
                                            </div>
                                            <div
                                                className={classNames(
                                                    stylesSheet.totalRight
                                                )}
                                            >
                                              {currencyFormat(
                                                  plan?.primary +
                                                  priceCapHandle() *
                                                  plan?.dependent
                                              )}
                                            </div>
                                          </div>
                                        </>
                                    )}
                                  </>
                              )}
                              {annualBilling === "Monthly" && (
                                  <>
                                    <div
                                        className={classNames(styles.billingContext, 'mb-2')}
                                    >
                                      <h3 className={classNames(stylesSheet.mainHeading, 'mb-2')}>
                                        <Image
                                            className={stylesSheet.joinIcon}
                                            src="/images/billingfrequency-join.svg"
                                            alt="office-icon"
                                        />
                                        Billing Frequency
                                      </h3>

                                      <div className={styles.iconParaAnnual}>
                                        {annualBilling}
                                      </div>
                                    </div>

                                    <div
                                      className={classNames(
                                        styles.memberContext,
                                        styles.billingContext,
                                        "d-flex justify-content-between mb-3"
                                      )}
                                    >
                                      <h3
                                        className={classNames(
                                          styles.billingContext,
                                          stylesSheet.memberHead
                                        )}
                                      >
                                        Annual Upfront
                                      </h3>
                                      <h3
                                        className={classNames(
                                          styles.billingContext,
                                          stylesSheet.memberHead,
                                          "text-right"
                                        )}
                                      >
                                        Amount
                                      </h3>
                                    </div>

                                    <div
                                        className={classNames(
                                            styles.memberContext,
                                            styles.billingContext,
                                            "d-flex justify-content-between mb-2"
                                        )}
                                    >
                                      <div className={classNames(styles?.leftHeading?.colorgrey, 'clrLight')}>
                                        Primary Member
                                      </div>
                                      <div className={styles.rightHeading}>
                                        {currencyFormat(plan?.yearly_one_time_primary)}
                                      </div>
                                    </div>

                                    {addDependents === 0 ? (
                                        ""
                                    ) : (
                                        <div
                                            className={classNames(
                                                styles.memberContext,
                                                styles.billingContext,
                                                "d-flex justify-content-between mb-4"
                                            )}
                                        >
                                          <div className={classNames(styles.leftHeading.colorgrey, 'clrLight')}>
                                            {addDependents} dependent(s) @ $
                                            {plan?.yearly_one_time_dependent}/yr
                                          </div>
                                          <div className={styles.rightHeading}>
                                            {currencyFormat(
                                              priceCapHandle() *
                                                plan?.yearly_one_time_dependent
                                            )}
                                          </div>
                                        </div>
                                    )}

                                    <div
                                        className={classNames(
                                          styles.memberContext,
                                          styles.billingContext,
                                          "d-flex justify-content-between mb-3"
                                        )}
                                    >
                                      <h3
                                        className={classNames(
                                          styles.billingContext,
                                          stylesSheet.memberHead,
                                          "pt-0"
                                        )}
                                      >
                                        Monthly Recurring
                                      </h3>
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.billingContext,
                                            styles.memberContext
                                        )}
                                    >
                                      <div className="d-flex justify-content-between mb-2">
                                        <div className={classNames(styles?.leftHeading?.colorgrey, 'clrLight')}>
                                          Primary Member
                                        </div>
                                        <div className={styles.rightHeading}>
                                          {currencyFormat(plan?.primary)}
                                        </div>
                                      </div>

                                      {addDependents === 0 && plan ? (
                                          ""
                                      ) : (
                                          <div className="d-flex justify-content-between pt-2 pb-2">
                                            <div className={classNames(styles.leftHeading.colorgrey, 'clrLight')}>
                                              {addDependents} dependent(s) @ $
                                              {plan?.dependent}/mo
                                            </div>
                                            <div className={styles.rightHeading}>
                                              {currencyFormat(
                                                  priceCapHandle() *
                                                  plan?.dependent
                                              )}
                                            </div>
                                          </div>
                                      )}
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.billingContext,
                                            styles.total,
                                            "d-flex justify-content-between py-3"
                                        )}
                                    >
                                      <div className={classNames(styles?.leftHeading?.colorgrey, 'clrLight')}>
                                        Total Annual Cost:{" "}
                                      </div>
                                      <div className={classNames(styles.rightHeading)}>
                                        {currencyFormat(
                                            plan?.yearly_one_time_primary +
                                            priceCapHandle() *
                                            plan?.yearly_one_time_dependent +
                                            (plan?.primary * 12 +
                                              priceCapHandle() *
                                                plan?.dependent *12)
                                        )}

                                      </div>
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.billingContext,
                                            styles.billingContextFinal,
                                            styles.padTop4,
                                            "d-flex justify-content-between"
                                        )}
                                    >
                                      <div className={classNames(styles.leftHeading)}>
                                        Total Due Now:{" "}
                                      </div>
                                      <div
                                          className={classNames(
                                              // styles.rightHeading,
                                              stylesSheet.totalRight
                                          )}
                                      >
                                        {currencyFormat(
                                            plan?.yearly_one_time_primary +
                                            priceCapHandle() *
                                            plan?.yearly_one_time_dependent +
                                            (plan?.primary +
                                              priceCapHandle() *
                                                plan?.dependent)
                                        )}
                                      </div>
                                    </div>
                                  </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classNames(stylesSheet.dependBillingContainer, 'pt-5')}>
                      <Elements stripe={stipePromise}>
                        <PaymentDetails
                            setCardSource={setCardSource}
                            formSubmitted={formSubmitted}
                            setLoader={setLoader}
                        />
                      </Elements>
                    </div>
                  </div>


                  <div className="">
                    <div
                        className={classNames(
                            stylesSheet.btnWrapper,
                            " d-flex justify-content-end"
                        )}
                    >
                      <div className={stylesSheet.cancelWrapper}>
                        <button
                            type="button"
                            className={stylesSheet.cancelBtn}
                            onClick={() => {
                              document.body.style.overflow = "scroll";
                              props.setOpen(props.close);
                            }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className={stylesSheet.addWrapper}>
                        <button
                            type="submit"
                            className={classNames(stylesSheet.addBtn)}
                            disabled={loader}
                        >
                          {loader && (
                              <HalfCircleSpinner
                                  size={20}
                                  style={{
                                    display: "inline-block",
                                    marginRight: "8px",
                                  }}
                              />
                          )}{" "}
                          Start &amp; Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Drawer>
        )
      }
    </>
  );
};
