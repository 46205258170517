import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { useAuth } from "../../api/services/auth";
import memberInfoService from "../../api/services/memberInfoService";
import styles from "../../styles/module/dashboard.module.scss";
import ErrorHandler from "../../components/ErrorHandler";
import MemberIdCard from "../../components/dashboardViews/memberIdCard";
import AccountDetails from "../../components/dashboardViews/accountDetails";
import Dependantslist from "../../components/dashboardViews/dependantsList";
import SubscriptionDetails from "../../components/dashboardViews/subscriptionDetails";
import PaymentTable from "../../components/dashboardViews/paymentTable";
import { useIdleTimer } from 'react-idle-timer';
import dashboard_json from '../../components/dashboardViews/test-data/dashboard.json';
import "../../assets/css/dashboard.css"
import useScript  from '../../hooks/useScript'
import useStorage from "../../hooks/useStorage";
import HfdWidget from "../../components/hfdWidget/hfdWidget";
import getHFDbyLocation from "../../api/services/getHFDbyLocation";
import classNames from "classnames";
import Modal from "react-bootstrap/Modal";
import HFD_logo from "../../assets/images/HFD_logo.png";
import hStyles from "../../styles/module/hfdModal.module.scss";
import { SearchSelect } from "../../components/shared-components/searchSelect";
import { hfdDropdownOptions, hfdDropdownStyles, statesUS } from "../../data/join-now";
import {HalfCircleSpinner} from "react-epic-spinners";
import {getHFDauthenticLink, hfdRequestBody} from "../../api/services/getHFDauthenticLink";
import AFDLogo from "../../assets/images/active_&_fit_logo.svg"

// export default function Dashboard(props) {
const Dashboard = (props) => {
    const { isAuthenticated, user: currentDetails, setShowApiLoader } = useAuth();
    const [user, setUser] = useState(currentDetails);
    const [apiErrors, setApiErrors] = useState();
    const [successTextMsg, setSuccessTextMsg] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showHfd, setShowHfd] = useState(false);
    const [bannerClose, setBannerClose] = useState(false);
    const { setItemInStorage , getStoredItem, deleteStoredItem } = useStorage();
    const [showWidget, setshowWidget] = useState(false);
    const [errors, setErrors] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [totalCost, setTotalCost] = useState("");
    const [showLoader, setShowloader] = useState(false);
    const [disable, setDisable] = useState(true);
    
    let failureRef = useRef();
    
    useEffect(() => {
        if (successTextMsg) {
            setShowApiLoader(true)
            memberInfoService.memberInfo().then((response) => {
                setUser(response.data);
                setShowApiLoader(false)
            });
        }
    }, [successTextMsg]);
    
    useEffect(() => {
        const token = Cookies.get("membersyToken");

        if (token === undefined) {
            window.location.pathname = "/";
        }
    }, []);

    // Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Dashboard | membersy";
	}, []);
    
    // idle timeout
    const handleOnIdle = event => {

        // user will be logout when the idle time meet
        Cookies.remove("membersyToken");
        deleteStoredItem('bannerHide');
        window.location.replace("https://membersy.com/")
    }
    
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 3, // 3-minutes
        onIdle: handleOnIdle,
        debounce: 500
    })

    const status = useScript('/js/pendo.js');
    
    useEffect(() => {
        if (isAuthenticated === true && status === "ready") {
            pendoInit()
            if(process?.env?.REACT_APP_HFD_WIDGET_ENABLE==="true"){
                hfdCheck()
            }
        }
    }, [isAuthenticated, status])

    const hfdCheck = () => {
        const location_id = currentDetails?.location_id;
        getHFDbyLocation.getHFDbyLocation(location_id).then((res) => {
            if (res?.data) {
                if(res?.data?.hfd_is_enabled==1){
                    setshowWidget(true);
                    const bannerHide=getStoredItem('bannerHide');
                    if(bannerHide==null){
                        setBannerClose(true);
                    }
                }
            }
        })
    };

    const handleBannerClose = () => {
        setItemInStorage('bannerHide',"1");
        setBannerClose(false);
    };

    const pendoInit = () => {
        window.pendo.initialize({
            visitor: {
                id: process.env.REACT_APP_ENVIRONMENT + '-' +   currentDetails?.id,
                status: currentDetails?.Memberships[0].status,
                email: currentDetails?.email,
                person_id: currentDetails?.Memberid,
                full_name: currentDetails?.first_name + ' ' + currentDetails?.last_name,
                first_name: currentDetails?.first_name,
                last_name: currentDetails?.last_name,
                dob: currentDetails?.dob,
                city: currentDetails?.city,
                state: currentDetails?.state,
                zip_code: currentDetails?.zip,
                auto_renew: currentDetails?.Memberships[0]?.status === "Current" && user?.card !== false ? 'Yes' : 'No',
                num_dependents: currentDetails?.Dependents.length,
                created_at: currentDetails?.created_at,
                last_login_at: currentDetails?.last_login_at,
                locale: process.env.REACT_APP_LOCALE,
                location_id: currentDetails?.location_id,
                location_name: currentDetails?.practice_name,
                location_created_at: currentDetails?.location_created_at,
                location_status: currentDetails?.location_status,
                plan_id: currentDetails?.plan_id,
                plan_name: currentDetails?.plan_name,
                plan_max_dependents: currentDetails?.plan_max_dependents,
                plan_created_at: currentDetails?.plan_created_at,
                environment: process.env.REACT_APP_ENVIRONMENT,
                product: 'Member Portal',
                version: process.env.REACT_APP_VERSION
            },
            account: {
                id: process.env.REACT_APP_ENVIRONMENT + '-' + currentDetails?.partner_id,
                partner_id: currentDetails?.partner_id,
                partner_name: currentDetails?.partner_name,
                partner_created_at: currentDetails?.partner_created_at,
                partner_status: currentDetails?.partner_status
            }
        })
    }

    const getProviderLink = () => {
        setShowloader(true)
        setErrors("")

        const selectedState = statesUS.filter((e) => e.label == currentDetails.state);
        currentDetails.StatoId = selectedState[0].value;
        currentDetails.TotalCost = totalCost.value;

        const requestBody = hfdRequestBody(currentDetails)

        getHFDauthenticLink.getLink(requestBody).then((res) => {
            setShowloader(false)
            setDisable(false)

            if (res.status === 200) {
                setTermsChecked(false)
                setTotalCost("")
                setDisable(true)
                setShowHfd(false)
                let url = res.data
                window.open(url, '_blank', 'noopener, noreferrer');

            } else {
                setErrors(res.message)
            }
        })
    }
    
    return (
        <>
            {isAuthenticated && (
                <>
                    <div className={classNames(styles.dashbWrapper, "bgWrapper")}>
                        {apiErrors && (
                            <div ref={failureRef}>
                                <ErrorHandler apiErrors={apiErrors} />
                            </div>
                        )}
                        { bannerClose && (
                            <div className="hfdAlertBanner">
                                <div className="hfdLeftWrap">
                                    <span className="icnWrap"><i className="fa-solid fa-bullhorn icn"></i></span>
                                    <p>Big news! We're excited to announce that we've teamed up with Healthcare Finance Direct to deliver affordable payment options.</p>
                                </div>
                                <div className="hfdRightWrap">
                                    <button
                                        type="button"
                                        className="btn btnWhite"
                                        onClick={() => {
                                            setShowHfd(true)
                                        }}
                                    >Learn More</button>
                                    <button type="button" className="btnCloses"  onClick={() => {handleBannerClose()}}><i className="fa-solid fa-xmark icn"></i></button>
                                </div>
                            </div>
                        )}
                        <section
                            className={classNames(currentDetails.location_id === null ? 'hideAllActionBtn' : '', 'dashboardWrapper')}
                        >
                            <div className="layoutOuterWrap">
                                {/* My Account Details */}
                                <AccountDetails 
                                    user = {currentDetails}
                                    showWidget = {showWidget}
                                />

                                <div className ={`madRow ${showWidget ? '' : 'hfd_false'}`}>
                                    <div className ="madCol madLeftCol">
                                        <div className={classNames(styles.mainContainer)}>
                                            {/*Dependant listings*/}
                                            <Dependantslist
                                                user = {currentDetails}
                                            />
                    
                                            {/* subscription table */}
                                            <SubscriptionDetails 
                                                dashboard_json = {dashboard_json}
                                                user = {currentDetails}
                                            />
                                            
                                            {/* payments table */}
                                            <PaymentTable 
                                                dashboard_json = {dashboard_json}
                                                user = {currentDetails}
                                            />
                                        </div>
                                    </div>
                                    <div className ="madCol madRightCol">
                                        <MemberIdCard
                                            dashboard_json = {dashboard_json}
                                            user = {currentDetails}
                                        />
                                    </div>
                                </div>
                                {/* Active_&_Fit_Widget */}
                                {process?.env?.REACT_APP_ENABLE_AFD_WIDGET === 'true' &&
                                    <div className="activeAndFitWidget">
                                        <div className="contentWrap">
                                            <div className="logoWrap">
                                                <img src={AFDLogo} className="img-fluid" alt="Active &amp; Fit Logo"/>
                                            </div>
                                            <div className="textWrap">
                                                <p>Get access to 8,100+ of the gyms and studios you know and love, with no long-term contracts. And, enjoy workout videos led by world-class instructors, from the comfort of your home. It's the flexibility you need in a fitness routine. All for just $29/month.</p>
                                            </div>
                                        </div>
                                        <div className="btnWrap">
                                            <a href={process?.env?.REACT_APP_CHOOSE_HEALTHY_URL} className="btn afBtn">Join Now</a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>
                        <Modal
                            show={showHfd}
                            className={classNames(hStyles.hfdPopupContainer, hStyles.fontDmSans, "HFDpopup")}
                            centered
                            dialogClassName={hStyles.popupDialogue}
                            backdrop="static"
                        >
                            <Modal.Body
                                contentclassname={hStyles.modalContentArea}
                                >
                                <button 
                                    type="button" 
                                    className="hfdBtnClose"
                                    onClick={() => {
                                        setShowHfd(false)
                                        setTermsChecked(false)
                                        setTotalCost("")
                                        setDisable(false)
                                        setErrors("")
                                    }}
                                >
                                    <i className="fa-solid fa-xmark icn"></i>
                                </button>
                                <div className={hStyles.hfdPopupImg}>
                                    <img
                                        src={HFD_logo}
                                        className="img-fluid"
                                        alt="Healthcare Finance Direct"
                                    />
                                </div>
                                <div className={classNames(hStyles.hfdDescriptionWrap)}>
                                    <h1 className={classNames(hStyles.hfdPopupHeading, "h1")}>An Affordable Pay-Over-Time Option For Every Single Patient!</h1>
                                    <p className={hStyles.hfdPopupPara}>Access to good healthcare can be expensive. Thousands of patients postpone the treatment they need every year because they lack cash or can’t access credit. Healthcare Finance Direct has a solution. Apply now and receive financing you deserve for your next dental procedure!</p>
                                </div>
                                <form className={classNames(hStyles.hfdFormStyles,"hfdForm")}>
                                    <div className="formRow">
                                        <label className="formLabel">How much financing do you need?</label>
                                        <div className="selectWrap">
                                            <SearchSelect
                                                styles={hfdDropdownStyles}
                                                placeholder="Select Financing Amount..."
                                                options={hfdDropdownOptions}
                                                value={totalCost}
                                                onChange={(event) => setTotalCost(event)}
                                                theme={(theme) => ({...theme,
                                                    borderRadius: 0,
                                                    colors: {...theme.colors,
                                                    primary: "#2563EB",
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="formRow">
                                        <div className="fakeWrap">
                                            <input 
                                                type="checkbox" 
                                                className="fakeInput"
                                                id="terms"
                                                checked={termsChecked}
                                                onChange={() => setTermsChecked(!termsChecked)}
                                            />
                                            <label htmlFor="terms" className="fakeLabel">By checking this box, I am confirming that the financing amount needed for my dental procedure was the amount giving to me by my dental provider.</label>
                                        </div>
                                    </div>
                                    <div className={classNames(hStyles.detailsButton, "btnWrap")}>
                                        <button
                                            type="button"
                                            className={classNames(hStyles.applyButton)}
                                            disabled={(!termsChecked || totalCost === '') ? disable : showLoader}
                                            onClick={() => { getProviderLink() }}
                                        >
                                            {showLoader && (
                                                <HalfCircleSpinner size={15}
                                                    style={{
                                                        display: "inline-block",
                                                        marginRight: "10px",
                                                        verticalAlign: "middle",
                                                    }}
                                                />
                                            )}
                                            Apply Now
                                        </button>
                                    </div>
                                    {errors && (
                                        <small className="d-block w-100 text-center text-danger mt-2">{errors}</small>
                                    )}
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            )}
        </>
    );
}

export default Dashboard;
