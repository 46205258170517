import React, {useState} from 'react';
import ForgotPasswordService from "../api/services/forgotPasswordService";
import {useInput} from '../hooks/useInput';
import {Form} from 'react-bootstrap';
import {HalfCircleSpinner} from 'react-epic-spinners';
import Modal from "react-bootstrap/Modal";
import popuptickicn from "../assets/images/popup-tick.svg";
import styles from "../styles/module/forgotPassword.module.scss";
import classNames from "classnames";
import ValidateEmail from "../utility/emailRegExp";
import moment from 'moment';

// export default function ForgotPasswordForm({ group_key }){
const ForgotPasswordForm = (props) => {
    
    const [email, bindEmail, resetEmail] = useInput("");
    const [errors, setErrors] = useState(false);
    const [prevEmail, setPrevEmail] = useState();
    const [showLoader, setShowLoader] = useState(false);
    
    const [show, setShow] = useState(false);
    const [emailPopup, setEmailPopup] = useState(false);

    const validateForgot = () => {
        let errors = {};
        let isValid = true;
        if (!email) {
            isValid = false;
            errors["email"] = "Please enter your Email Address.";
        }

        if (email) {
            var pattern = new RegExp(ValidateEmail.RegExp);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Please enter valid Email Address.";
            }
        }
        setErrors(errors);
        return isValid;
    };

    const storeEmail = () => {
        var storeTime = new Date();
        localStorage.setItem('userData', JSON.stringify({email: email, time: storeTime}));
    }
    
    const stringifiedUserData = localStorage.getItem('userData');
    const userDataAsObjectAgain = JSON.parse(stringifiedUserData);


    const checkTimeDiff = () => {
        var currentTime = moment(new Date()); //current time
        var storedTime = moment(userDataAsObjectAgain?.time); //stored time
        var duration = moment.duration(currentTime.diff(storedTime));
        var minutes = duration.minutes();
        return minutes;
    }

    const forgotPasswordHandler = (event) => {
        event.preventDefault();
        let errors = {}
        if (validateForgot()) {
            setShowLoader(true);
            let data = { email: email,  group_key: ["smileselectmemberhip", "vantageonesavingsplan", "dentaldepot"] };

            if (checkTimeDiff() <= 5 && stringifiedUserData !== null && data?.email === userDataAsObjectAgain?.email) {
                setShowLoader(false);
                setEmailPopup(true);
            } else {
                ForgotPasswordService.forgotPass(data).then((response) => { 
                    if (response.status === 200) {
                        errors['resetSuccess'] = response.message;
                        setShowLoader(false)
                        setPrevEmail(email);
                        setShow(true);
                        storeEmail()
                    } else {
                        errors['email'] = response.message;
                        setShowLoader(false)
                        setShow(false);
                    }
                });
                setErrors(errors);
            }
        }
    };
    
    const handleClose = () => {
        window.location.replace('/login')
    };
    
    return(
        <div className="loginFormWrapper">
            <Form data-testid="forgotForm" onSubmit={(event) => forgotPasswordHandler(event)} className="loginForm">
                <div className="inputWrapper">
                    <label className={"ccLabelText"}>Email address</label>
                    <input  
                        type="email" 
                        placeholder="jane@example.com"
                        className={`email customInputError customInput formControl  ${errors.email && "fcError"}`} 
                        {...bindEmail}
                    />
                    {errors.email && (
                        <div data-testid="forgotEmailFieldErr"  className="formErrors">{errors.email}</div>
                    )}
                </div>
                <Form.Row>
                    <button
                    data-testid="resetBtn"
                    variant="primary" type="submit" className="resetBtn btn btnPrimary"
                    disabled={showLoader}
                    onClick={() => { 
                        setShow()
                    }}
                    >
                    {showLoader && (
                        <HalfCircleSpinner
                            size={18}
                            style={{
                            display: "inline-block",
                            marginRight: "5px",
                            }}
                        />
                    )}
                        Submit</button>
                </Form.Row>
                <Form.Row>
                    <button
                        type="button" className="resetBtn btn btnPrimary"
                        onClick={() => {
                            props.setShowForgotPasswordForm(false)
                        }}
                    >
                        Go back
                    </button>
                </Form.Row>
            </Form>
            <div data-testid="success_modal">
                <Modal
                    show={show}
                    className={classNames(
                        styles.popupContainer,
                        "forgetPasswordModal"
                    )}
                    size="lg"
                    centered
                    dialogClassName={styles.popupDialogue}
                    onHide={() => setShow(false)}
                    backdrop="static"
                >
                    <Modal.Body className={classNames(styles.modalBodyContent, "pt-0")} data-testid="success_msg">
                        <div className={styles.popupImg}>
                            <img
                                src={popuptickicn}
                                alt="forget-pasword-successfull"
                            />
                        </div>
                        <h3 className={styles.popupHeading}>
                            Submitted Successfully!
                        </h3>
                        <div data-testid="success_msg">
                            <div className="frgtSuccess">Your password reset token has been sent to {email}.</div>
                            <span className="memTxtSmall">If you do not see an email from member@membersy.com to reset your password, please check your spam.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={classNames(
                        styles.footerStyles,
                        "justify-content-center"
                    )}>
                        <button type="submit" className="btn btnPrimary backButton"
                            onClick={()=>{
                                handleClose()
                            }}
                        >
                          Return to Member Login
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div data-testid="check_email_modal">
                <Modal
                    show={emailPopup}
                    className={classNames(
                        styles.popupContainer,
                        "forgetPasswordModal"
                    )}
                    size="lg"
                    centered
                    dialogClassName={styles.popupDialogue}
                    onHide={() => setEmailPopup(false)}
                    backdrop="static"
                >
                    <Modal.Body className={classNames(styles.modalBodyContent, "pt-0")} data-testid="success_msg">
                        <h3 className={styles.popupHeading}>
                            Something Went Wrong!
                        </h3>
                        <div data-testid="success_msg" style={{maxWidth: '450px', marginLeft: 'auto', marginRight: 'auto'}}>
                            <div className="frgtSuccess">We are unable to process your request. Please wait 5 minutes and try again.</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={classNames(
                        styles.footerStyles,
                        "justify-content-center"
                    )}>
                        <button type="button" className="backButton btn btnPrimary"
                            onClick={()=>{
                                setEmailPopup(false)
                            }}
                            style={{minWidth: '150px'}}
                        >
                          Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )   
}

export default ForgotPasswordForm;
