import React from "react";
import Modal from "react-bootstrap/Modal";
// import { classNames } from "react-select/dist/declarations/src/utils";
import classNames from "classnames";
import styles from "../styles/module/termsModal.module.scss";

const TermsModal = (props) => {
  const { onClose, open, planName, planUrl, member_email } = props;

  const handleClose = () => {
    onClose(false);
  };
  return (
    <Modal
      show={open}
      size="lg"
      className={classNames(
        styles.popupContainer,
        "checkouttermsModal"
      )}
      centered
      onHide={handleClose}
    >
      <Modal.Header className={styles.hedaerAlign}>
        <Modal.Title
          data-testid="term_modal_heading"
          id="contained-modal-title-vcenter"
          className={styles.motalTitle}
        >
          Member Terms &amp; Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBodyContent}>
        <div className="memwp_decr_box" data-testid="term_modal_body">
          <p><span className="text-capitalize">{planName}</span> is a discount dental membership plan administered by Membersy LLC, a Texas limited liability company (“​Company​”).</p>
          <p>1. <strong>Plan Summary, Disclosures.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p><span className="text-capitalize">{planName}</span> (“<strong>Plan</strong>”) is a discount dental membership plan administered by Membersy LLC (“<strong>Company</strong>”). Membership in Plan entitles members to discounts for certain identified dental services rendered by participating providers in accordance with the Plan fee schedule. Discounts may vary depending on the type of provider and the service received.</p></li>
          <li><p><strong>Plan is NOT INSURANCE. Plan members are obligated to pay providers for all services rendered. Company does not pay providers for services rendered to members.</strong> Neither Company nor Plan are affiliated with or endorsed by any state insurance department.</p></li>
          <li><p>Plan members may visit Plan website at <a target="_blank" rel="noreferrer" href="https://membersy_marketplace.com">membersy_marketplace.com</a> or contact Company’s Member Services department to obtain additional information, including an up-to-date list of participating providers and a complete description of reduced rates under the Plan fee schedule.</p>
          <ul className="listUnstyled simContactList">
          <li>
          <span className="title">Mailing address:</span>
          <span className="descr">membersy <br />811 Barton Springs Road, Suite 750 <br />Austin, TX 78704 <br />Attention: Member Services</span>
          </li>
          <li>
          <span className="title">Toll-free telephone:</span>
          <span className="descr">(888) 231-5266 (9am-7pm EST, Monday-Friday)</span>
          </li>
          <li>
          <span className="title">Email:</span>
          <span className="descr"><a href="mailto:member@membersy.com">member@membersy.com</a></span>
          </li>
          </ul>
          </li>
          <li><p>If Plan member remains dissatisfied after completing the complaint procedure detailed herein, the member may contact his/her state insurance department.</p></li>
          <li><p><strong>You, the Plan member, may cancel your membership at any time by contacting Member Services to request cancellation as fully set forth in Section 6 below. You will receive a full refund of membership fees paid to Company if the cancellation request is received within the first thirty (30) days of the current annual contract term. Florida residents may be entitled to receive a pro-rata refund of Membership Fees paid to Company upon cancellation. Please contact Member Services for details.</strong></p></li>
          </ul>
          <p>2. <strong>Definitions.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p><strong>Member: </strong>An individual, family member or dependent enrolled in Plan. Plan shall only cover those individuals, family members and/or dependents who are enrolled in the Plan at the time of service.</p></li>
          <li><p><strong>Provider: </strong>A licensed dental services provider participating in Plan.</p></li>
          <li><p><strong>Membership Fees: </strong>Annual fees payable to Company upon enrollment in Plan and upon renewal of Plan term. Membership fees may vary from term to term; you will be provided with notice of any changes to membership fees.</p></li>
          <li><p><strong>Effective Date: </strong>The date that the Member submits the completed Member Application and applicable Membership Fees to Company. Members shall be entitled to receive Plan discounts from Providers as of the Effective Date.</p></li>
          <li><p><strong>Savings Summary: </strong>The schedule of applicable fees to be charged to Members for dental services rendered under the Plan, as published on the Plan website. Savings Summary may be amended from time to time.</p></li>
          <li><p><strong>Agreement: </strong>The complete Plan membership contract between Company and the Plan member, consisting of (i) Member Application, (ii) Member Terms and Conditions, and (iii) Savings Summary.</p></li>
          </ul>
          <p>3. <strong>Plan Description, Limitations, Exclusions &amp; Exceptions.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p>Plan is a discount dental membership plan administered by Company and offered in participating dental practices and online through Plan website. Company is not a licensed insurer, health maintenance organization, preferred provider organization, or underwriter of health care services. Company is not licensed to provide and does not provide dental services. Members are eligible to receive discounts on dental services in accordance with the Savings Summary from any Provider participating in Plan. Members are obligated to pay Providers for dental services at the time of service in accordance with the Provider’s payment policies. No portion of any Provider’s fees will be reimbursed or otherwise paid by Company. Because some savings are based on a percentage of the individual Provider’s usual and customary rates, actual savings may vary. Plan may not be used in conjunction with any other membership plan, discount health care program or third-party payor program, including government and private third-party payor programs (e.g., Medicaid, private insurance). All savings amounts listed on Savings Summary are current savings offered by Providers and are subject to change. From time to time, Providers may, at their discretion, offer services or products to the general public at prices lower than the Savings Summary prices available through Plan membership.</p></li>
          <li><p>Providers are solely responsible for the services and products received by Members, and Company disclaims any liability with respect to the provision of such services and products. Company cannot guarantee the continued participation of any Provider. Any Provider’s participation in Plan may be terminated at any time without prior notice to Members.</p></li>
          <li><p>The following is a list of express limitations, exclusions and exceptions under the Plan:</p>
          <ul className="listUnstyled simTermsList simTermsListii">
          <li><p>Discounts for treatments of fractures or dislocations, congenital malformations, malignancies, cysts or neoplasms, and Temporomandibular Joint Syndrome (TMJ) are not provided.</p></li>
          <li><p>Discounts for prescription drugs and over-the-counter drugs are not provided.</p></li>
          <li><p>Prophylaxis (cleaning) is limited to once every six months.</p></li>
          <li><p>Full-mouth x-rays are limited to once every 24 months.</p></li>
          <li><p>Replacement of partial dentures is limited to once every five years.</p></li>
          <li><p>Full upper and/or lower dentures are not to exceed one each in any five-year period.</p></li>
          <li><p>Denture relines are limited to one per arch in any 12-month period.</p></li>
          <li><p>Services performed by any non-participating provider are not covered.</p></li>
          </ul>
          </li>
          </ul>
          <p>4. <strong>Enrollment.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p>Members are eligible to receive discounts on dental services in accordance with the Savings Summary from participating Provider(s) in the dental office(s) in which the Plan membership was purchased. Members may obtain an updated list of Providers at any time by visiting Plan website or by contacting Member Services. If any Provider’s participation in Plan is terminated, affected Members shall be given an opportunity to select a new Provider from the list of participating Providers; if there are no other participating Providers offering services in Member’s immediate area, Member may request a membership cancellation and pro-rata refund of Membership Fees in accordance with Company’s cancellation policy as set forth in Section 6 below.</p></li>
          <li><p>Individual Members are eligible to purchase additional memberships for up to nine (9) family members or dependents under their own Plan membership by contacting Member Services or by using the self-service member portal on Plan website. Company shall notify the Member at the time of purchase regarding any changes to Plan pricing and/or term renewal date that may result from the addition of new Members to an existing Plan membership.</p></li>
          </ul>
          <p>5. <strong>Contract Term/Renewal.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p>All Plan memberships are annual contracts; your initial contract term will begin on the Effective Date and will continue for twelve (12) months thereafter. Your Plan membership will automatically renew for an additional one-year term at the end of each annual contract term, and payment of Membership Fees for the renewal term shall automatically be charged to or drafted from your credit card or bank account using payment information on file with Company. Your Plan membership shall remain in effect until it is canceled in accordance with Section 6 below.</p></li>
          <li><p>By enrolling in Plan and providing your payment information to Company, you, the Member, are authorizing Company to bill your credit card or checking account for Membership Fees for the initial term and any renewal term(s) at the plan level rate that you have selected.</p></li>
          <li><p>Company shall attempt to notify you prior to automatic renewal using the contact information on file with Company. You are responsible for ensuring that your contact information remains accurate and up-to-date.</p></li>
          <li><p>You may change your method of payment at any time by submitting a request in writing to Member Services or by using the self-service member portal on Plan website.</p></li>
          </ul>
          <p>6. <strong>Cancellation Policy.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p>Company reserves the right to immediately cancel your Plan membership without prior notice at any time and for any reason, including non-payment of Membership Fees. In the event that Company cancels your Plan membership for any reason other than non-payment of Membership Fees, you shall receive a pro-rata refund of Membership Fees paid to Company within thirty (30) calendar days after the effective date of cancellation.</p></li>
          <li><p>If you wish to cancel your Plan membership, please send a cancellation notice with your name and Plan ID number to Member Services via mail or email to the address listed in Section 1 above, or call Member Services via telephone to request cancellation.</p></li>
          <li><p>Members shall receive a full reimbursement of any Membership Fees that have been paid during the current term if (i) the cancellation request is received within the first thirty (30) days of the current annual contract term, and (ii) no dental services have been provided to the Member under the Plan during the current term. Company reserves the right to contact Providers to determine whether dental services have been provided to the Member. Written notice of cancellation is deemed given when (i) sent via email to the correct address, or (ii) deposited in a mailbox, properly addressed, and postage prepaid to Company’s mailing address above. Florida residents may be entitled to receive a pro-rata refund of Membership Fees paid to Company upon cancellation. Please contact Member Services for details.</p></li>
          </ul>
          <p>7. <strong>Member Responsibilities.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p>Members are required to make payment directly to Providers for all dental services provided hereunder in accordance with the Provider’s payment policies. <strong>Plan savings and Providers are subject to change, and Members may be responsible for related additional services and charges, such as lab fees associated with the dental services received. For an up-to-date list of participating Providers and Savings Summary discounts, you may visit Plan website or email Member Services at any time.</strong></p></li>
          <li><p>Member is responsible for verifying that his/her dental services provider is an active participant in Plan prior to receiving dental services. Providers are responsible for the provision of dental services and for informing Members of the Provider’s treatment policies.</p></li>
          </ul>
          <p>8. <strong>Complaint Procedure.</strong></p>
          <ul className="listUnstyled simTermsList">
          <li><p>Complaints regarding your Plan membership must be submitted in writing to Member Services via email or mail to the address listed in Section 1 above. Member Services shall review your complaint and contact you regarding a resolution within five (5) business days.</p></li>
          <li><p>If you are dissatisfied after completing the complaint procedure detailed above, you may contact your state insurance department. Member Services will provide contact information for your state insurance department upon request.</p></li>
          </ul>
          <p>You, the Member, may cancel this Agreement at any time by contacting Member Services to request cancellation. You will recieve a full refund of all Membership Fees if (i) the cancellation request is recieved within the first thirty (30) days of the current annual contract term, and (ii) no dental services have been provided to you under the Plan.</p>
          </div>
      </Modal.Body>
      <Modal.Footer 
        className={classNames(
          "pt-4 pb-0",
          styles.footerStyles
        )} 
      >
        <button 
          className={classNames(
            "btn btnPrimary",
            // styles.btnStyles
          )} 
          onClick={handleClose}
        >
          Accept
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsModal;
