const UnfromatedPhone = (value) => {

    let refinePhoneNumber = value
    ?.replaceAll("(", "")
    ?.replaceAll(")", "")
    ?.replaceAll("-", "")
    ?.replaceAll(" ", "");
    
    return refinePhoneNumber;
}
export default UnfromatedPhone;