import styles from "../styles/module/join-now.module.scss";

export const colourStyles = {
  control: (base) => ({
    ...base,
    border: `1px solid #606E7A`,
    borderRadius: "10px",
    width: "100%",
    height: "50px",

    color: "#0B4DA2",
    "&:hover": { borderColor: " #0B4DA2" },

    boxShadow: "none",
    "@media (min-width: 768px)": {},
  }),

  placeholder: (base) => ({
    ...base,
    color: "#888888",

    fontFamily: "Proxima Nova",
    mediaquery: { fontSize: "17px" },
  }),
  option: (base) => ({
    ...base,
    width: "100%",

    fontFamily: "Proxima Nova",
    color: "#0B4DA2",
    background: "white",
  }),
  value: (base) => ({
    ...base,
    color: "white",
    background: "white",

    fontFamily: "Proxima Nova",
    "@media (max-width: 1513px)": { fontSize: 16 },
    "@media (max-width: 1440px)": { fontSize: 16 },
    "@media (max-width: 1024px)": { fontSize: 16 },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#0B4DA2",
    "&:hover": { color: " #0B4DA2" },
  }),
  indicatorSeparator: (base) => ({
    border: "none",
  }),
  singleValue: (base) => ({
    color: "#0B4DA2",
  
    "@media (max-width: 1584px)": { fontSize: 16 },
    "@media (max-width: 1440px)": { fontSize: 16 },
    "@media (max-width: 1024px)": { fontSize: 16 },
    // mediaquery: { width: "400px" }
  }),
  menu: (base) => ({
    ...base,
    width: "100%",
    color: "white",
  }),
  menuList: (base) => ({
    ...base,
    color: "white",
  }),
  dropdownHandle: (base) => ({
    ...base,
    color: "#0B4DA2",
  }),

  ":hover": {
    ...styles[":hover"],
    border: `1px solid #0B4DA2`,
  },
};

export const colourStylesPost = {
  control: (base) => ({
    ...base,
    border: `1px solid #006F90`,
    borderRadius: "10px",
    width: "100%",
    height: "50px",
    borderColor: " #006F90",
    color: "#006F90",
    "&:hover": { borderColor: " #006F90" },

    boxShadow: "none",
    "@media (min-width: 768px)": {},
  }),

  placeholder: (base) => ({
    ...base,
    color: "#888888",

    fontFamily: "Proxima Nova",
    mediaquery: { fontSize: "17px" },
  }),
  option: (base) => ({
    ...base,
    width: "100%",

    fontFamily: "Proxima Nova",
    color: "#006F90",
    background: "white",
  }),
  value: (base) => ({
    ...base,
    color: "white",
    background: "white",

    fontFamily: "Proxima Nova",
    "@media (max-width: 1513px)": { fontSize: 16 },
    "@media (max-width: 1440px)": { fontSize: 16 },
    "@media (max-width: 1024px)": { fontSize: 16 },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#006F90",
    "&:hover": { color: " #006F90" },
  }),
  indicatorSeparator: (base) => ({
    border: "none",
  }),
  singleValue: (base) => ({
    color: "#006F90",

    "@media (max-width: 1584px)": { fontSize: 16 },
    "@media (max-width: 1440px)": { fontSize: 16 },
    "@media (max-width: 1024px)": { fontSize: 16 },
    // mediaquery: { width: "400px" }
  }),
  menu: (base) => ({
    ...base,
    width: "100%",
    color: "white",
  }),
  menuList: (base) => ({
    ...base,
    color: "white",
  }),
  dropdownHandle: (base) => ({
    ...base,
    color: "#006F90",
  }),

  ":hover": {
    ...styles[":hover"],
    border: `1px solid #006F90`,
  },
};

export const officesDropdownSubset = [
  {
    value: "familyDentalAtAlafayaCrossings",
    label: "Family Dental at Alafaya Crossings",
  },
  {
    value: "familyDentalAtLakesideVillage",
    label: "Family Dental at Lakeside Village",
  },
  { value: "familyDentalAtWildlight", label: "Family Dental at Wildlight" },
  {
    value: "familyDentalCareOfSouthBradenton",
    label: "Family Dental Care of South Bradenton",
  },
  { value: "familyDentalOfFortMyers", label: "Family Dental of Fort Myers" },
  {
    value: "familyDentistryAtSouthWood",
    label: "Family Dentistry at SouthWood",
  },
  { value: "familyDentistryOfLargo", label: "Family Dentistry of Largo" },
  {
    value: "FamilyOralHealthAssociates",
    label: "Family Oral Health Associates",
  },
  {
    value: "firstImpressionsSmileCenter",
    label: "First Impressions Smile Center",
  },
  { value: "fortHamerDentalCare", label: "Fort Hamer Dental Care" },
];

export const dependentsDropdown = [
  { value: 0, label: "No Thanks"},
  { value: 1, label: "Yes - 1 Dependent"},
  { value: 2, label: "Yes - 2 Dependents"},
  { value: 3, label: "Yes - 3 Dependents"},
  { value: 4, label: "Yes - 4 Dependents"},
  { value: 5, label: "Yes - 5 Dependents"},
  { value: 6, label: "Yes - 6 Dependents"},
  { value: 7, label: "Yes - 7 Dependents"},
  { value: 8, label: "Yes - 8 Dependents"},
  { value: 9, label: "Yes - 9 Dependents"},
];

export const plansDropdown = [
  { value: 1, label: "Smile Select Membership Plan", disabled: false },
]

export const statesUS = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export const hfdDropdownOptions = [
  { value: 500, label: "$ 500" },
  { value: 1000, label: "$ 1000" },
  { value: 1500, label: "$ 1500" },
  { value: 2000, label: "$ 2000" },
  { value: 2500, label: "$ 2500" },
  { value: 3000, label: "$ 3000" },
  { value: 3500, label: "$ 3500" },
  { value: 4000, label: "$ 4000" },
  { value: 4500, label: "$ 4500" },
  { value: 5000, label: "$ 5000" }
]

export const hfdDropdownStyles = {
  control: (base) => ({
    ...base,
    border: `1px solid #D1D5DB`,
    borderRadius: "6px",
    width: "100%",
    height: "38px",
    borderColor: " #D1D5DB",
    color: "#2563eb",
    fontFamily: "Proxima Nova",
    transition: "all 0.35s ease",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    "&:hover": { borderColor: "#b3b3b3" }
  }),

  placeholder: (base) => ({
    ...base,
    color: "#6B7280",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Proxima Nova"
  }),

  menu: (base) => ({
    ...base,
    borderRadius: "6px",
    borderColor: " #D1D5DB",
    zIndex: "2"
  }),

  singleValue: (base) => ({
    fontFamily: "Proxima Nova",
    fontSize: "15px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#000000"
  }),

  option: (styles, state) => ({
    ...styles,

    color: state.isSelected ? '#ffffff' : '#6B7280',
    '&:hover': { 
      color: state.isSelected ? '#ffffff' : '#6B7280' 
    }
  })
}

export const statusIndicatorList = [
  { status: "0", label: "Invalid", image: "/images/invalid-icn.svg" },
  { status: "1", label: "Unverified", image: "/images/unverified-icn.svg" },
  { status: null, label: "Unverified", image: "/images/unverified-icn.svg" },
  { status: "2", label: "Verified", image: "/images/verified-icn.svg" },
  { status: "3", label: "Verified", image: "/images/verified-icn.svg" }
]