import React, { useState, useEffect } from "react";
import styles from "../../styles/module/dashboard.module.scss";
import classNames from "classnames";
import { Row, Col ,Alert } from "react-bootstrap";
import MemberIdCard from "./memberIdCard"
import { PrimaryDetailsDrawer } from "../drawers/primaryDetailsDrawer";
import SuccessModal from '../successModal';
import dashboard_json from '../dashboardViews/test-data/dashboard.json';
import HfdWidget from "../hfdWidget/hfdWidget";
import { statusIndicatorList } from '../../data/join-now';

const AccountDetails = ({user, showWidget}) => {
        const [openPrimaryDetailsDrawer, setOpenPrimaryDetailsDrawer] = useState(false);  
        const [successTextHeading, setSuccessTextHeading] = useState("");
        const [successTextMsg, setSuccessTextMsg] = useState("");
        const [isSucess, setIsSucess] = useState(false);
        const [addressStatus, setAddressStatus] = useState("");
        const [emailStatus, setEmailsStatus] = useState("");
        const [phoneStatus, setPhoneStatus] = useState("");
        
        useEffect(() => {
            const addressVerificationInfo = statusIndicatorList.find((x) => x.status === user?.address_verification_level);
            setAddressStatus(addressVerificationInfo);

            const emailVerificationInfo = statusIndicatorList.find((x) => x.status === user?.email_verification_level);
            setEmailsStatus(emailVerificationInfo);

            const phoneVerificationInfo = statusIndicatorList.find((x) => x.status === user?.phone_verification_level);
            setPhoneStatus(phoneVerificationInfo);
        }, [])

        return (
            <div data-testid= "account_details" className="accountDetails">
                { (user.Memberships[0].status == "Unpaid" || user.Memberships[0].status == "Disputed") &&
                    <Alert variant="danger" className={styles.statusAlert}>
                        <img src="/images/alert.png" className={classNames(styles.alertImg, "img-fluid mr-2")}  alt="alert"/>
                        Attention! Your account's status is currently 
                        { user.Memberships[0].status == "Unpaid" ? ' inactive ' : ' under dispute '  }. 
                        Please Contact us at <Alert.Link className={styles.alertLink} href="tel:8775454188">(877) 545-4188</Alert.Link> for help in resolving this issue.
                    </Alert>
                }
                <h5 className={styles.memHeading}>My Account</h5>
                <div className={classNames(styles.mainContainer)}>
                    <div className ={`layoutWrap ${showWidget ? '' : 'hfd_false'}`}>
                        <div className="lwLeftCol">
                            <div className={classNames(styles.whiteBlock)}>
                                {/* User Details */}
                                <div className={classNames(styles.head, "pb-0 position-relative")}>
                                    <h3 className={classNames(styles.headingActive)}>
                                        <span
                                            className={classNames(
                                                user?.status === "Active"
                                                    ? styles.statusHeader
                                                    : styles.statusErrorMsg
                                            )}
                                        >
                                            {user?.status} Account&nbsp;
                                        </span>
                                        {user?.practice_name && (
                                            <span className="dashofcCapitalize">{user.practice_name}</span>
                                        )}
                                    </h3>
                                    <div className="editBtnWrap">
                                        <div
                                            data-testid = "edit_btn"
                                            className={classNames(
                                                styles.updateBtn,
                                                styles.wid100
                                            )}
                                            onClick={() => {
                                                document.body.style.overflow = "hidden";
                                                setOpenPrimaryDetailsDrawer(
                                                    !openPrimaryDetailsDrawer
                                                );
                                            }}
                                        >
                                            <div className={styles.editImg}>
                                                <img src={dashboard_json?.variables?.dashboard_edit_icon} className="img-fluid" alt="edit-icon" />
                                            </div>
                                            Edit
                                        </div>
                                    </div>                                   
                                </div>
                                <div className={classNames(styles.contentSec, "dbContentWrap")}>
                                    <Row>
                                        <Col xs={12} className="d-md-none">
                                            <div className={styles.colWrap}>
                                                <label>Plan Type</label>
                                                <p className={styles.userDetails}>
                                                    {user?.plan_name}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={5}>
                                            <div className={styles.colWrap}>
                                                <label>Primary Name</label>
                                                <p className={styles.userDetails}>
                                                    {`${user.first_name || ""} ${
                                                        user.last_name || ""
                                                    }`}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7} className="d-none d-md-block">
                                            <div className={styles.colWrap}>
                                                <label>Plan Type</label>
                                                <p className={styles.userDetails}>
                                                    {user?.plan_name}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={5}>
                                            <div className={styles.colWrap}>
                                                <label>Primary ID</label>
                                                <p className={styles.userDetails}>{user.Memberid}</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7}>
                                            <div className={styles.colWrap}>
                                                <label>Primary DOB</label>
                                                <p className={styles.userDetails}>
                                                    {user?.dob}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={5} className="d-none d-md-block">
                                            <div className={styles.colWrap}>
                                                <label>Email
                                                    <span className="statusIndicatorWrap">
                                                        <img src={emailStatus?.image} className="img-fluid" alt="Verification Status Indicator Image"/>
                                                        <span className="statusTooltip">{emailStatus?.label}</span>
                                                    </span>
                                                </label>
                                                <p
                                                    title={user.email}
                                                    className={classNames(
                                                        styles.userDetails,
                                                        styles.emailWrap
                                                    )}
                                                >
                                                    <a href={`mailto: ${user.email}`}> {user.email}</a>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7}>
                                            <div className={styles.colWrap}>
                                                <label>Street Address
                                                    <span className="statusIndicatorWrap">
                                                        <img src={addressStatus?.image} className="img-fluid" alt="Verification Status Indicator Image"/>
                                                        <span className="statusTooltip">{addressStatus?.label}</span>
                                                    </span>
                                                </label>
                                                <p className={styles.userDetails}>{user.address1}</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={5}>
                                            <div className={styles.colWrap}>
                                                <label>Phone
                                                    <span className="statusIndicatorWrap">
                                                        <img src={phoneStatus?.image} className="img-fluid" alt="Verification Status Indicator Image"/>
                                                        <span className="statusTooltip">{phoneStatus?.label}</span>
                                                    </span>
                                                </label>
                                                <p className={styles.userDetails}>
                                                    <a href= {`tel:${user.phone}`}>{user.phone}</a>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7}>
                                            <div className={styles.colWrap}>
                                                <label>City</label>
                                                <p className={styles.userDetails}>{user.city}</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={5}>
                                            <div className={styles.colWrap}>
                                                <label>State</label>
                                                <p className={styles.userDetails}>{user?.state}</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7}>
                                            <div className={styles.colWrap}>
                                                <label>Zip</label>
                                                <p className={styles.userDetails}>{user.zip}</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} className="d-md-none">
                                            <div className={styles.colWrap}>
                                                <label>Email
                                                    <span className="statusIndicatorWrap">
                                                        <img src={emailStatus?.image} className="img-fluid" alt="Verification Status Indicator Image"/>
                                                        <span className="statusTooltip">{emailStatus?.label}</span>
                                                    </span>
                                                </label>
                                                <div className={styles.tooltipEmail}>
                                                    <p
                                                        title={user.email}
                                                        className={classNames(
                                                            styles.userDetails,
                                                            styles.emailWrap
                                                        )}
                                                    >
                                                        <a href={`mailto: ${user.email}`}> {user.email}</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="lwRightCol">
                            <div className={classNames(styles.memPricing)}>
                                {/* member Id card View (sidebar) */}
                                {showWidget ? (
                                    <HfdWidget
                                        user = {user}
                                    />
                                ):(
                                    <MemberIdCard
                                        dashboard_json = {dashboard_json}
                                        user = {user}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
    
                {/* when user click on edit button primary details drawer open */}
                {openPrimaryDetailsDrawer && (
                    <PrimaryDetailsDrawer
                        userData={user}
                        open={openPrimaryDetailsDrawer}
                        setOpen={setOpenPrimaryDetailsDrawer}
                        setSuccessTextHeading={setSuccessTextHeading}
                        setSuccessTextMsg={setSuccessTextMsg}
                        setIsSucess={setIsSucess}
                    />
                )}
    
                {/* showing success modal when primary details updated */}
                <SuccessModal
                    open={isSucess}
                    onClose={() => setIsSucess(false)}
                    heading={successTextHeading}
                    msg={successTextMsg} 
                />    
            </div>
        )
    }
export default AccountDetails
