import React from 'react'
import styles from "../../../styles/module/join-now.module.scss";
import classNames from "classnames";

const DependentInput = ({ firstNameID, bindFirstName, lastNameID, bindLastName, dobID, dobValue, bindDob, errors }) => (
    <div
      className={classNames(
        styles.dependentContainer,
        "d-flex justify-content-between"
      )}
    >
      <div className={classNames(styles.dependentForm, "adInputWrap")}>
        <input 
          data-testid={firstNameID}
          placeholder="First name*"
          id={firstNameID}
          className={classNames("form-input",
          errors[firstNameID]
            ? "fcError"
            : "",
          )}
          {...bindFirstName}
        />
        {errors[firstNameID] && (
          <small className="support-error">{errors[firstNameID]}</small>
        )}
      </div>
  
      <div className={styles.dependentForm}>
        <input
          data-testid={lastNameID}
          placeholder="Last name*"
          id={lastNameID}
          className={classNames("form-input",
          errors[lastNameID]
            ? "fcError"
            : "",
           )}
          {...bindLastName}
        />
        {errors[lastNameID] && (
          <small className="support-error">{errors[lastNameID]}</small>
        )}
      </div>
      <div className={styles.dependentForm}>
        <input
          data-testid={dobID}
          type="date"
          id={dobID}
          value={dobValue}
          className={classNames("form-input",
            errors[dobID]
            ? "fcError"
            : "",
           )}
          {...bindDob}
        />
        {errors[dobID] && (
          <small className="support-error">{errors[dobID]}</small>
        )}
      </div>
    </div>
  );

  export default DependentInput;
