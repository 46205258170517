import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../src/assets/css/app.css";
// import './App.css';
import Layout from "./components/layout-components/Layout";
import EarlyRenew from "./pages/EarlyRenew";
import Checkout from "./pages/checkout";
import Login from "./pages/login";
import Logout from "./pages/logout";
import ResetPassword from "./pages/resetPassword";
import Dashboard from "./pages/dashboard";
import Custom404 from "./pages/404Page";
import { AuthProvider } from './api/services/auth';
import UpdateBilling from "./pages/updatebilling";
import UpdateBillingSuccess from "./pages/updatebillingSuccess";
import ChooseHealthy from "./pages/chooseHealthy";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

function App() {
	return (
	    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} useEnterprise={true}>
            <AuthProvider>
                <Router>
                    <Switch>
                        <Layout>
                            <Route exact path="/" component={Login}></Route>
                            <Route exact path="/earlyrenew" component={EarlyRenew}></Route>
                            <Route path="/checkout" component={Checkout}></Route>
                            <Route path="/login" component={Login}></Route>
                            <Route path="/logout" component={Logout}></Route>
                            <Route path="/reset-password" component={ResetPassword}></Route>
                            <Route path="/dashboard" component={Dashboard}></Route>
                            <Route path="/404" component={Custom404}></Route>
                            <Route exact path="/updatebilling" component={UpdateBilling}></Route>
                            <Route exact path="/updatebilling-success" component={UpdateBillingSuccess}></Route>
                            <Route exact path="/choose-healthy" component={ChooseHealthy}></Route>
                        </Layout>
                    </Switch>
                </Router>
            </AuthProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
