import React, {useState, useEffect} from "react";
import "../../assets/css/updateBilling.css";
import img01 from "../../assets/images/success-icn.svg";
const UpdateBillingSuccess = (props) => {
    // Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Update Billing Success | membersy";
	}, []);
    
    return (
		<section className="rsSuccessSection">
            <div className="container">
                <div className="successContentWrap">
                    <header className="successHeader">
                        <div className="icnWrap">
                            <img src={img01} className="img-fluid" alt="success Icn"/>
                        </div>
                        <h1>Billing Information Updated!</h1>
                        <p>Your membership billing information has been successfully updated.</p>
                    </header>
                    <div className="rsBtnWrap">
                        <a href="/" className="btn btnPrimary">Sign In to Member Portal</a>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default UpdateBillingSuccess;