import axios from "axios";
import Cookies from "js-cookie";
import { API_AUTHENTICATE } from "./config";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_Api_Url,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const onSuccess = (res) => Promise.resolve(res.data);
const onError = ({ response }) => {
  if (response === undefined) {
    let serviceNotReachable = { data: [{ require: "serviceNotReachable" }] };
    return Promise.reject(serviceNotReachable);
  } else {
    return Promise.reject(response);
  }
};

httpClient.interceptors.request.use((request) => {
  if (request.url !== API_AUTHENTICATE) {
    request.headers.Authorization = `Bearer ${Cookies.get("membersyToken")}`;
  }
  return request;
});

httpClient.interceptors.response.use((response) => response);

httpClient.interceptors.response.use(onSuccess, onError);

export default httpClient;
