import React, { useState, useEffect } from 'react'
import moment from "moment";
import styles from "../../styles/module/dashboard.module.scss";
import classNames from "classnames";
import { membershipColumns } from "../../data/dashboard";
import CancelModal from "../cancelModal";
import { AddDependentDrawer } from "../drawers/addDependentDrawer";
import { RenewSubscriptionDrawer } from '../drawers/renewSubscriptionDrawer'
import { PurchaseSubscriptionDrawer } from "../drawers/purchaseSubscriptionDrawer";
import ReactivateModal from "../reactivateModal";
import SuccessModal from "../successModal";
import infoIcn from "../../assets/images/info-icon.svg";
import CheckLocationStatus from "../../utility/planDeprecationCheck";

const SubscriptionDetails = ({ dashboard_json, user }) => {
    
    const [isModalOpen, setModalState] = useState(false);
    const [isModalreactivateOpen, setModalreactivateState] = useState(false);
    const [membershipDetails, setMembershipDetails] = useState();
    const [apiErrors, setApiErrors] = useState();
    const [successTextHeading, setSuccessTextHeading] = useState("");
    const [successTextMsg, setSuccessTextMsg] = useState("");
    const [isSucess, setIsSucess] = useState(false);
    const [openAddDependentDrawer, setOpenAddDependentDrawer] = useState(false);
    const [openRenewSubscriptionDrawer, setRenewSubscriptionDrawer] = useState(false);
    const [openPurchaseSubscriptionDrawer, setOpenPurchaseSubscriptionDrawer,] = useState(false);
    // state to handle price cap
    const [priceCap, setPriceCap] = useState( user?.Memberships[0]?.plan?.cap_limit !== null ?  user?.Memberships[0]?.plan?.cap_limit : 0)
    const planName = user?.plan_name;
    const [deprecationStatus, setDeprecationStatus] = useState(false);

    {/* Check the plan_location_deprecation function on page load */}
    useEffect(() => {
        const data = {
            locationStatus: user?.plan_location_status,
            deprecationDate: user?.plan_location_deprecation_date
        }
        if (CheckLocationStatus(data)) {
            setDeprecationStatus(true)
        } else {
            setDeprecationStatus(false)
        }
    }, [user?.plan_location_status])

    return (
        <div className={classNames(styles.membershipWrapper, "subsTableWrap")} data-testid = "subscription_details">
            <div className="tableInnerWrap">
                <div
                    className={classNames(
                        styles.paymentHeadingWrapper,
                        "d-flex justify-content-between"
                    )}
                >
                    <div>
                        <h1 className={styles.memSubHeading}>
                            Subscription Details
                            <div className={styles.tooltip}>
                                <img
                                    src={infoIcn}
                                    alt="info-icon"
                                    width={20}
                                />
                                <span className={styles.tooltiptext}>
                                    View/update subscription details and billing preferences
                                </span>
                            </div>
                        </h1>
                    </div>
                    <div className={styles.deptBtnContainer}>
                        <div className={classNames(styles.deptBtnWrapper, "actBtnRow")}>

                            {/* Drawers and open model button on user membership status */}
                            {/* Add Dependents Drawer when status is "Current" */}
                            {/* if price limit meet user can't able add dependents */}
                            {user?.Dependents.length < priceCap &&  user?.Memberships[0].status === "Current" && (
                                <button
                                    data-testid = "add_dependent_button"
                                    onClick={() => {
                                        document.body.style.overflow = "hidden";
                                        setOpenAddDependentDrawer(!openAddDependentDrawer);
                                        setMembershipDetails(user?.Memberships[0]);
                                    }}
                                    className={classNames(styles.adddepBtn, "tbActBtn")}
                                >
                                    Add Dependent(s)
                                </button>
                            )}

                            {(priceCap === 0 || priceCap === '0')  &&  user?.Memberships[0].status === "Current" && (
                                <button
                                    data-testid = "add_dependent_button"
                                    onClick={() => {
                                        document.body.style.overflow = "hidden";
                                        setOpenAddDependentDrawer(!openAddDependentDrawer);
                                        setMembershipDetails(user?.Memberships[0]);
                                    }}
                                    className={classNames(styles.adddepBtn, "tbActBtn")}
                                >
                                    Add Dependent(s)
                                </button>
                            )}

                            {/* this drawer is commented for now but may be used later on. */}
                            {user?.Memberships[0].status === "Current" && (
                                <>
                                    {/*<a*/}
                                    {/*    className={styles.adddepBtn}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        document.body.style.overflow = "hidden";*/}
                                    {/*        setOpenUpgradeSubscriptionDrawer(*/}
                                    {/*            !openUpgradeSubscriptionDrawer*/}
                                    {/*        );*/}
                                    {/*        setMembershipDetails(user?.Memberships[0]);*/}
                                    {/*        setUpgradeDetails(upgradeDetails);*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    Upgrade Subscription*/}
                                    {/*</a>*/}
                                    {/* <UpgradeModal
                                            open={isModalupgradeOpen}
                                            onClose={() => setModalupgradeState(false)}
                                        /> */}
                                </>
                            )}

                            {/* Renew Subscription Drawer when status is "Overdue" */}
                            {user?.Memberships[0].status === "Overdue" && deprecationStatus && (
                                <>
                                    <button
                                        data-testid="renew_drawer_btn"
                                        onClick={() => {
                                            document.body.style.overflow = "hidden";
                                            setRenewSubscriptionDrawer(!openRenewSubscriptionDrawer);
                                            setMembershipDetails(user?.Memberships[0]);
                                        }}
                                        className={classNames(styles.adddepBtn, styles.cancelStatusReturn, "tbActBtn")}
                                    >
                                        Renew Subscription
                                    </button>
                                </>
                            )}

                            {/* Reactive Subscription Modal when status is "Pending Cancelation" */}
                            {user?.Memberships[0].status === "Pending Cancelation" && deprecationStatus && (
                                <>
                                    <a
                                        href="#"
                                        data-testid = "reactive_subscription_btn"
                                        className={classNames(
                                            styles.adddepBtn,
                                            styles.cancelStatusReturn,
                                            "tbActBtn"
                                        )}
                                        onClick={() => setModalreactivateState(true)}
                                    >
                                        Enable Subscription
                                    </a>
                                    <ReactivateModal
                                        open={isModalreactivateOpen}
                                        subscription_id={user?.Memberships[0]?.subscription_id}
                                        setSuccessTextHeading={setSuccessTextHeading}
                                        setSuccessTextMsg={setSuccessTextMsg}
                                        setIsSucess={setIsSucess}
                                        setapiErrors={setApiErrors}
                                        onClose={() => setModalreactivateState(false)}
                                        planName={planName}
                                    />
                                </>
                            )}
                            
                            {/* Purchase new Subscription Drawer when status is "Canceled" || "Expired" */}
                            {(user?.Memberships[0].status === "Canceled" || user?.Memberships[0].status === "Expired" || user?.Memberships[0].status === "Refund" || user?.Memberships[0].status === "Unpaid") && deprecationStatus ? (
                                <button
                                    data-testid = "purchase_new_subscription_button"
                                    className={classNames(
                                        styles.adddepBtn,
                                        styles.cancelStatusReturn,
                                        styles.purcahseNewSubscription,
                                        "tbActBtn"
                                    )}
                                    onClick={() => {
                                        setOpenPurchaseSubscriptionDrawer(
                                            !openPurchaseSubscriptionDrawer
                                        );
                                        setMembershipDetails(user?.Memberships[0]);
                                    }}
                                >
                                    Purchase New Subscription
                                </button>
                            ) : ''
                        }
                            {/* Cancel Subscription Modal when status is "Current" */}
                            {user?.Memberships[0].status === "Current" && deprecationStatus && (
                                <>
                                    <a data-testid = "cancel_subscription_btn" className={classNames(styles.cancelBtn, "tbActBtn")} onClick={() => setModalState(true)}>
                                        Disable Auto-Renew
                                    </a>
                                    <CancelModal
                                        open={isModalOpen}
                                        subscription_id={user?.Memberships[0]?.subscription_id}
                                        setapiErrors={setApiErrors}
                                        setSuccessTextHeading={setSuccessTextHeading}
                                        setSuccessTextMsg={setSuccessTextMsg}
                                        setIsSucess={setIsSucess}
                                        onClose={() => setModalState(false)}
                                    />
                                </>
                            )}

                            {/* No action will be performed when status is disputed */}
                            {user?.Memberships[0].status === "Disputed" && (
                                <p>
                                    No action can be perform until dispute resolve
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className='statusRow'>
                    {/* No action will be performed when plan location status is deprecated */}
                    {!deprecationStatus && (
                        <p className='planLocationStatus'>
                            Dear Member, we are sorry to inform you that this plan is now inactive and will be deprecated soon. To obtain additional information about a new dental membership plan, please call <a href="tel:8775454188">(877)545-4188</a>.
                        </p>
                    )}
                </div>                
                <div className={classNames(styles.tableContainer, "sdTable dashbordTable")}>
                    <table className={classNames(styles.tableWrapper)}>
                        <thead>
                        <tr>
                            {membershipColumns.map(({ title }) => (
                                <th className={styles.tableHeading} key={title}>
                                    {title}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody className="">
                        {user?.Memberships?.map((membership, index, payment) => (
                            <>
                                {membership.status !== "Unpaid" && (
                                    <tr key={index}>
                                        <td className={styles.tablePara}>
                                            {membership.plan.name}
                                        </td>
                                        <td className={styles.tablePara}>
                                            {moment(membership.StartDate).format("MM-DD-YYYY")}
                                        </td>
                                        <td className={styles.tablePara}>
                                            {moment(membership.EndDate).format("MM-DD-YYYY")}
                                        </td>
                                        <td
                                            className={classNames(
                                                styles.tablePara,
                                                styles.statusBtnWrapper
                                            )}
                                        >
                                            <div
                                                className={classNames(
                                                    membership.status === "Current" ? styles.activeBtn :
                                                    membership.status === "Pending Cancelation" ? 
                                                    classNames(
                                                        styles.activeBtn,
                                                        "warning"
                                                    ) : 
                                                    classNames(
                                                        styles.activeBtn,
                                                        styles.inactiveBtn
                                                    ),
                                                    'statuSpan'
                                                )}
                                            >
                                                {membership.status}
                                            </div>
                                        </td>
                                        <td className={styles.tablePara}>
                                            {
                                                membership?.status === "Current" && user?.card !== false ? (
                                                    <>
                                                        <div className="tipatright">
                                                            Yes
                                                            <div className={styles.tooltip}>
                                                                <img
                                                                    src={infoIcn}
                                                                    alt="info-icon"
                                                                    width={20}
                                                                />
                                                                <span className={styles.tooltiptext}>
                                                                    The current subscription is scheduled to
                                                                    auto-renew on next invoice date
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="tipatright">
                                                            No
                                                            <div className={styles.tooltip}>
                                                                <img
                                                                    src={infoIcn}
                                                                    alt="info-icon"
                                                                    width={20}
                                                                />
                                                                <span className={styles.tooltiptext}>
                                                                    The current subscription is scheduled to
                                                                    cancel on next invoice date
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Drawers */}
            {/* Add Dependent Drawer open when click on the Add Dependent(s) button */}
            {openAddDependentDrawer && (
                <AddDependentDrawer
                    card={user.card}
                    open={openAddDependentDrawer}
                    setOpen={setOpenAddDependentDrawer}
                    membershipDetails={membershipDetails}
                    setSuccessTextHeading={setSuccessTextHeading}
                    setSuccessTextMsg={setSuccessTextMsg}
                    setIsSucess={setIsSucess}
                    user={user}
                />
            )}
            
            {/* Renew Subscription Draer open when click on the Renew button */}
            {openRenewSubscriptionDrawer && (
                <RenewSubscriptionDrawer
                    open={openRenewSubscriptionDrawer}
                    setOpen={setRenewSubscriptionDrawer}
                    fullDetails = {user}
                    membershipDetails={membershipDetails}
                    setSuccessTextHeading={setSuccessTextHeading}
                    setSuccessTextMsg={setSuccessTextMsg}
                    setIsSucess={setIsSucess}
                    user={user}
                    memberID={user.id}
                />
            )}

            {/*  Purchase Subscription Drawer when user clicks on the Purchase Subscription button */}
            {openPurchaseSubscriptionDrawer && (
                <PurchaseSubscriptionDrawer
                    open={openPurchaseSubscriptionDrawer}
                    setOpen={setOpenPurchaseSubscriptionDrawer}
                    membershipDetails={membershipDetails}
                    setSuccessTextHeading={setSuccessTextHeading}
                    setSuccessTextMsg={setSuccessTextMsg}
                    setIsSucess={setIsSucess}
                    user={user}
                    memberID={user.id}
                />
            )}

            {/* Upgrade Subscription Drawer is not used for now, but may be later */}
            {/* {openUpgradeSubscriptionDrawer && (
                <UpgradeSubscriptionDrawer
                    open={openUpgradeSubscriptionDrawer}
                    setOpen={setOpenUpgradeSubscriptionDrawer}
                    membershipDetails={membershipDetails}
                    setUpgradeDetails={upgradeDetails}
                    memberID={user?.id}
                    setSuccessTextHeading={setSuccessTextHeading}
                    setSuccessTextMsg={setSuccessTextMsg}
                    successTextMsg={successTextMsg}
                    setIsSucess={setIsSucess}
                    user={user}
                />
            )} */}

            {/* showing success modal when success is true */}
            {
                isSucess && (
                    <SuccessModal
                        open={isSucess}
                        onClose={() => setIsSucess(false)}
                        heading={successTextHeading}
                        msg={successTextMsg} 
                    />  
                )
            }              
        </div>
    )
}

export default SubscriptionDetails
