import React, { useState, useEffect } from "react";
import logoSvg from "../../../assets/images/MembersyLogo.svg";
import logoMembersyNew from "../../../assets/images/Membersy_Logo_New_Blue.svg";
import "./Header.css";
import Cookies from "js-cookie";
import {useAuth} from '../../../api/services/auth';

const Header = () => {
	const [menu, setMenu] = useState(false);
	const [paymentRebranding, SetPaymentRebranding] = useState(false);

	const checkPath = window.location.pathname;
	const {logout} = useAuth();

	useEffect(() => {
		if (checkPath === '/updatebilling' || checkPath === '/updatebilling-success') {
			SetPaymentRebranding(true);
		}
	}, [])
	
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	
    useEffect(() => {
        const token = Cookies.get("membersyToken");
        if(token){
            setIsAuthenticated(true)
        }
    }, []);
	
	
    return (
        <div className="phStickyWrap">
            <header
                id="pageHeader"
                className="sticky-wrap-headerFixer w-100 headerFixer position-fixed"
            >
                <div className="container">
                    <div className="hdWrap">
	                    <div className="logo">
	                        <a href="https://membersy.com/">
								<img
									src={(process.env.REACT_APP_PAYMNET_REBRANDING === 'true' && paymentRebranding) ? logoMembersyNew : logoSvg}
									className="imgFluid"
									alt="Membersy Pay"
								/>
	                        </a>
	                    </div>
	                    <div className={`menuWrap ${
	                            menu === true ? "show" : ""
	                        }`}
	                    >
							<div className="btnWrap">
								{
									isAuthenticated ? (
										<ul className="navbarNav dasList">
											<li>
												<button type="button"
													className="btn btnSecondary hdBtn logoutBtn"
													onClick={() =>logout()}
												>
													Logout
												</button>
											</li>
										</ul>
									) : (
										<a
											className="btn btnSecondary hdBtn"
											href="/login"
										>
											Login
										</a>
									)
								}
							</div>
	                    </div>
	                    
	                    <button
	                      className={`navOpener ${
							menu === true ? "show" : ""
						}`}
	                      type="button"
	                      onClick={() => setMenu((value) => setMenu(!value))}
	                    >
	                        <span className="menuIcnWrap">
	                            <span className="icnBar"></span>
	                        </span>
	                    </button>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
