import React, { useState, useEffect, useRef, useCallback } from "react";
import {useInput} from '../../hooks/useInput';
import axios from "axios";
import { SearchSelect } from "../../components/shared-components/searchSelect";
import { Image } from "cloudinary-react";
import GoogleMapReact from "google-map-react";
import img01 from "../../assets/images/icoParticipatingOffice.png";
import img02 from "../../assets/images/icoMembershipPlan.png";
import img03 from "../../assets/images/icoPlanBenefit.png";
import icnSuccessfull from "../../assets/images/icnSuccessfull.svg";
import mappinicn from "../../assets/images/pin.png";
import "../../assets/css/checkout.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "../../components/shared-components/paymentDetails/PaymentDetails";
import AddDependentsCheckoutModal from '../../components/addDependentsCheckout';
import TermsModal from "../../components/termsModal";
import {useAuth} from '../../api/services/auth';
import planByLocationService from "../../api/services/planByLocationService";
import createMemberService from "../../api/services/createMemberService";
import emailVerification from "../../api/services/emailVerification";
import { statesUS, hfdDropdownOptions, hfdDropdownStyles } from "../../data/join-now";
import classNames from "classnames";
import { HalfCircleSpinner } from "react-epic-spinners";
import styles from "../../styles/module/join-now.module.scss";
import ReactHtmlParser from "react-html-parser";
import ApplyCoupon from '../../components/shared-components/applyCoupon/applyCoupon';
import Modal from "react-bootstrap/Modal";
import loderIcon from "../../assets/images/loader.gif";
import getHFDbyLocation from '../../api/services/getHFDbyLocation';
import HFD_logo from "../../assets/images/HFD_logo.svg";
import hfdStyles from "../../styles/module/hfdModal.module.scss";
import ValidateEmail from "../../utility/emailRegExp";
import { hfdRequestBody, getHFDauthenticLink } from '../../api/services/getHFDauthenticLink';
import GooglePlacesAutocomplete from '../../components/googlePlacesAutocomplete';
import CheckLocationStatus from "../../utility/planDeprecationCheck";

export const currencyFormat = (value) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    }
).format(value);

const Checkout = (props) => {
	const {setShowApiLoader} = useAuth();

	const [firstName, bindFirstName, resetFirstName] = useInput("");
	const [lastName, bindLastName, resetLastName] = useInput("");
	const [dob, setDOB] = useState("");
	const [phone, bindPhone, resetPhone] = useInput("");
	const [email, bindEmail, resetEmail] = useInput("");

	const [address2, setAddress2] = useState("");
	const [address1, setAddress1] = useState("");
	const [city, setCity] = useState("");
	const [state, bindState] = useState("");
	const [zip, bindZip] = useState("");

	const [errors, setErrors] = useState({});
	const [stepTwoError, setStepTwoError] = useState({});
	const [stepThreeError, setStepThreeError] = useState({});
	const [stepFourError, setStepFourError] = useState({});

    const [mapCenter, setMapCenter] = useState({ lat: 29.72133, lng: -98.16825 });
    const [selectedOffice, setSelectedOffice] = useState({});
	const [activeOffices, setActiveOffices] = useState([]);
	const [infoWindow, setInfoWindow] = useState();
	const [scrolltoLocation, setScrolltoLocation] = useState();
	const [stepStage, setStepStage] = useState("selected");
	const [refs, setRefs] = useState();
	const [numOffices, setNumOffices] = useState();

	const [isModalOpen, setModalState] = useState(false);

	const [allOffice, setAllOffice] = useState([]);
	const [showLoader, setShowloader] = useState(false);

	const [stipePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY));
	const [cardSource, setCardSource] = useState(null);
	const [formSubmitted, setSubmitted] = useState(0);

	const [termsChecked, setTermsChecked] = useState(false);
	const [loader, setLoader] = useState(false);
	const [modalShow, setModalShow] = useState(false);

	const [planDropdown, setPlanDropDown] = useState();

	const [router] = useState(new URLSearchParams(props.location.search));
	const [officesDropdown, setOfficesDropdown] = useState([]);
	const [activePlan, setActivePlan] = useState();
	const [plan, setPlan] = useState();

	// state to manage the price cap
	const [priceCap, setPriceCap] = useState(null);

	const [enrollmentOffice, setEnrollmentOffice] = useState();
	const [addDependents, setAddDependents] = useState(0);
	const [annualBilling, setAnnualBilling] = useState(router?.query?.billingFrequency ? router?.query?.billingFrequency : "Annual");

    const [prevEmail, setPrevEmail] = useState()
	const [transactionFailure, setTransactionFailure] = useState();
	const emailErrorTxt = "The email address you've entered is already in use by another account.";
	const [emailVerified, setEmailVerified] = useState(true);
	let failureRef = useRef();
	const addDependentsRef = useRef();
	const [planFailure, setPlanFailure] = useState(false);

	// coupon
	const [coupon, setCoupon] = useState(null);
	const [discountAmount, setDiscountAmount] = useState(null);
	const [discountPercentage, setDiscountPercentage] = useState(null);

	const groupKeyArr = ["marketplace"];

	const [mapZoom, setMapZoom] = useState(2);
	const [maps, setMaps] = useState(null);
	const [map, setMap] = useState(null);

	// Form steps
	const [formStep, setFormStep] = useState(1);

	const [dateValue, setDateValue] = useState("");

	const scrollToTopRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
	const myRef = useRef(null);
	const executeScroll = () => scrollToTopRef(myRef);

	const [memberInfo, setMemberInfo] = useState("");
    const [planBenefit, setPlanBenefit] = useState("");

	// HFD Variables
	const [hfdFlag, setHFDflag] = useState(false);
	const [showHfd, setShowHfd] = useState(false);
	const [show, setShow] = useState(false);
	const [hfdErrors, setHfdErrors] = useState(false);
	const [totalCost, setTotalCost] = useState("");
	const [hfdTermsChecked, setHfdTermsChecked] = useState(false);
	const [disable, setDisable] = useState(true);
	const [modalShowLoader, setModalShowLoader] = useState(false);
	const [hfdModalErrors, setHfdModalErrors] = useState(false);
	const [modalTotalCost, setModalTotalCost] = useState("");
	const [modalTermsChecked, setModalTermsChecked] = useState(false);
	const [modalBtnDisable, setModalBtnDisable] = useState(true);
	
	// Google Places Autocomplete
	const placeholder = "Your address";
	const [googleInput, setGoogleInput] = useState(undefined);
	const [inputValue, setInputValue] = useState("");
	const [stateName, setStateName] = useState([]);
	const [stateMatch, setStateMatch] = useState(false);

	useEffect(() => {
		if (stateName !== '') {
			for (let i=0; i < statesUS.length; i++) {
				if (statesUS[i].value === stateName.value) {
					setStateMatch(true)
				}
			}
			bindState(stateName);
		}
	}, [stateName])


	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Checkout | membersy";
	}, []);

	// Next step function
	const stepForward = () => {
		setFormStep(cur => cur + 1)
	}

	// Prev step function
	const stepBack = () => {
		setFormStep(cur => cur - 1)
	}

    const colourStyles = {
        menu: (provided, state) => ({
            ...provided,
            borderRadius: '7.49px',
            cursor: 'pointer',
            boxShadow: '0 2.25px 7.49px 0 rgba(0, 59, 77, 0.102)',
            padding: '0 5px',
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected, placeholder}) => {
            return {
              ...styles,
              backgroundColor: isDisabled ? null : isSelected ? '#006f9026' : isFocused ? "#006f9026" : null,
              color: isDisabled ? '#f5f5f5' : isSelected ? '#003B4D' : isFocused ? "#003B4D" : "#003B4D",
              fontSize: "12px",
              fontWeight: "700",
              transition: "all 0.35s ease",
			  cursor: 'pointer',
			  borderRadius: '5px',
            };
        },
		placeholder: (styles) => ({ ...styles, color: '#003b4d80' }),
    }

    // Step two Validation Checking...
    const stepTwoValidation = () => {
		let stepTwoError = {};
		let isValidTwo = true;

		if (!firstName) {
			isValidTwo = false;
			stepTwoError["firstName"] = "Please enter your First name.";
		}
		if (!lastName) {
			isValidTwo = false;
			stepTwoError["lastName"] = "Please enter your Last name.";
		}
		if (!dob) {
			isValidTwo = false;
			stepTwoError["dob"] = "Please enter your Date of Birth.";
		}
	    if (!phone) {
	        isValidTwo = false;
	        stepTwoError["phone"] = "Please enter your Phone Number.";
	    }
	    if (phone) {
	        let res = phone.replace(/\D/g, "");
	        if (res.length < 10) {
	            isValidTwo = false;
	            stepTwoError["phone"] = "Please enter a Valid Phone number.";
	        }
	    }
		if (!email) {
			isValidTwo = false;
			stepTwoError["email"] = "Please enter your Email Address.";
		}
		if (email) {
			var pattern = new RegExp(ValidateEmail.RegExp);
			if (!pattern.test(email)) {
			    isValidTwo = false;
			    stepTwoError["email"] = "Please enter valid email address.";
			}
		}
		if(!emailVerified){
			isValidTwo = false
		}
		if (!activePlan) {
			isValidTwo = false;
			errors["activePlan"] = "Please select a plan.";
		}
		setStepTwoError(stepTwoError);
		return isValidTwo;
	};

	const stepTwoHanlder = async (a) => {
		a.preventDefault();
		if (stepTwoValidation()) {
			stepForward();
		}
	};

	const stepThreeValidation = () => {
		let stepThreeError = {};
		let isValidThree = true;

		if (!googleInput) {
			isValidThree = false;
			stepThreeError["googleInput"] = "Please enter your Address.";
		}
		if (!city) {
		    isValidThree = false;
		    stepThreeError["city"] = "Please enter your City.";
		}
		if (!state) {
		    isValidThree = false;
		    stepThreeError["state"] = "Please enter your State.";
		}
		if (!zip) {
		    isValidThree = false;
		    stepThreeError["zip"] = "Please enter your Zipcode.";
		}
		if (zip) {
		    let res = zip.replace(/\D/g, "");
		    if (res.length > 9) {
				isValidThree = false;
				stepThreeError["zip"] = "Please enter a Valid ZipCode";
		    }
		}
		setStepThreeError(stepThreeError);
		return isValidThree;
	};

	const stepThreeHanlder = async (e) => {
		e.preventDefault();
		if (stepThreeValidation()) {
			stepForward();
		}
	};

	const stepFourValidation = () => {
		let stepFourError = {};
		let isValidFour = true;

		// call the addDependents components validations
		if(addDependentsRef.current){
			isValidFour = addDependentsRef.current.addDependentsValidations()
		}

		if (!termsChecked) {
			isValidFour = false;
			stepFourError["termsChecked"] = "Please agree with the terms and conditions.";
		}

		if (addDependents === null) {
			isValidFour = false;
			stepFourError["addDependents"] = "Please select a dependent";
		}

		setStepFourError(stepFourError);
		return isValidFour;
	};

	// verify email
	useEffect(()=> {
	    if(email){
	        // email verification api calling
		    var pattern = new RegExp(ValidateEmail.RegExp);

	        if(pattern.test(email)) {
		        let requestBody = { email : email, group_key: groupKeyArr}
		        emailVerification.emailVerification(requestBody).then((response) => {
			        if (response.status === 200) {
			            setTransactionFailure(null);
			            setEmailVerified(true)
			        } else if (response.status === 409) {
			            setTransactionFailure(response.message);
			            setEmailVerified(false)
			            setPrevEmail(email)
			            resetEmail()
			        } else if (response.status === 408) {
			            setTransactionFailure(response.message+ `. Please give us a call at <a href="tel:8775454188">(877) 545-4188</a> to change your subscription over to this plan.`);
			            setEmailVerified(false)
			            setPrevEmail(email)
			            resetEmail()
		            }
		        });
		    }
	    }
	}, [email])

	// this function will be called from the addDependent (child component to set the addDependent counts)
	const addDepCountHandler = (event) => {
	    setAddDependents(event);
	}

	//Send the data on form submission
	const submitForm = () => {
	    if (stepFourValidation()) {
	        setLoader(true);

	        let memberDetails = [];

	        if(addDependentsRef.current){
	            memberDetails = addDependentsRef.current.getDependentsData()
	        }

	        let unformmatedPhone = phone
		        .replaceAll("(", "")
		        .replaceAll(")", "")
		        .replaceAll("-", "")
		        .replaceAll(" ", "");

	        let requestBody = {
		        first_name: firstName,
		        last_name: lastName,
		        provider_id: selectedOffice.id || singleOfficeInfo?.id,
		        email,
		        phone: unformmatedPhone,
				address1: address2 ? (address2 + ' ' + inputValue) : inputValue,
		        billing_country: cardSource.card.country,
				state: state[0].value,
				zip: zip,
		        city,
		        password: unformmatedPhone,
		        total_members: addDependents,
		        members: JSON.stringify(memberDetails),
		        dob,
		        pms_number: null,
		        is_marketplace: true,
		        payment_type: 0,
		        plan_id: activePlan.value,
		        planFrequency: annualBilling,
		        card: JSON.stringify(cardSource.card),
				payment_method: cardSource.id,
	        };

			if (coupon !== '' || coupon !== null) {
				requestBody.coupon= coupon;
			}

	        setShowApiLoader(true);
	        createMemberService.createMember(requestBody).then((response) => {
	            setShowloader(false);
				setMemberInfo(response.data)
		        if (response.status === 200) {
		            setLoader(false);
					stepForward();
		        } else if (response.status === 409) {
		            setTransactionFailure(response.message);
		            setLoader(false);
		        }
	        });
	    }
    };

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(stepFourValidation()){
			setSubmitted((prevState) => prevState + 1);
		} else {
			setLoader(false);
		}
	};

    // load the card object 
	useEffect(() => {
		if (cardSource) {
		    submitForm();
		}
	}, [cardSource]);

	// Scroll ref if create member submition transaction failed
	useEffect(() => {
	    if (failureRef.current !== undefined) {
		    failureRef?.current?.scrollIntoView({
		        behavior: "smooth",
		        block: "center",
		    });
	    }
	}, [transactionFailure, planFailure]);

	// Errors load earlier
	useEffect(() => {
		if (stepFourError) {
		    let errorNodes = document.querySelectorAll(".support-error, .checkError, .fcError");
		    let firstNode = errorNodes[0];
		}
	}, [stepFourError]);

	const isModifierKey = (event) => {
		const key = event.keyCode;
		return (
			event.shiftKey === true ||
			key === 35 ||
			key === 36 ||
			key === 8 ||
			key === 9 ||
			key === 13 ||
			key === 46 ||
			(key > 36 && key < 41) ||
			((event.ctrlKey === true || event.metaKey === true) &&
				(key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
		);
	};

	const isNumericInput = (event) => {
		const key = event.keyCode;
		return (key >= 48 && key <= 57) || (key >= 96 && key <= 105);
	};

	const enforceFormat = (event) => {
		if (!isNumericInput(event) && !isModifierKey(event)) {
		    event.preventDefault();
		}
	};

	const formatToPhone = (event) => {
		if (isModifierKey(event)) {
		    return;
		}
		const target = event.target;
		const input = target.value.replace(/\D/g, "").substring(0, 10);
		const areaCode = input.substring(0, 3);
		const middle = input.substring(3, 6);
		const last = input.substring(6, 10);

		if (input.length > 6) {
		    target.value = `(${areaCode}) ${middle}-${last}`;
		} else if (input.length > 3) {
		    target.value = `(${areaCode}) ${middle}`;
		} else if (input.length > 0) {
		    target.value = `(${areaCode}`;
		}
	};

	const handleStateChange = (event) => {
		const states = statesUS.filter((USstate) => USstate.value === event.value);
		bindState(states);
	};

	function handlePlanSelect(event) {
		planDropdown.map((plan) => {
		    if(plan.value === event.value){
				setActivePlan(plan);
				getPlanById(plan.value);
				return ;
		    }
		});
	}

	const sortAlphabetically = (data) => {
		data.sort((a, b) => {
		    if (a.name < b.name) {
				return -1;
		    }
		    if (a.name > b.name) {
				return 1;
		    }
		    return 0;
		});
		return data;
	};

	const formatDropdownList = (data) => {
		let MappedData = data.map((officeInfo) => {
		    return {value: officeInfo.id, label: officeInfo.name};
		});
		return MappedData;
	};

	const mapAsMembershipDropdownList = (data) => {
		let dropDownList = data.map((plan) => {
		    return {
				value: plan["plan_id"],
				label: plan["full_name"],
				frequencies: plan["frequencies"],
				disabled: false,
				locationStatus: plan["plan_location_status"],
				deprecationDate: plan["plan_location_deprecation_date"],		
		    };
		});
		return dropDownList;
	};

	const handleListItemClick = (officeInfo) => {
		if (
			officeInfo?.latitude === "" &&
		    officeInfo?.longitude === ""
		) {
		    setMapCenter(defaultMapCenter);
		    setMapZoom(3);
		} else {
		    setMapCenter({
				lat: parseFloat(officeInfo?.latitude),
				lng: parseFloat(officeInfo?.longitude),
		    });
		    setMapZoom(5);
		}
		setSelectedOffice(officeInfo);
		setInfoWindow(officeInfo);
		setScrolltoLocation(officeInfo.id);
		setPlanBenefit(officeInfo.planOverView);
	};

	var locationId = router.get('locationId');
	var planId = router.get('planId');

	const getPlans = async (officeInfo) => {

		setShowApiLoader(true);
		let plansList=[]

		let data = {location_id: officeInfo.id || locationId};

		planByLocationService.planByLocation(data).then((response) => {
			plansList = mapAsMembershipDropdownList(response.data);
			
			{/* Check & remove the depcrecated plan from the plan's dropdown */}
			const updatePlanList = plansList.filter(data => CheckLocationStatus(data) === true);
			setPlanDropDown(updatePlanList);

			{/* show error if plan's dropdown is empty*/}
			if (updatePlanList.length < 1) {
				setPlanFailure(true)
			} else {
				setPlanFailure(false)
			}

		    // if planId in query params
		    if(planId){
				setActivePlan("");
				getPlanById()
				updatePlanList?.map((e) => {
			    if(String(e.value) === planId){
					setActivePlan(e);
					getPlanById(e.value)
			    } else {
					setActivePlan(updatePlanList[0]);
					getPlanById(updatePlanList[0]?.value)
				}
			})
		    }else {
				setActivePlan(updatePlanList[0]);
				getPlanById(updatePlanList[0]?.value)
		    }
		    setShowApiLoader(false);
		}).catch((err)=>{
		    setActivePlan(plansList[0]);
		    getPlanById(plansList[0]?.value)
		});
	};

	useEffect(() => {
		if (router?.query?.name) {
		    let preselectOffice = officesDropdown.filter((name) => {
				return name.value === router?.query?.name;
		    });
		    setEnrollmentOffice(preselectOffice[0]);
		}
	}, [officesDropdown]);

	useEffect(() => {
		let planData_request = {location_id: enrollmentOffice?.value};
		if(locationId.split(',').length === 1) {
			getPlans(planData_request);
		}
	}, [enrollmentOffice]);

	// get plan by Id and frequency
	const getPlanById = (planId) => {
        let url = ""
		if (activePlan?.value !== undefined) {
			if(activePlan?.frequencies.length !== 0 || activePlan?.frequencies.length >= 1){
				url = `${process.env.REACT_APP_Api_Url}/plan/${planId}?frequency=${annualBilling}`
			}else {
				url = `${process.env.REACT_APP_Api_Url}/plan/${planId}?frequency=Annual`
			}
			axios.get(url).then((res)=>{
				if(res.data.data){
					setPlan(res?.data?.data[0])
					setPriceCap(res?.data?.data[0]?.cap_limit)
				} else {
					setPlan("")
					setPriceCap(null)
				}
			})
		}
    }

	// call getPlanById when frequency changed
	useEffect(()=>{
		getPlanById(activePlan?.value)
	},[annualBilling, activePlan])

	const priceCapHandle = () => {
		if(priceCap === null || priceCap === '0' || priceCap === 0){
		    return addDependents
		}
		else {
			if(addDependents > priceCap){
				return priceCap
			}else if (addDependents < priceCap){
				return addDependents
			}else {
				return addDependents
			}
		}
	}

	const [singleOfficeInfo, setSingleOfficeInfo] = useState({});

	// onload catch the parms from url and make request
	// office loactions request
	useEffect(() => {

		setShowloader(true);

		var locationIdArry= "";

		if (locationId === null){
			setModalShow(true);
		} else if (locationId === '') {
			window.location.replace('/404');
		} else {
			locationIdArry = locationId?.split(",");
		}

		var params = new URLSearchParams();
		var ids = locationIdArry;

		for(let i=0; i < ids?.length; i++){
			params.append("locationIds", ids[i]);
		};

		var request = {
			params: params
		};

		const headers = {
			'auth-key': '$2b$10$qmHPNla/.9HshZsBRvBPq.hR4aR.JdjANbPS40qF8uruYhCb7yCLW'
		};

		axios.get(process.env.REACT_APP_Api_Url_Alt + 'locations/list?' + params, {headers}).then((res) => {

			var data_arr = res.data && res.data.filter((e) => {
				return e?.name?.toLowerCase().substring(0, 3) !== "*na";
			});

			if (res.status === 200) {
				setShowloader(false);

				let dentistList = sortAlphabetically(data_arr);
				setAllOffice(dentistList);
				setActiveOffices(dentistList);
				setSelectedOffice({});

				if (res.data.length <= 1) {
					setFormStep(2);
					setSingleOfficeInfo(res.data[0]);
				}
		    } else {
				setActiveOffices([]);
				setSelectedOffice({});
		    }
		});

	}, []);

	const saveOfficeDetails = (officeInfo) => {
		let sessionDetails = sessionStorage.getItem("sessionDetails");
		if (sessionDetails) {
			sessionDetails = JSON.parse(sessionDetails);
		} else {
			sessionDetails = { officeInfo: {} };
		}
		sessionDetails.officeInfo = { ...officeInfo };
		sessionStorage.setItem("sessionDetails", JSON.stringify(sessionDetails));
	};

	useEffect(() => {
		setNumOffices(activeOffices?.length);
		let refs = activeOffices?.reduce((acc, value) => {
		    acc[value.id] = React.createRef();
		    return acc;
		}, {});
		setRefs(refs);
	}, [activeOffices]);

	useEffect(() => {
		if (refs) {
		  refs[scrolltoLocation]?.current?.scrollIntoView({
			behavior: "smooth",
			block: "center",
		  });
		}
	}, [scrolltoLocation]);

	var defaultMapCenter = { lat: 29.72133, lng: -98.16825 };

	// component which renders the list of offices or locations
	const ListItems = (props) => {

		const borderClassName = props.officeInfo.id === props.selectedOffice.id && "selectedDentist";

		return (
            <>
				<div
                    ref={props.forwardRef}
				    className={`indvDentist ${borderClassName}`}
				    onClick={props.handleListItemClick}
				>
					<div className="officeItem d-flex justify-content-between align-items-center">
						<div className="itemLeftWrap d-flex align-items-center">
							<div className="officeLogo">
								<img
									src={props.officeInfo.logo ? props.officeInfo.logo : '/images/placeholderImage.jpg'}
									className="imgFluid w-100 roundedSm"
									alt="img description"
								/>
							</div>
							<div className="planDecs">
								<h4 className="officeName text-capitalize">{props.officeInfo.name}</h4>
								<p className="officeAddress text-capitalize">
									{props.officeInfo.address1}
									{"," + props.officeInfo.address2 && props.officeInfo.address2}
									<br /> {props.officeInfo.city && props.officeInfo.city + ","}{" "}
									{props.officeInfo.state} {props.officeInfo.zip}<br />
								</p>
								<p className="officeNumber">(877) 545-4188</p>
							</div>
						</div>
						<div className="itemRightWrap">
		                    <button
								className="btnSlct btn btnPrimary"
								type="button"
								onClick={() =>{
									stepForward()
									props.changeStepOnSelect("completed");
									saveOfficeDetails(infoWindow);
									getPlans(props.officeInfo)
									executeScroll()
								}}
								>
								Select Office
		                    </button>
						</div>
					</div>
				</div>
            </>
		);
    };

    // detail window displays the detail on map
    const InfoWindow = ({ infoWindow, singleOfficeInfo }) => (
        <div className="pinInfo">
            <p className="infoWindowOfficeName text-capitalize ">{infoWindow.name ? infoWindow?.name : singleOfficeInfo?.name}</p>
            <p className="infoWinAddress text-capitalize ">
                {infoWindow.address1 ? infoWindow?.address1 : singleOfficeInfo?.address1}
                {"," + infoWindow.address2 && infoWindow.address2 ? infoWindow?.address2 : singleOfficeInfo?.address2}
                <br /> {infoWindow.city && infoWindow.city ? infoWindow?.city : singleOfficeInfo?.city + ","} {infoWindow.state ? infoWindow?.state : singleOfficeInfo?.state}{" "}
                {infoWindow.zip ? infoWindow?.zip : singleOfficeInfo?.zip}
            </p>
        </div>
    );

	// Marker (pin) displays on map
	const MapMarker = ({ handleListItemClick }) => (
		<div>
	        <Image onClick={handleListItemClick} src={mappinicn} className="mapPin" />
	    </div>
	);

	const handleApiLoaded = (map, maps) => {
		setMaps(maps);
		setMap(map);
		const bounds = new maps.LatLngBounds();
		activeOffices.map((office) => {
			let point = new maps.LatLng(office.latitude, office.longitude);
			bounds.extend(point);
		})
		map.fitBounds(bounds);
	};

	const hfdProviderRequestBody = () => {
		const body = {
		  first_name: firstName,
		  last_name: lastName,
		  dob: dob,
		  address1: address1,
		  address2: address2,
		  city: city,
		  StatoId: state[0]?.value,
		  zip: zip,
		  email: email,
		  phone: phone
		}
		return body;
	}

	// HFD Provider Link Call
	const getProviderLink = () => {
		setShowloader(true)
		setHfdErrors("")

		var body = hfdProviderRequestBody()
		body.TotalCost = totalCost.value;
		const requestBody = hfdRequestBody(body)
	
		getHFDauthenticLink.getLink(requestBody).then((res) => {
		  setShowloader(false)
		  setDisable(false)
	
		  if (res.status === 200) {
			setHfdTermsChecked(false)
			setTotalCost("")
			setDisable(true)
			let url = res.data
			window.open(url, '_blank', 'noopener, noreferrer');
		  } else {
			setHfdErrors(res.message)
		  }
		})
	}

	const checkHFDflag = (id) => {
		getHFDbyLocation.getHFDbyLocation(id).then((response) => {
			if (response?.data?.hfd_is_enabled === 1) {
				setHFDflag(true)
			}
		})
	}

	useEffect(() => {
		let id = selectedOffice.id || singleOfficeInfo?.id
		if (id !== undefined && process?.env?.REACT_APP_HFD_WIDGET_ENABLE === "true") {
			checkHFDflag(id)
		}

	}, [selectedOffice.id, singleOfficeInfo.id])

	// HFD Provider Link Call for Modal
	const getModalProviderLink = () => {
		setModalShowLoader(true)
		setHfdModalErrors("")

		var body = hfdProviderRequestBody()
		body.TotalCost = modalTotalCost.value;	
		const requestBody = hfdRequestBody(body)
	
		getHFDauthenticLink.getLink(requestBody).then((res) => {
		  setModalShowLoader(false)
		  setModalBtnDisable(false)
	
		  if (res.status === 200) {
			setModalTermsChecked(false)
			setModalTotalCost("")
			setModalBtnDisable(true)
			let url = res.data
			window.open(url, '_blank', 'noopener, noreferrer');
		  } else {
			setHfdModalErrors(res.message)
		  }
		})
	}

    return (
        <>
            <section className="checkout_section">
                <div className="container">
					{planFailure && (
						<div ref={failureRef} className={classNames(styles.failedWrapper, 'planFailureError')}>
							<p className={styles.failHeading}>
								Dear Member, we are sorry to inform you that you cannot proceed with the checkout, because the Plan you wish to subscribe to is no longer active. If you need assitance with purchasing an active subscription plan, please call <a href="tel:8775454188">(877)545-4188</a>
							</p>
						</div>
					)}
                    {transactionFailure && (
                        <div ref={failureRef} className={styles.failedWrapper}>
			                <p className={styles.failHeading}>
			                    We were unable to process your enrollment;{" "}
			                    <span className={styles.failReason}>
			                        {ReactHtmlParser(transactionFailure)}
			                    </span>
			                </p>
			                {transactionFailure.toLowerCase() ===
			                    emailErrorTxt.toLowerCase() && (
			                    <p className={styles.failHeading}>
				                    Click{" "}
				                    <a className={styles.failureLink} href="/login">
				                        here
				                    </a>{" "}
			                        to login to our member portal to view/edit the account details associated with this email address.
			                        {" (" + prevEmail + ")"}
			                        <br />
			                        If this was a mistake, please try again using your correct email address.
			                    </p>
			                )}
                        </div>
                    )}
                    <div className="ubiWrap bgGrayLight position-relative">
                        <header className="headerWrap clrDarkBlue">
                            {formStep === 5 ? (
                                <>
	                                <h1 className="h1">Checkout Complete</h1>
                                </>

                            ):(
                                <>
									<h1 className="h1">Checkout</h1>
									<p>Complete the checkout process below to create your account and activate your subscription.</p>

									{formStep === 1 ? (
										""
									):(
										<span
											className="backStepBtn"
											onClick={() => {
												stepBack()
											}}
										>
											<span className="backArrow"></span>
											Back
										</span>
									)}
                                </>
                            )}
                        </header>
                        <form className="checkoutForm" onSubmit={(event) => handleSubmit(event)}>
                            <div
                                className={`formInnerWrap ${formStep === 5 && ("bgVII")}`}
                            >
								<div
									className={classNames(
										"formStep_1",
										formStep === 1
										? "d-block"
										: "d-none"
									)}
								>
									{/* Step_1 OfficeSelectionStepWrap */}
									<div className="officeSelectionStepWrap">
										<div className="mpRow rowReverse">
											<div className="pyCol pyCol40 positionStatic">
												<div className="formLeftWrap">
													{
														activeOffices.length > 0 ? (
															<div className="maps">
																<GoogleMapReact
																	bootstrapURLKeys={{
																	key: process.env.REACT_APP_GOOGLE_MAP_URL_KEY,
																}}

																	center={mapCenter}
																	zoom={mapZoom}
																	onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
																	yesIWantToUseGoogleMapApiInternals={true}
																>
																	{activeOffices?.map((officeInfo) => {
																		return (
																			<MapMarker
																				zIndex={1}
																				lat={officeInfo?.latitude}
																				lng={officeInfo?.longitude}
																				key={officeInfo?.id}
																				id={officeInfo?.id}
																				selectedOffice={selectedOffice}
																				handleListItemClick={() =>
																					handleListItemClick(officeInfo)
																				}
																			/>
																		);
																	})}
																	{infoWindow && (
																		<InfoWindow
																			zIndex={2}
																			lat={infoWindow?.latitude}
																			lng={infoWindow?.longitude}
																			infoWindow={infoWindow}
																			saveInfos={saveOfficeDetails}
																		/>
																	)}
																</GoogleMapReact>
															</div>
														): ""
													}
												</div>
											</div>
											<div className="pyCol pyCol60">
												<div className="formRightWrap">
													<ul className="stepList">
														<li className="active">Office</li>
														<li>Details</li>
														<li>Billing</li>
														<li>Payment</li>
														<li>Confirmation</li>
													</ul>
													<h3 className="h3 mpHeadingMain clrDarkBlue">Select Office Location
														<div className="toolTip">
															<img
															src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgBnZI9DgFBFMf/8zaiEMm6wWi02lVItlQQlDpH4CSOwA2sUOhsIhIlnaiWE6yoROx41rcdhH8z8/Lm9z5H4FG2bWKXrIOQZUtCweezh2DvYjr0rs/EDcgXKwhEm28movJAqonxwLlDZ6CLbyJVPYECVkGCYiPG5YPbhzo0IYzWS2YfsW2aGLBfgE/inhN14ktZ64xmuZRIWYJQusZ9TPopnp6n8UlCgJUWeieFDZcnZvhNDiFudDSRTeRKDc2APASG+9eejNBYLxeQmTmDFkfXD4ZU7flHXGVVeNEHG6c1hFMVXA7mYQuuc2vhCK5aS0X4/RJgAAAAAElFTkSuQmCC"
															className="icnInfo"
															alt="info-icon"
															/>
															<span className="toolTipTxt">Complete the checkout process below to create your account and activate your subscription.</span>
														</div>
													</h3>
													<div className="officeListWrap">
														{activeOffices?.length ? (
															activeOffices.map((officeInfo) => (
																<ListItems
																	forwardRef={refs && refs[officeInfo.id]}
																	selectedOffice={selectedOffice}
																	officeInfo={officeInfo}
																	saveInfos={saveOfficeDetails}
																	key={officeInfo.id}
																	handleListItemClick={() => handleListItemClick(officeInfo)}
																	changeStepOnSelect={(e) => setStepStage(e)}
																/>
															))
															) : showLoader ? (
																<HalfCircleSpinner
																	color="#0b4da2"
																	size={18}
																	style={{
																		display: "inline-block",
																		marginRight: "5px",
																	}}
																/>
															) : (
															<p className="text-center">No Results Found</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
								<div
									className={classNames(
										"formStep_2",
										formStep === 2
										? "d-block"
										: "d-none"
									)}
								>
									{/* Step_2 DetailStepWrap */}
									<div className="detailStepWrap">
										<div className="mpRow">
											<div className="pyCol pyCol60">
												<div className="formRightWrap">
													<ul className="stepList">
														<li className="complete">Office</li>
														<li className="active">Details</li>
														<li>Billing</li>
														<li>Payment</li>
														<li>Confirmation</li>
													</ul>
													<h3 className="h3 mpHeadingMain clrDarkBlue">Account Information
														<div className="toolTip">
															<img
																src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgBnZI9DgFBFMf/8zaiEMm6wWi02lVItlQQlDpH4CSOwA2sUOhsIhIlnaiWE6yoROx41rcdhH8z8/Lm9z5H4FG2bWKXrIOQZUtCweezh2DvYjr0rs/EDcgXKwhEm28movJAqonxwLlDZ6CLbyJVPYECVkGCYiPG5YPbhzo0IYzWS2YfsW2aGLBfgE/inhN14ktZ64xmuZRIWYJQusZ9TPopnp6n8UlCgJUWeieFDZcnZvhNDiFudDSRTeRKDc2APASG+9eejNBYLxeQmTmDFkfXD4ZU7flHXGVVeNEHG6c1hFMVXA7mYQuuc2vhCK5aS0X4/RJgAAAAAElFTkSuQmCC"
																	className="icnInfo"
																	alt="info-icon"
																/>
															<span className="toolTipTxt">Check your inbox for important membership info and updates including payment receipts and timely annual renewal reminders.</span>
														</div>
													</h3>
													<div className="chFormGroup offiGroup">
														<div className="chFormRow">
															<label className="hLabel">Participating Office</label>
															<div className="inputWrapWrap">
																<strong className="selectedOfficeWidget text-capitalize">
																	{
																		selectedOffice.name ? selectedOffice?.name
																		:
																		singleOfficeInfo?.name
																	}
																</strong>
																<i className="far fa-check-circle icn"></i>
															</div>
														</div>
													</div>
													<div className="chFormGroup planGroup">
														<div className="chFormRow">
															<label className="hLabel">Membership Plan</label>
															<SearchSelect
																className="memPlanSelect text-capitalize"
																id="membership-plans"
																options={planDropdown}
																value={activePlan}
																onChange={(event) => {
																	handlePlanSelect(event)
																}}
																styles={colourStyles}
															/>
														</div>
													</div>
													<div className="chFormGroup primaryInfoGroup">
														<div className="chFormRow">
															<div className="d-flex justify-content-between">
																<div className="chfInnerGroup chfgSmall">
																	<label className="hLabel">First Name</label>
																	<div className="inputWrap">
																		<input
																			type="text"
																			id="first_name"
																			placeholder="Your first name"
																			className={classNames("formControl",
																			stepTwoError?.firstName
																				? "fcError"
																				: ""
																			)}
																			{...bindFirstName}
																		/>
																	</div>
																</div>
																<div className="chfInnerGroup chfgSmall">
																	<label className="hLabel">Last Name</label>
																	<div className="inputWrap">
																		<input
																			type="text"
																			id="last_name"
																			placeholder="Your last name"
																			className={classNames("formControl",
																			stepTwoError?.lastName
																				? "fcError"
																				: ""
																			)}
																			{...bindLastName}
																		/>
																	</div>
																</div>
															</div>
															<div className="d-flex justify-content-between align-items-center">
																<div
																	className={dateValue === "" ? "chfInnerGroup chfgSmall": "chfInnerGroup chfgSmall dateHaveValue"}
																>
																	<label className="hLabel">Date of Birth</label>
																	<div className="inputWrap">
																		<span className="fakeDateWrap position-relative">
																			<input
																				type="date"
																				id="dateOfBirth"
																				placeholder="Your birthday"
																				value={dateValue}
																				onChange={(e) => {
																					setDateValue(e.target.value);
																					setDOB(e.target.value)
																				}}
																				className={classNames("formControl inputDate",
																				stepTwoError?.dob
																					? "fcError"
																					: ""
																				)}
																			/>
																			<label htmlFor="dateOfBirth" className="fakeLabel">
																				Your birthday
																			</label>
																		</span>
																	</div>
																</div>
																<div className="chfInnerGroup chfgSmall">
																	<label className="hLabel">Phone</label>
																	<div className="inputWrap">
																		<input
																			type="tel"
																			id="phone"
																			placeholder="Your phone number"
																			className={classNames("formControl",
																			stepTwoError?.phone
																				? "fcError"
																				: ""
																			)}
																			maxLength={14}
																			onKeyUp={formatToPhone}
																			onKeyDown={enforceFormat}
																			{...bindPhone}
																		/>
																	</div>
																</div>
															</div>
															<div className="d-sm-flex justify-content-between">
																<div className="chfInnerGroup">
																	<label className="hLabel">Email</label>
																	<div className="inputWrap">
																		<input
																			type="email"
																			id="email"
																			placeholder="Your email address"
																			className={classNames("formControl",
																			stepTwoError?.email
																				? "fcError"
																				: ""
																			)}
																			{...bindEmail}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="pyCol pyCol40 d-flex">
												<div className="checSumWrap w-100">
													<h2 className="h2 textCenter clrWhite">Checkout Summary</h2>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img01}
																className="icn"
																alt="icon"
															/>
															Participating Office
														</div>
														<div className="csSummary txtCapitalize">
															{
																selectedOffice.name ? selectedOffice?.name
																:
																singleOfficeInfo?.name
															}
														</div>
													</div>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img02}
																className="icn"
																alt="icon"
															/>
															Membership Plan
														</div>
														<div className="csSummary txtCapitalize">{activePlan?.label}</div>
													</div>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img03}
																className="icn"
																alt="icon"
															/>
															Plan Benefits
														</div>
														<div className="csSummary">
															{
																planBenefit ? planBenefit
																:
																singleOfficeInfo?.planOverView
															}
														</div>
													</div>
													{annualBilling === "Annual" && (
														<>
															<div className="csBillingContext anuFeeBox dflBox aligItemCenter">
																<div className="h4 csHeadingAlt">Annual Fee(s)</div>
																<div className="h4 csHeadingAlt">Amount</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">{currencyFormat(plan?.primary)}</div>
															</div>
															{addDependents === 0 || addDependents === null? (
																''
																):(
																	<div className="csMemberContext dflBox aligItemCenter">
																		<div className="csSummary clrWhite">
																			{addDependents} dependent(s) @ $
																			{plan?.dependent}/yr
																		</div>
																		<div className="csSummary clrWhite">
																			{currencyFormat(
																				priceCapHandle() * plan?.dependent
																			)}
																		</div>
																	</div>
																)
															}
															{addDependents === 0 ? (
																<>
																	<div className="csTotalContext dflBox">
																		<div className="csSummary clrWhite">Total Cost:</div>
																		<div className="totalCost clrWhite">{currencyFormat(plan?.primary)}</div>
																	</div>
																</>

																) : (
																<>
																	<div className="csTotalContext dflBox">
																		<div className="csSummary clrWhite">Total Cost:</div>
																		<div className="totalCost clrWhite">
																			{currencyFormat(
																				plan?.primary +
																				priceCapHandle() *
																				plan?.dependent
																			)}
																		</div>
																	</div>
																</>
																)
															}
														</>
													)}
													{annualBilling === "Monthly" && (
														<>
															<div className="csBillingContext anuFeeBox dflBox aligItemCenter">
																<div className="h4 csHeadingAlt">Annual Fee(s)</div>
																<div className="h4 csHeadingAlt">Amount</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">
																	{currencyFormat(
																		plan?.yearly_one_time_primary
																	)}
																</div>
															</div>
															{addDependents === 0 ? (
																''
																):(
																	<div className="csMemberContext dflBox aligItemCenter">
																		<div className="csSummary clrWhite">
																			{addDependents} dependent(s) @ $
																			{plan?.yearly_one_time_dependent}/yr
																		</div>
																		<div className="csSummary clrWhite">
																			{currencyFormat(
																				priceCapHandle() *
																				plan?.yearly_one_time_dependent
																			)}
																		</div>
																	</div>
																)
															}
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">
																	Monthly Recurring
																</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">
																	{currencyFormat(
																		plan?.primary
																	)}
																</div>
															</div>
															{addDependents === 0 && plan ? (
																""
																) : (
																<div className="csMemberContext dflBox aligItemCenter">
																	<div className="csSummary clrWhite">
																		{addDependents} dependent(s) @ $
																		{plan?.dependent}/mo
																	</div>
																	<div className="csSummary clrWhite">
																		{currencyFormat(
																			priceCapHandle() *
																			plan?.dependent
																		)}
																	</div>
																</div>
																)
															}
															<div className="csTotalContext dflBox">
																<div className="csSummary clrWhite">Total Cost:</div>
																<div className="totalCost clrWhite">
																	{currencyFormat(
																		plan?.yearly_one_time_primary +
																		priceCapHandle() *
																		plan?.yearly_one_time_dependent +
																		(plan?.primary * 12 +
																			priceCapHandle() *
																			plan?.dependent *12
																		)
																	)}
																</div>
															</div>
														</>
													)}
													<div className="joinNowContext">
														<button
															type="button"
															className="btn btnWhite"
															onClick={(a) => {
																stepTwoHanlder(a);
																executeScroll()
															}}
														>
															Continue
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className={classNames(
										"formStep_3",
										formStep === 3
										? "d-block"
										: "d-none"
									)}
								>
									{/* Step_3 BillingStepWrap */}
									<div className="billingStepWrap">
										<div className="mpRow">
											<div className="pyCol pyCol60">
												<div className="formRightWrap">
													<ul className="stepList">
														<li className="complete">Office</li>
														<li className="complete">Details</li>
														<li className="active">Billing</li>
														<li>Payment</li>
														<li>Confirmation</li>
													</ul>
													<h3 className="h3 mpHeadingMain clrDarkBlue">Billing Information
														<div className="toolTip">
															<img
																src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgBnZI9DgFBFMf/8zaiEMm6wWi02lVItlQQlDpH4CSOwA2sUOhsIhIlnaiWE6yoROx41rcdhH8z8/Lm9z5H4FG2bWKXrIOQZUtCweezh2DvYjr0rs/EDcgXKwhEm28movJAqonxwLlDZ6CLbyJVPYECVkGCYiPG5YPbhzo0IYzWS2YfsW2aGLBfgE/inhN14ktZ64xmuZRIWYJQusZ9TPopnp6n8UlCgJUWeieFDZcnZvhNDiFudDSRTeRKDc2APASG+9eejNBYLxeQmTmDFkfXD4ZU7flHXGVVeNEHG6c1hFMVXA7mYQuuc2vhCK5aS0X4/RJgAAAAAElFTkSuQmCC"
																	className="icnInfo"
																	alt="info-icon"
																/>
															<span className="toolTipTxt">We'll mail you a member welcome kit and ID cards (upon request) and a renewal notification letter each year prior to annual renewal date.</span>
														</div>
													</h3>
													<div className="chFormGroup primaryInfoGroup">
														<div className="chFormRow">
															<div className="d-sm-flex justify-content-between">
																<div className="chfInnerGroup">
																	<label className="hLabel">Address Line 1</label>
																	<div className="inputWrap">
																		<GooglePlacesAutocomplete
																			bindZip={bindZip}
																			inputValue={inputValue}
																			setInputValue={setInputValue}
																			setGoogleInput={setGoogleInput}
																			placeholder={placeholder}
																			setAddress1={setAddress1}
																			setCity={setCity}
																			setStateName={setStateName}
																			bindState={bindState}
																			stepThreeError={stepThreeError}
																			formStep={formStep}
																			setAddress2={setAddress2}
																		/>
																	</div>
																</div>
															</div>
															<div className="d-flex justify-content-between">
																<div className="chfInnerGroup chfgSmall">
																	<label className="hLabel">Address Line 2</label>
																	<div className="inputWrap">
																		<input
																			id="address2"
																			type="text"
																			placeholder="Your address"
																			className="formControl"
																			value={address2}
																			onChange={(event) =>
																				setAddress2(event.target.value)
																			}
																		/>
																	</div>
																</div>
																<div className="chfInnerGroup chfgSmall">
																	<label className="hLabel">City</label>
																	<div className="inputWrap">
																		<input
																			id="city"
																			type="text"
																			placeholder="Your city"
																			className={classNames("formControl",
																			stepThreeError?.city
																			? "fcError"
																			: ""
																			)}
																			value={city}
																			onChange={(event) => setCity(event.target.value)}
																		/>
																	</div>
																</div>
															</div>
															<div className="d-flex justify-content-between">
																<div className="chfInnerGroup chfgSmall ">
																	<div className="chFormRow">
																		<label className="hLabel">State</label>
																		<SearchSelect
																			className={classNames("memPlanSelect",
																			stepThreeError?.state
																				? "fcError"
																				: ""
																			)}
																			id="state_field"
																			options={statesUS}
																			value={state}
																			onChange={(event) => {
																			handleStateChange(event)}}
																			styles={colourStyles}
																		/>
																	</div>
																</div>
																<div className="chfInnerGroup chfgSmall">
																	<label className="hLabel">Zipcode</label>
																	<div className="inputWrap">
																		<input
																			id="zip"
																			type="text"
																			pattern="^[0-9-]+$"
																			className={classNames("formControl",
																			stepThreeError?.zip
																			? "fcError"
																			: ""
																			)}
																			placeholder="Your zipcode"
																			value={zip}
																			onChange={(event) => bindZip(event.target.value)}
																		/>
																	</div>
																</div>
															</div>
														</div>
														<div className="chFormRow radioCheckRow">
															<div className="chfInnerGroup">
																<label className="hLabel">Would you like to enroll in paperless billing and receive a digital membership card?</label>
																<div className="raidoOptionWrap">
																	<span className="fakeRadioBtnWrap">
																		<input className="form-check-input fakeInput" type="radio" name="Billing Option" id="option01" value="Yes" />
																		<label className="form-check-label fakeLabel" htmlFor="option01">Yes</label>
																	</span>
																	<span className="fakeRadioBtnWrap">
																		<input className="form-check-input fakeInput" type="radio" name="Billing Option" id="option02" defaultChecked value="No" />
																		<label className="form-check-label fakeLabel" htmlFor="option02">No</label>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="pyCol pyCol40">
												<div className="checSumWrap">
													<h2 className="h2 textCenter clrWhite">Checkout Summary</h2>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img01}
																className="icn"
																alt="icon"
															/>
															Participating Office
														</div>
														<div className="csSummary txtCapitalize">
															{
																selectedOffice.name ? selectedOffice?.name
																:
																singleOfficeInfo?.name
															}
														</div>
													</div>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img02}
																className="icn"
																alt="icon"
															/>
															Membership Plan
														</div>
														<div className="csSummary txtCapitalize">{activePlan?.label}</div>
													</div>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img03}
																className="icn"
																alt="icon"
															/>
															Plan Benefits
														</div>
														<div className="csSummary">
															{
																planBenefit ? planBenefit
																:
																singleOfficeInfo?.planOverView
															}
														</div>
													</div>
													{annualBilling === "Annual" && (
														<>
															<div className="csBillingContext anuFeeBox dflBox aligItemCenter">
																<div className="h4 csHeadingAlt">Annual Fee(s)</div>
																<div className="h4 csHeadingAlt">Amount</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">{currencyFormat(plan?.primary)}</div>
															</div>
															{addDependents === 0 || addDependents === null? (
																''
																):(
																	<div className="csMemberContext dflBox aligItemCenter">
																		<div className="csSummary clrWhite">
																			{addDependents} dependent(s) @ $
																			{plan?.dependent}/yr
																		</div>
																		<div className="csSummary clrWhite">
																			{currencyFormat(
																				priceCapHandle() * plan?.dependent
																			)}
																		</div>
																	</div>
																)
															}
															{addDependents === 0 ? (
																<>
																	<div className="csTotalContext dflBox">
																		<div className="csSummary clrWhite">Total Cost:</div>
																		<div className="totalCost clrWhite">{currencyFormat(plan?.primary)}</div>
																	</div>
																</>

																) : (
																<>
																	<div className="csTotalContext dflBox">
																		<div className="csSummary clrWhite">Total Cost:</div>
																		<div className="totalCost clrWhite">
																			{currencyFormat(
																				plan?.primary +
																				priceCapHandle() *
																				plan?.dependent
																			)}
																		</div>
																	</div>
																</>
																)
															}
														</>
													)}
													{annualBilling === "Monthly" && (
														<>
															<div className="csBillingContext anuFeeBox dflBox aligItemCenter">
																<div className="h4 csHeadingAlt">Annual Fee(s)</div>
																<div className="h4 csHeadingAlt">Amount</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">
																	{currencyFormat(
																		plan?.yearly_one_time_primary
																	)}
																</div>
															</div>
															{addDependents === 0 ? (
																''
																):(
																	<div className="csMemberContext dflBox aligItemCenter">
																		<div className="csSummary clrWhite">
																			{addDependents} dependent(s) @ $
																			{plan?.yearly_one_time_dependent}/yr
																		</div>
																		<div className="csSummary clrWhite">
																			{currencyFormat(
																				priceCapHandle() *
																				plan?.yearly_one_time_dependent
																			)}
																		</div>
																	</div>
																)
															}
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">
																	Monthly Recurring
																</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">
																	{currencyFormat(
																		plan?.primary
																	)}
																</div>
															</div>
															{addDependents === 0 && plan ? (
																""
																) : (
																<div className="csMemberContext dflBox aligItemCenter">
																	<div className="csSummary clrWhite">
																		{addDependents} dependent(s) @ $
																		{plan?.dependent}/mo
																	</div>
																	<div className="csSummary clrWhite">
																		{currencyFormat(
																			priceCapHandle() *
																			plan?.dependent
																		)}
																	</div>
																</div>
																)
															}
															<div className="csTotalContext dflBox">
																<div className="csSummary clrWhite">Total Cost:</div>
																<div className="totalCost clrWhite">
																	{currencyFormat(
																		plan?.yearly_one_time_primary +
																		priceCapHandle() *
																		plan?.yearly_one_time_dependent +
																		(plan?.primary * 12 +
																			priceCapHandle() *
																			plan?.dependent *12
																		)
																	)}
																</div>
															</div>
														</>
													)}
													<div className="joinNowContext">
														<button
															type="button"
															className="btn btnWhite"
															onClick={(e) => {
																stepThreeHanlder(e);
																executeScroll()
															}}
														>
															Continue
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								
								<div
									className={classNames(
										"formStep_4",
										formStep === 4
										? "d-block"
										: "d-none"
									)}
								>
									{/* Step_4 PaymentStepWrap */}
									<div className="billingStepWrap">
										<div className="mpRow">
											<div className="pyCol pyCol60">
												<div className="formRightWrap">
													<ul className="stepList">
														<li className="complete">Office</li>
														<li className="complete">Details</li>
														<li className="complete">Billing</li>
														<li className="active">Payment</li>
														<li>Confirmation</li>
													</ul>
													<h3 className="h3 mpHeadingMain clrDarkBlue">Payment Details
														<div className="toolTip">
															<img
																src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgBnZI9DgFBFMf/8zaiEMm6wWi02lVItlQQlDpH4CSOwA2sUOhsIhIlnaiWE6yoROx41rcdhH8z8/Lm9z5H4FG2bWKXrIOQZUtCweezh2DvYjr0rs/EDcgXKwhEm28movJAqonxwLlDZ6CLbyJVPYECVkGCYiPG5YPbhzo0IYzWS2YfsW2aGLBfgE/inhN14ktZ64xmuZRIWYJQusZ9TPopnp6n8UlCgJUWeieFDZcnZvhNDiFudDSRTeRKDc2APASG+9eejNBYLxeQmTmDFkfXD4ZU7flHXGVVeNEHG6c1hFMVXA7mYQuuc2vhCK5aS0X4/RJgAAAAAElFTkSuQmCC"
																	className="icnInfo"
																	alt="info-icon"
																/>
															<span className="toolTipTxt">Adding dependents could increase subscription fee. (see Checkout Summary for details)</span>
														</div>
													</h3>
													<div className="chFormGroup primaryInfoGroup">
														<div className="chFormRow">
															<div className="chFormGroup planGroup">
																<div className="chFormRow">
																	<label className="hLabel">Would you like to add any dependents?</label>
																	<AddDependentsCheckoutModal
																		ref = {addDependentsRef}
																		handleDepCount = {addDepCountHandler}
																	/>
																</div>
															</div>
															<div className="mpCardContext d-flex justify-content-between mb-3">
																<div className="stripeModuleWrap w-100">
																	<Elements stripe={stipePromise}>
																		<PaymentDetails
																			setCardSource={setCardSource}
																			formSubmitted={formSubmitted}
																			setLoader={setLoader}
																		/>
																	</Elements>
																</div>
															</div>
															<div className="mpCardContext d-flex justify-content-between flex-shrink-0">
																<div className="applyCouponWrap w-100">
																	<ApplyCoupon
																		coupon = {setCoupon}
																		discountAmount = {setDiscountAmount}
																		percentageAmount = {setDiscountPercentage}
																	/>
																</div>
															</div>
															<div className="mpCheckingContext">
																<label className="inputContainer w-100 d-inline-block">
																	<input type="checkbox"
																		id="termsChecked"
																		checked={termsChecked}
																		onChange={() => setTermsChecked(!termsChecked)}
																	/>
																	<span
																	className={classNames(
																		errors.termsChecked
																		? "checkError checkmark"
																		: "checkmark"
																	)}
																	></span>
																</label>
																<label htmlFor="termsChecked" className="mpCheckTerms">
																	The account’s primary member understands and agrees to the {" "}
																	<button
																		type="button"
																		onClick={() => setModalState(true)}
																		className="mtocLink"
																	>
																		Member Terms and Conditions.
																	</button>
																</label>
																<small className="support-error">
																	{stepFourError.termsChecked}
																</small>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="pyCol pyCol40">
												<div className="checSumWrap">
													<h2 className="h2 textCenter clrWhite">Checkout Summary</h2>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img01}
																className="icn"
																alt="icon"
															/>
															Participating Office
														</div>
														<div className="csSummary txtCapitalize">
															{
																selectedOffice.name ? selectedOffice?.name
																:
																singleOfficeInfo?.name
															}
														</div>
													</div>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img02}
																className="icn"
																alt="icon"
															/>
															Membership Plan
														</div>
														<div className="csSummary txtCapitalize">{activePlan?.label}</div>
													</div>
													<div className="csBillingContext">
														<div className="h3 csHeading clrWhite">
															<img
																src={img03}
																className="icn"
																alt="icon"
															/>
															Plan Benefits
														</div>
														<div className="csSummary">
															{
																planBenefit ? planBenefit
																:
																singleOfficeInfo?.planOverView
															}
														</div>
													</div>
													{annualBilling === "Annual" && (
														<>
															<div className="csBillingContext anuFeeBox dflBox aligItemCenter">
																<div className="h4 csHeadingAlt">Annual Fee(s)</div>
																<div className="h4 csHeadingAlt">Amount</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">{currencyFormat(plan?.primary)}</div>
															</div>
															{addDependents === 0 || addDependents === null? (
																''
																):(
																	<div className="csMemberContext dflBox aligItemCenter">
																		<div className="csSummary clrWhite">
																			{addDependents} dependent(s) @ $
																			{plan?.dependent}/yr
																		</div>
																		<div className="csSummary clrWhite">
																			{currencyFormat(
																				priceCapHandle() * plan?.dependent
																			)}
																		</div>
																	</div>
																)
															}
															{addDependents === 0 ? (
																<>
																	{discountAmount || discountPercentage ?
																		<div className="csMemberContext dflBox aligItemCenter">
																			<div className="csSummary clrWhite">Discount:</div>
																			<div className="csSummary clrWhite">
																				{
																					discountAmount && currencyFormat(discountAmount)
																				}
																				{
																					discountPercentage && discountPercentage + "%"
																				}
																			</div>
																		</div>
																		:""
																	}
																	<div className="csTotalContext dflBox">
																		<div className="csSummary clrWhite">Total Cost:</div>
																		<div className="totalCost clrWhite">
																			{
																				discountAmount ? currencyFormat(plan?.primary - discountAmount) : discountPercentage ? currencyFormat(plan?.primary - (plan?.primary/100) * discountPercentage) : currencyFormat(plan?.primary)
																			}
																		</div>
																	</div>
																</>

																) : (
																<>
																	{discountAmount || discountPercentage ?
																		<div className="csMemberContext dflBox aligItemCenter">
																			<div className="csSummary clrWhite">Discount:</div>
																			<div className="csSummary clrWhite">
																				{
																					discountAmount && currencyFormat(discountAmount)
																				}
																				{
																					discountPercentage && discountPercentage + "%"
																				}
																			</div>
																		</div>
																		:""
																	}
																	<div className="csTotalContext dflBox">
																		<div className="csSummary clrWhite">Total Cost:</div>
																		<div className="totalCost clrWhite">
																			{
																				discountAmount ?
																				currencyFormat(
																					plan?.primary +
																					priceCapHandle() *
																					plan?.dependent - discountAmount
																				) : discountPercentage ?
																				currencyFormat(
																					plan?.primary +
																					priceCapHandle() *
																					plan?.dependent -
																					(( plan?.primary +
																					priceCapHandle() *
																					plan?.dependent )/100 *  discountPercentage
																					)
																				) :
																				currencyFormat(
																					plan?.primary +
																					priceCapHandle() *
																					plan?.dependent
																				)
																			}
																		</div>
																	</div>
																</>
																)
															}
														</>
													)}
													{annualBilling === "Monthly" && (
														<>
															<div className="csBillingContext anuFeeBox dflBox aligItemCenter">
																<div className="h4 csHeadingAlt">Annual Fee(s)</div>
																<div className="h4 csHeadingAlt">Amount</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">
																	{currencyFormat(
																		plan?.yearly_one_time_primary
																	)}
																</div>
															</div>
															{addDependents === 0 ? (
																''
																):(
																	<div className="csMemberContext dflBox aligItemCenter">
																		<div className="csSummary clrWhite">
																			{addDependents} dependent(s) @ $
																			{plan?.yearly_one_time_dependent}/yr
																		</div>
																		<div className="csSummary clrWhite">
																			{currencyFormat(
																				priceCapHandle() *
																				plan?.yearly_one_time_dependent
																			)}
																		</div>
																	</div>
																)
															}
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">
																	Monthly Recurring
																</div>
															</div>
															<div className="csMemberContext dflBox aligItemCenter">
																<div className="csSummary clrWhite">Primary Member:</div>
																<div className="csSummary clrWhite">
																	{currencyFormat(
																		plan?.primary
																	)}
																</div>
															</div>
															{addDependents === 0 && plan ? (
																""
																) : (
																<div className="csMemberContext dflBox aligItemCenter">
																	<div className="csSummary clrWhite">
																		{addDependents} dependent(s) @ $
																		{plan?.dependent}/mo
																	</div>
																	<div className="csSummary clrWhite">
																		{currencyFormat(
																			priceCapHandle() *
																			plan?.dependent
																		)}
																	</div>
																</div>
															)}
															{discountAmount || discountPercentage ?
																<div className="csMemberContext dflBox aligItemCenter">
																	<div className="csSummary clrWhite">Discount:</div>
																	<div className="csSummary clrWhite">
																		{
																			discountAmount && currencyFormat(discountAmount)
																		}
																		{
																			discountPercentage && discountPercentage + "%"
																		}
																	</div>
																</div>
																:""
															}
															<div className="csTotalContext dflBox">
																<div className="csSummary clrWhite">Total Cost:</div>
																<div className="totalCost clrWhite">
																{
																	discountAmount ?
																		currencyFormat(
																			plan?.yearly_one_time_primary +
																			priceCapHandle() *
																			plan?.yearly_one_time_dependent +
																			(plan?.primary +
																			priceCapHandle() *
																			plan?.dependent) -
																			discountAmount
																		): discountPercentage ?
																		currencyFormat(
																			plan?.yearly_one_time_primary +
																			priceCapHandle() *
																			plan?.yearly_one_time_dependent +
																			(plan?.primary +
																			priceCapHandle() *
																			plan?.dependent) -
																			(plan?.yearly_one_time_primary +
																				priceCapHandle() *
																				plan?.yearly_one_time_dependent +
																				(plan?.primary +
																				priceCapHandle() *
																				plan?.dependent))/100 * discountPercentage
																		) :
																		currencyFormat(
																			plan?.yearly_one_time_primary +
																			priceCapHandle() *
																			plan?.yearly_one_time_dependent +
																			(plan?.primary +
																			priceCapHandle() *
																			plan?.dependent)
																		)
																	}
																</div>
															</div>
														</>
													)}
													<div className="joinNowContext">
														<button
															type="submit"
															className="btn btnWhite"
															disabled={showLoader}
															onClick={(f) => {
																executeScroll()
															}}
														>
															{showLoader && (
																<HalfCircleSpinner
																	size={16}
																	style={{
																		display: "inline-block",
																		marginRight: "5px",
																	}}
																/>
															)}
															Start Membership <i className="icomoon-icnMembershipPlan icn"><span className="sr-only">icon</span></i>
														</button>
													</div>
												</div>
											</div>
										</div>
										{loader === true ? (
											<div
												className="loderWrap true"
											>
												<img
													src={loderIcon}
													className="icn"
													alt="icon"
												/>
											</div>

										):(
											""
										)}
									</div>
									<TermsModal
										planName = { selectedOffice.name ? selectedOffice?.name : singleOfficeInfo?.name }
										open={isModalOpen}
										onClose={() => setModalState(false)}
									/>
								</div>
								<div
									className={classNames(
										"formStep_5",
										formStep === 5
										? "d-block"
										: "d-none"
									)}
								>
									{/* Step_5 ConfirmationStepWrap */}
									<div className="confirmationStepWrap">
										<div className="row">
											<div className="col-12 col-lg-6">
												<div className="paySuccMessage text-center bgStyles">
													<div className="icnWrap">
														<img
															src={icnSuccessfull}
															className="imgFluid"
															alt="forget-pasword-successfull"
														/>
													</div>
													<h3 className="h3 modalHeading"> Payment Successful!</h3>
													<div className="memberContextBox">
														<div className="mySubHead">Your ID #: <span className="mycDesc">{memberInfo?.person_id}</span></div>
														<div className="mySubHead">Username: <span className="mycDesc">{memberInfo?.email}</span></div>
														<div className="mySubHead">Password: <span className="mycDesc">{phone}</span></div>
													</div>
													<div className="btmSucNote">We have sent a copy of your receipt to your email.</div>
													<a href="/" 
														className="btn btnPrimary btnOpacityHover"
													>Sign In</a>
												</div>
											</div>
											<div className="col-12 col-lg-6">
												{
													(process?.env?.REACT_APP_HFD_WIDGET_ENABLE === "true" && hfdFlag === true) ?
													<div className="hfdWidget">
														<h1 className="hfdMainTitle fontDmSans">Need help with financing for your next dental procedure?</h1>
														<article className="hfdCard">
															<div className="hfdCardImg">
																<img src={HFD_logo} className="img-fluid" alt="Healthcare Finance Direct"/>
															</div>
															<div className="hfdCardContent">
																<form className="hfdForm">
																	<div className="formRow">
																		<label className="formLabel">How much financing do you need?</label>
																		<div className="selectWrap">
																		<SearchSelect
																			styles={hfdDropdownStyles}
																			placeholder="Select Financing Amount..."
																			options={hfdDropdownOptions}
																			value={totalCost}
																			onChange={(event) => setTotalCost(event)}
																			theme={(theme) => ({...theme,
																			borderRadius: 0,
																			colors: {...theme.colors,
																			primary: "#2563EB",
																			},
																			})}
																		/>
																		</div>
																	</div>
																	<div className="formRow">
																		<div className="fakeWrap">
																		<input 
																			type="checkbox" 
																			className="fakeInput"
																			id="hfdTerm"
																			checked={hfdTermsChecked}
																			onChange={() => setHfdTermsChecked(!hfdTermsChecked)}
																		/>
																		<label htmlFor="hfdTerm" className="fakeLabel">By checking this box, I am confirming that the financing amount needed for my dental procedure was the amount giving to me by my dental provider.</label>
																		</div>
																	</div>
																	<div className="btnWrap">
																		<button
																		type="button"
																		className="btn hfdBtnSecondary"
																		onClick={() => {
																			setShowHfd(true)
																			setShow(false)
																			setHfdErrors(false)
																			setTotalCost("")
																			setHfdTermsChecked(false)
																			setDisable(true)
																		}}
																		>Learn More</button>
																		<button
																			type="button"
																			className="btn hfdBtnPrimary"
																			disabled={(!hfdTermsChecked || totalCost === '') ? disable : showLoader}
																			onClick={() => { getProviderLink() }}
																		>
																		{showLoader && (
																			<HalfCircleSpinner size={15}
																				style={{
																					display: "inline-block",
																					marginRight: "10px",
																					verticalAlign: "middle",
																				}}
																			/>
																		)}
																			Apply Now
																		</button>
																	</div>
																	{hfdErrors && (
																		<small className="d-block w-100 text-center text-danger mt-2">{hfdErrors}</small>
																	)}
																</form>
															</div>
															</article>
													</div> :
													<div className="mapWrapper bgStyles">
														<div className="maps">
															<GoogleMapReact
																bootstrapURLKeys={{
																key: "AIzaSyD_bBaGS6Hk_QgBQQxIwa8td2eU9sbOUtA",
															}}
																defaultZoom={15}
																center={mapCenter}
																zoom={mapZoom}
															>
																<MapMarker
																	zIndex={1}
																	lat={selectedOffice.latitude ? selectedOffice?.latitude : singleOfficeInfo?.latitude }
																	lng={selectedOffice.longitude ? selectedOffice?.longitude : singleOfficeInfo?.longitude }
																	id={selectedOffice.id ? selectedOffice?.id : singleOfficeInfo?.id }
																/>
																{infoWindow && (
																	<InfoWindow
																		zIndex={2}
																		lat={infoWindow?.latitude ? infoWindow?.latitude : singleOfficeInfo?.latitude}
																		lng={infoWindow?.longitude ? infoWindow?.longitude : singleOfficeInfo?.longitude}
																		infoWindow={infoWindow ? infoWindow : singleOfficeInfo}
																		saveInfos={saveOfficeDetails ? saveOfficeDetails : singleOfficeInfo}
																	/>
																)}
															</GoogleMapReact>
														</div>
													</div>
												}
											</div>
								        </div>
								    </div>
								</div>
								{loader === true ? (
						            <div
						                className="loderWrap true"
						            >
										<img
						        		    src={loderIcon}
						        		    className="icn"
						        		    alt="icon"
						        		/>
						            </div>

					            ):(
					                ""
					            )}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
			<span ref={myRef} className="forRef"></span>
			<Modal
				show={modalShow}
				className="errorModal generalPopupStyles"
				centered
				onHide={() => setModalShow(false)}
				backdrop="static"
			>
				<Modal.Body>
					<div className="icnWrap">
						<i className="icn fa fa-times-circle"></i>
					</div>
					<h3 className="h3 modalHeading"> Opps!</h3>
					<div className="memberContextBox">
						<div className="mySubHead">It looks like you have an empty token. If you need any help please contact us at <a href="tel:8775454188">(877) 545-4188</a>.</div>
					</div>
					<div className="text-center btnWrap pt-4">
						<a href="https://membersy.com/" className="btn btnPrimary text-capitalize">Back to home</a>
					</div>
				</Modal.Body>
			</Modal>
			{/* HFD_Modal */}
			<Modal
				show={showHfd}
				className={classNames(hfdStyles.hfdPopupContainer, "HFDpopup")}
				centered
				dialogClassName={hfdStyles.popupDialogue}
				backdrop="static"
			>
				<Modal.Body
					contentclassname={hfdStyles.modalContentArea}
				>
				<button 
					type="button" 
					className="hfdBtnClose"
					onClick={() => {
						setShowHfd(false)
						setShow(true)
						setModalTermsChecked(false)
						setModalTotalCost("")
						setHfdModalErrors("")
						setModalBtnDisable(true)
					}}
				>
					<i className="fa-solid fa-xmark icn"></i>
				</button>
				<div className={hfdStyles.hfdPopupImg}>
					<img
					src={HFD_logo}
					className="img-fluid"
					alt="Healthcare Finance Direct"
					/>
				</div>
				<div className={classNames(hfdStyles.hfdDescriptionWrap)}>
					<h1 className={classNames(hfdStyles.hfdPopupHeading, "h1")}>An Affordable Pay-Over-Time Option For Every Single Patient!</h1>
					<p className={hfdStyles.hfdPopupPara}>Access to good healthcare can be expensive. Thousands of patients postpone the treatment they need every year because they lack cash or can’t access credit. Healthcare Finance Direct has a solution. Apply now and receive financing you deserve for your next dental procedure!</p>
				</div>
				<form className={classNames(hfdStyles.hfdFormStyles,"hfdForm")}>
					<div className="formRow">
					<label className="formLabel">How much financing do you need?</label>
					<div className="selectWrap">
						<SearchSelect
							styles={hfdDropdownStyles}
							placeholder="Select Financing Amount..."
							options={hfdDropdownOptions}
							value={modalTotalCost}
							onChange={(event) => setModalTotalCost(event)}
							theme={(theme) => ({...theme,
									borderRadius: 0,
									colors: {...theme.colors,
									primary: "#2563EB",
								},
							})}
						/>
					</div>
					</div>
					<div className="formRow">
					<div className="fakeWrap">
						<input 
							type="checkbox" 
							className="fakeInput"
							id="terms"
							checked={modalTermsChecked}
							onChange={() => setModalTermsChecked(!modalTermsChecked)}
						/>
						<label htmlFor="terms" className="fakeLabel">By checking this box, I am confirming that the financing amount needed for my dental procedure was the amount giving to me by my dental provider.</label>
					</div>
					</div>
					<div className={classNames(hfdStyles.detailsButton, "btnWrap")}>
					<button
						type="button"
						className={classNames(hfdStyles.applyButton)}
						disabled={(!modalTermsChecked || modalTotalCost === '') ? modalBtnDisable : modalShowLoader}
						onClick={() => { 
							getModalProviderLink()
						}}
					>
						{modalShowLoader && (
							<HalfCircleSpinner size={15}
								style={{
									display: "inline-block",
									marginRight: "10px",
									verticalAlign: "middle",
								}}
							/>
						)}
						Apply Now
					</button>
					</div>
					{hfdModalErrors && (
					<small className="d-block w-100 text-center text-danger mt-2">{hfdModalErrors}</small>
					)}
				</form>
				</Modal.Body>
			</Modal>
        </>
    );
};

export default Checkout;
