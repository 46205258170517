import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../styles/module/cancelModal.module.scss";
import cancelMemberShipService from "../api/services/cancelMemberService";
import {HalfCircleSpinner} from "react-epic-spinners";

const CancelModal = (props) => {

  const { onClose, open } = props;
  const [showLoader, setShowloader] = useState(false);
  const [apiErrors, setapiErrors] = useState("")

  const handleClose = () => {
    setapiErrors("");
    onClose(false);
  };

  const cancelMemberShip = () => {
    setShowloader(true)
    let data = { subscription_id: props.subscription_id };

    // calling cancel member subscription api 
    cancelMemberShipService.cancelMember(data).then((response) => {
      if (response.status === 200) {
        props.setIsSucess(true);
        setShowloader(false);

        // setting success msgs in success case
        props.setSuccessTextHeading(`Success: Auto-Renew Disabled`);
        props.setSuccessTextMsg(`<p>The account’s current subscription has been disabled and auto-renewal billing has been turned off.</p>
        <div><strong className="text3">Note:</strong> The account’s canceled subscription will show as Pending Cancelation until it’s next term date.</div>`);
        
        handleClose();
      } else {
        setapiErrors(response.message);
        setShowloader(false);
      }
    });
  };
  return (
    <div data-testid = "cancel_subscription_modal" >
      <Modal
        show={open}
        size="lg"
        className={styles.popupContainer}
        centered
        onHide={handleClose}>
        <Modal.Header className={styles.hedaerAlign}>
          {
            apiErrors !== "" && (
              <span data-testid="api_error" >{apiErrors}</span>
            )
          }
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={styles.motalTitle}
          >
            Disable Auto-Renew
            <p>Hopefully it’s not a goodbye - just a see ya’ later.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBodyContent}>
          <p>
            If you disable auto-renew, your current subscription will immediately go into
            PENDING CANCELATION status and auto-renewal billing for your account
            will be turned off.
          </p>
          <p>
            <b>Note:</b> You and any dependents on your account (if applicable)
            will continue to receive member-only discounts on dental services at
            your participating dental office up until your current subscription
            term date..
          </p>
        </Modal.Body>
        <Modal.Footer className={styles.footerStyles}>
          <button data-testid="go_back_btn" className={styles.btnStyles} onClick={handleClose}>
            Go Back
          </button>
          <button data-testid="cancel_subscription_btn" className={styles.btnStylesCancel} onClick={cancelMemberShip}>
          {showLoader && (
              <HalfCircleSpinner size={18}
              style={{
                display: "inline-block",
                marginRight: "5px",
              }}
            />
            )} 
            Disable Auto-Renew
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CancelModal;
