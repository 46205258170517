import React, {useState, useEffect} from "react";
import { useLocation, useHistory } from 'react-router-dom';
import "../../assets/css/updateBilling.css";
import img04 from "../../assets/images/payment_vendors.png";
import img05 from "../../assets/images/icn_participating_office.svg";
import img06 from "../../assets/images/icn_billing_frequency.svg";
import loderIcon from "../../assets/images/loader.gif";
import errorIcon from "../../assets/images/error_icn.svg";
import classNames from "classnames";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "../../components/shared-components/paymentDetails/PaymentDetails";
import Modal from "react-bootstrap/Modal";
import updatePaymentDetail from "../../api/services/updatePaymentDetail";
import memberPaymentInfo from "../../api/services/getMemberPaymentDetail";

const UpdateBilling = (props) => {
	const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
	const [termsChecked, setTermsChecked] = useState(false);
	const [formSubmitted, setSubmitted] = useState(0);
	const [cardSource, setCardSource] = useState(null);
	const [disable, setDisable] = useState(false);
    const [errModel, setErrModelShow] = useState(false);
    const [stipePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY));

    // Get URL params
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const hashKey = queryParams.get('key')
    const channeltype = queryParams.get('channel')
	const supportLink = "https://membersy.com/marketplace/support";

    // Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Update Billing | membersy";
	}, []);

    useEffect(() => {
        memberPaymentInfo.paymentInfo(hashKey).then((res) => {
            if (res.status !== 200) {
                history.push("/")
            } else {
                setUserData(res.data);
            }
        }).catch((err) => {
            if(err) {
                history.push("/")
            }
		})
	}, []);

    useEffect(() => {
        if (hashKey === undefined || hashKey === '') {
            history.push("/")
        }
	}, []);
    
    const validateForm = () => {
		let errors = {};
		let isValid = true;
		
		if (!termsChecked) {
			isValid = false;
			errors["termsChecked"] = "Please agree with the terms and conditions.";	
			setLoader(false);
		}
		setErrors(errors);
		return isValid;
	};

    const handleSubmit = async (e) => {
		e.preventDefault();
		if(validateForm()){
			setSubmitted((prevState) => prevState + 1);
		} else {
			setLoader(false);
		}
	};

    useEffect(() => {
		if (errors) {
		  let errorNodes = document.querySelectorAll(".support-error,.checkError");
		  let firstNode = errorNodes[0];
		}
	}, [errors]);

    const getRequestBody = () => {
        let body = {
            source_id: cardSource?.id,
            hash_id: hashKey,
        }

        if (channeltype) {
            body.channel_type = channeltype
        }

        return body;
    }

    const submitForm = () => {
		if (validateForm()) {
			setDisable(true);
            setLoader(true)

            const requestBody = getRequestBody()

            updatePaymentDetail.updatePayment(requestBody).then((response) => {
                setLoader(false);

                if (response.status === 200) {
                    history.push("/updatebilling-success");
                    
                } else if (response.status === 409) {
                    setErrModelShow(true);
                    setErrorMessage(response.message)
                }

            }).catch((err) => {
                setLoader(false);
                if(err) {
                    setErrModelShow(true);
                    setErrorMessage("Server Error!")
                }
            })  
		}
	}
	
	useEffect(() => {
		if (cardSource) {
		    submitForm();
		}
	}, [cardSource]);
    
    return (
        <section className="renewPaySec">
            <div className="container">
                <div className="renewPayContentWrap">
                    <header className="renewHeaderBlock">
                        <h1 className="h1">Update Billing Details</h1>
                        <p>Make sure your membership savings continue into the next year by updating your payment information. We’ll take care of the rest — and you’ll be ready for your next appointment!</p>
                    </header>
                    <div className="fieldGroupLabel">
                        <h2>Account Information</h2>
                    </div>
                    <form className="updateBillingForm position-relative" onSubmit={(event) => handleSubmit(event)}>
                        <div className="row formRow">
                            <div className="col col-12 col-md-4">
                                <div className="memberInfoWrap">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <div className="form-control">{userData?.first_name}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <div className="form-control">{userData?.last_name}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Member ID</label>
                                        <div className="form-control">{userData?.id}</div>
                                    </div>
                                    <div className="fieldGroupLabel renFormPayDetail">
                                        <h2>Payment Details</h2>
                                        <img src={img04}  alt="payment icon"/>
                                    </div>
                                    <div className="form-group">
                                        <Elements stripe={stipePromise}>
                                            <PaymentDetails
                                                setCardSource={setCardSource}
                                                formSubmitted={formSubmitted}
                                                setLoader={setLoader}
                                            />
                                        </Elements>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-md-8">
                                <div className="PaymentSummaryWrap">
                                    <h2 className="h2 textCenter">Membership Summary</h2>
                                    <div className="csBillingContext">
                                        <div className="h3 csHeading">Plan</div>
                                        <div className="csSummary txtCapitalize">{userData?.plan_name}</div>
                                    </div>
                                    <div className="csBillingContext">
                                        <div className="h3 csHeading">Billing Frequency</div>
                                        <div className="csSummary txtCapitalize">{userData?.frequency}</div>
                                    </div>
                                    <div className="csBillingContext">
                                        <div className="h3 csHeading">Automatic Renewal</div>
                                        <div className="csSummary">
											Your Annual membership will automatically renew for an additional term on your respective renewal date.
											You may turn auto-renew on or off using self-service options in the member portal or by contacting <a href={supportLink} target="_blank">Member Support</a>.
										</div>
                                    </div>

                                    <div className="csBillingContext">
                                        <div className="h3 csHeading">Cancellation</div>
                                        <div className="csSummary">
											You may request cancellation at any time by contacting <a href={supportLink} target="_blank">Member Support</a>.
											You will receive a full refund of membership fees if (i) you cancel within the applicable cancellation window,
											and (ii) no services have been provided to you under the plan. Restrictions and exceptions may apply,
											please review Member Terms & Conditions for details.
										</div>
                                    </div>
                                    <div className="paymentContentCheck">
                                        <div className="d-flex">
                                            <div className="fakeWrap">
                                                <input 
                                                    type="checkbox"
                                                    id="termsChecked"
                                                    className="fakeInput"
                                                    checked={termsChecked} 
                                                    onChange={() => setTermsChecked(!termsChecked)}
                                                />
                                                <label 
                                                    htmlFor="termsChecked"
                                                    className={classNames(
                                                        errors.termsChecked ?
                                                        "fakeCheckmark checkError"
                                                        : "fakeCheckmark"
                                                    )}
                                                ></label>
                                            </div>
                                            <div className="mpCheckTerms">
												The account’s primary member has read, understands, and agrees to the <a href={userData?.term_condition} target="_blank" rel="noreferrer noopener">Member Terms &amp; Conditions.</a>
                                            </div>
                                        </div>
                                        {errors?.termsChecked &&
                                            <small className="support-error">{errors?.termsChecked}</small>
                                        }
                                    </div>
                                    <div className="psSummayButton">
                                        <button 
                                            type="submit"
                                            className="btn"
                                        >
                                            Update Billing Information
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loader === true ? (
                            <div 
                                className="loderWrap true"
                            >
                                <img 
                                    src={loderIcon} 
                                    className="icn" 
                                    alt="icon"
                                />
                            </div>     
                        ):(
                            ""
                        )}
                    </form>
                </div>
            </div>
            <Modal
                show={errModel}
                className="upErrorModal generalPopupStyles"
                centered
                onHide={() => setErrModelShow(false)}
                backdrop="static"
                >
                <Modal.Body>
                    <button type="button" 
                        className="btnCloses"  
                        onClick={() => setErrModelShow(false)}
                    >
                        <i className="fa-solid fa-xmark icn"></i>
                    </button>
                    <div className="bodyInnerWrap">
                        <div className="icnWrap">
                            <img 
                                src={errorIcon}
                                className="img-fluid"
                                alt="Insufficient Funds"
                            />
                        </div>
                        <div className="contentWrap">
                            <h3 className="h3 modalHeading"> Unable to process</h3>
                            <div className="memberContextBox">
                                <div className="mySubHead">{errorMessage}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default UpdateBilling;