import React, { useEffect, useRef, useState } from "react";
import useScript  from '../hooks/useScript';
import classNames from "classnames";

const GooglePlacesAutocomplete = (props) => {
	const autoCompleteRef = useRef();
	const inputRef = useRef();
	const options = {
	  fields: ["address_components", "geometry", "icon", "formatted_address"]
	}

	const [customGoogleMap, setCustomGoogleMap] = useState();

	const status = useScript(`https://maps.googleapis.com/maps/api/js?key=${process?.env?.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`);

	useEffect(() => {
		if (status === "ready") {
		  	if(window.google !== undefined){
				setCustomGoogleMap(window.google.maps)
				return;

		  	} else {
				console.log("Map Instance is not loaded yet,!")
		  	}
		}
	}, [status])

	useEffect(() => {
		if (customGoogleMap) {
			googlePlaceAutocomplete()
			if (props?.inputValue == '') {
				googleCurrentPlace()
			}
		}
	}, [customGoogleMap])

	// Address purify function
	const PurifyAddress = (results) => {
		const typePostalCode = filterAddressBasedOnType(results, 'postal_code');
		const typeLocality = filterAddressBasedOnType(results, 'locality');
		const typeAdminAreaLevel = filterAddressBasedOnType(results, 'administrative_area_level_1');
		if(typePostalCode){
			postalCode(typePostalCode)
		}else{
			props?.bindZip("")
		}
		if (typeLocality) {
			locality(typeLocality);
		}
		if (typeAdminAreaLevel) {
			adminAreaLevel(typeAdminAreaLevel)
		}
	}

	// Filter the adress on the type base
	const filterAddressBasedOnType = (results, type) => {
		if(Array.isArray(results))
			return results.find(x => x.address_components.find(x => x.types[0] === type)
		);
		return results.address_components.find(x => x.types[0] === type);
	}
	const postalCode = (typePostalCode) => {
		const address = filterAddressBasedOnType(typePostalCode, 'postal_code');
		var formatted_address = typePostalCode.formatted_address;
		var postalcode = address.short_name;
		let streetAddress = formatted_address.split(",")
		props?.setInputValue(streetAddress[0]);
		props?.setGoogleInput(streetAddress[0]);
		props?.bindZip(postalcode)
	}
	const locality = (typeLocality) => {
		const address = filterAddressBasedOnType(typeLocality, 'locality');
		var cityName = address.short_name;
		props?.setCity(cityName)
	}
	const adminAreaLevel = (typeAdminAreaLevel) => {
		const address = filterAddressBasedOnType(typeAdminAreaLevel, 'administrative_area_level_1');
		var StateShortName =address.short_name;
		var StateLongName = address.long_name;
		props?.setStateName([{label: StateLongName, value: StateShortName}])
	}

	const googleCurrentPlace = () => {
		navigator.geolocation.getCurrentPosition(function(position) {
			const lat = position.coords.latitude;
			const lng = position.coords.longitude;
			const google_map_pos = new window.google.maps.LatLng( lat, lng );
			const google_maps_geocoder = new window.google.maps.Geocoder();
			google_maps_geocoder.geocode(
				{ 'latLng': google_map_pos },
				function( results, status ) {

					if (status == window.google.maps.GeocoderStatus.OK) {
						PurifyAddress(results)
					} else {
						console.log("Geocoder failed due to: " + status);
					}
				}
			);
		});
	}

	const googlePlaceAutocomplete = () => {
		autoCompleteRef.current = new window.google.maps.places.Autocomplete((inputRef.current), options);
		autoCompleteRef.current.addListener('place_changed', async function () {
	
			var place = await autoCompleteRef.current.getPlace();
		
			var place_lat = place?.geometry?.location?.lat();
			var place_lng = place?.geometry?.location?.lng();
			
			var latlng = new window.google.maps.LatLng(place_lat, place_lng);
		
			const geocoder = new window.google.maps.Geocoder();

			const subPremise = place.address_components.find(x => x.types[0] === 'subpremise');
			const route = place.address_components.find(x => x.types[0] === 'route');
			const streetNumber = place.address_components.find(x => x.types[0] === 'street_number');

			geocoder.geocode({ 'latLng': latlng,}, function(results, status) {        
				props?.bindZip("")

				if (status == window.google.maps.GeocoderStatus.OK) {
					PurifyAddress(results)
					props?.setInputValue(
						(streetNumber?.long_name && route?.long_name) ?
						streetNumber?.long_name + ' ' + route?.long_name
						: place?.address_components[0]?.long_name + ' ' + place?.address_components[1]?.long_name
					)
					props?.setAddress2(subPremise?.long_name)
				} else {
					console.log("Geocoder failed due to: " + status);
				}
			})
		});
	}

	return (
		<input
			ref={inputRef}
			placeholder={props?.placeholder ? props.placeholder : ''}
			value={props?.inputValue}
			onChange={(e) => {
				props?.setInputValue(e.target.value);
				props?.setGoogleInput(e.target.value);
				props?.setGoogleInput(prev => {
				if (prev === '') {
					props?.bindZip('')
					props?.setCity('')
					props?.setStateName('')
					props?.bindState('')
					props?.setAddress2('')
				}
				return prev;
			});
			}}
			type="text"
			className={classNames("formControl zipContainer",
				props.stepThreeError?.googleInput
				? "fcError"
				: ""
			)}
		/>
	);
};

export default GooglePlacesAutocomplete;
