import React, { useState, useEffect, useRef } from "react";
import { Drawer } from "../drawer";
import styles from "../../styles/module/adddependent.module.scss";
import classNames from "classnames";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "../shared-components/paymentDetails/PaymentDetails";
import updateBillingService from "../../api/services/updateBillingService";
import ErrorHandler from "../ErrorHandler";
import { HalfCircleSpinner } from "react-epic-spinners";

export const CardDetailsDrawer = (props) => {
  const [stipePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY))
  const [cardSource, setCardSource] = useState(null);
  const [stripeError, setStripeError] = useState({});
  const [formSubmitted, setSubmitted] = useState(0);
  const [loader, setLoader] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  let failureRef = useRef();

  const handleCardDetailsSubmit = (event) => {
    event.preventDefault();
    setSubmitted((prevState) => prevState + 1);
  };

  const submitForm = () => {
    setLoader(true);
    let requestBody = {
      payment_method: cardSource.id,
      card: JSON.stringify(cardSource.card),
    };

    props.setSuccessTextMsg("");
    updateBillingService.updateBilling(requestBody).then((response) => {
      setLoader(false);
      if (response.status === 200) {
        props.setOpen(props.close);
        document.body.style.overflow = "scroll";
        props.setIsSucess(true);
        props.setSuccessTextHeading(`Success: Billing Preferences Updated`);
        props.setSuccessTextMsg(`<p>You’ve successfully updated the account’s billing preferences.</p>
        <div><strong className="text3">Note:</strong> Click Continue to view the account’s updated billing information</div>`);
      } else {
        setApiErrors(response.message);
      }
    });
  };

  useEffect(() => {
    if (cardSource) {
      submitForm();
    }
  }, [cardSource]);


  useEffect(() => {
    if (failureRef.current !== undefined) {
      failureRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [apiErrors]);


  return (
    <>
      {
        props.open && (
          <Drawer open={props.open}>
            <div className={styles.DependentMainContainer} data-testid="card_detail_drawer" >
              <header className={styles.dependentHeader}>
                <div className="d-flex align-items-start justify-content-between">
                  <div>
                    <h2 className={styles.addHeading}>Update Card Details</h2>
                    <p className={styles.addsubHeading}>
                      {
                        props?.card ? (
                          <>
                            The credit/debit card you currently have on file is a&nbsp;
                            {props.userData?.card?.card_type} ending in&nbsp;
                            {props.userData?.card?.last_4} (exp:&nbsp;
                            {props.userData?.card?.expiry_date})
                          </>
                        ) : <>{" "}Currently there's no designated card on file for this account</>
                      }
                    </p>
                  </div>
                  <div className={styles.closeWrapper}>
                    <button
                      data-testid="cancel_button_svg"
                      aria-label="Close panel"
                      className={styles.closebtn}
                      onClick={() => {
                        document.body.style.overflow = "scroll";
                        props.setOpen(props.close);
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={"adddependent_closeIcon__1q-il"}>
                        <path d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </header>

              <form onSubmit={handleCardDetailsSubmit}>
                <div className={styles.dependdivContainer}>
                  {apiErrors && (
                    <div ref={failureRef}>
                      <ErrorHandler apiErrors={apiErrors} />
                    </div>
                  )}
                  <Elements stripe={stipePromise}>
                    <PaymentDetails
                      setCardSource={setCardSource}
                      stripeError={stripeError}
                      setStripeError={setStripeError}
                      formSubmitted={formSubmitted}
                      setLoader={setLoader}
                    />
                  </Elements>
                </div>

                {/* <!-- Action buttons --> */}
                <div className="">
                  <div
                    className={classNames(
                      styles.btnprimaryContainer,
                      " d-flex justify-content-end"
                    )}
                  >
                    <div className={styles.cancelWrapper}>
                      <button
                        data-testid="cancel_button"
                        type="button"
                        className={styles.cancelBtn}
                        onClick={() => {
                          document.body.style.overflow = "scroll";
                          props.setOpen(props.close);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <span className={styles.addWrapper}>
                      <button
                        data-testid="update_card_btn"
                        type="submit"
                        className={styles.addBtn}
                        disabled={loader}
                      >
                        {loader && (
                          <HalfCircleSpinner
                            size={18}
                            style={{
                              display: "inline-block",
                              marginRight: "5px",
                            }}
                          />
                        )}
                        Update Card
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </Drawer>
        )
      }
    </>
  );
};
