import React from "react";
import styles from "../../styles/module/adddependent.module.scss";

export const DrawerInput = ({ testid, id, label, bindInput, placeholder }) => (
  <div className={styles.cvContainerInput}>
    <div>
      <label htmlFor={id} className={styles.labelName}>
        {label}
      </label>
    </div>
    <div className="">
      {id === "primary_first_name" ||
      id === "primary_last_name" ||
      id === "email" ||
      id === "phone" ? (
        <input
          data-testid = {testid}
          id={id}
          className="form-input "
          placeholder={placeholder}
          {...bindInput}
          readOnly
        />
      ) : (
        <input
          data-testid = {testid}
          id={id}
          className="form-input "
          placeholder={placeholder}
          {...bindInput}
        />
      )}
    </div>
  </div>
);
