import httpClient from "../httpClient";
import { GET_HFD_LINK } from "../config";
import unformatePhone from "../../utility/unformatePhone";
import moment from "moment";

export const hfdRequestBody = (requestBody) => {
    let bodyObjcet = {
        ProviderId: process?.env?.REACT_APP_HFD_PROVIDER_ID,
        Type: process?.env?.REACT_APP_HFD_PROVIDER_TYPE,
        TotalCost: requestBody.TotalCost,
        FirstName: requestBody?.first_name,
        LastName: requestBody?.last_name,
        DOB: moment(requestBody?.dob).format("MM-DD-YYYY"),
        StreetLine1: requestBody?.address1,
        StreetLine2: requestBody?.address2,
        City: requestBody?.city,
        StatoId: requestBody.StatoId,
        PostalCode: requestBody?.zip,
        CountryCode: "US",
        Email: requestBody?.email,
        Phone: unformatePhone(requestBody?.phone)
    }
    return bodyObjcet;
}

export const getHFDauthenticLink = {
  getLink: (data) => httpClient.post(GET_HFD_LINK, data),
};