import React, {useState, useImperativeHandle, forwardRef, useEffect} from 'react'
import DependentInput from './shared-components/dependentInput/dependentInput';
import { dependentsDropdown } from "../data/join-now";
import { SearchSelect } from "./shared-components/searchSelect";
import { useInput } from "../hooks/useInput";
import classNames from "classnames";
import styles from "../styles/module/join-now.module.scss";

const AddDependentsInput = forwardRef((props, ref) =>{
    const {handleDepCount, maxDep} = props
    const [addDependents, setAddDependents] = useState(null);
    const [dependent1FirstName, bindDependent1FirstName] = useInput("");
    const [dependent1LastName, bindDependent1LastName] = useInput("");
    const [dob1, bindDOB1] = useInput("");
    const [dependent2FirstName, bindDependent2FirstName] = useInput("");
    const [dependent2LastName, bindDependent2LastName] = useInput("");
    const [dob2, bindDOB2] = useInput("");
    const [dependent3FirstName, bindDependent3FirstName] = useInput("");
    const [dependent3LastName, bindDependent3LastName] = useInput("");
    const [dob3, bindDOB3] = useInput("");
    const [dependent4FirstName, bindDependent4FirstName] = useInput("");
    const [dependent4LastName, bindDependent4LastName] = useInput("");
    const [dob4, bindDOB4] = useInput("");
    const [dependent5FirstName, bindDependent5FirstName] = useInput("");
    const [dependent5LastName, bindDependent5LastName] = useInput("");
    const [dob5, bindDOB5] = useInput("");
    const [dependent6FirstName, bindDependent6FirstName] = useInput("");
    const [dependent6LastName, bindDependent6LastName] = useInput("");
    const [dob6, bindDOB6] = useInput("");
    const [dependent7FirstName, bindDependent7FirstName] = useInput("");
    const [dependent7LastName, bindDependent7LastName] = useInput("");
    const [dob7, bindDOB7] = useInput("");
    const [dependent8FirstName, bindDependent8FirstName] = useInput("");
    const [dependent8LastName, bindDependent8LastName] = useInput("");
    const [dob8, bindDOB8] = useInput("");
    const [dependent9FirstName, bindDependent9FirstName] = useInput("");
    const [dependent9LastName, bindDependent9LastName] = useInput("");
    const [dob9, bindDOB9] = useInput("");
    const [errors, setErrors] = useState({});
    const [depDropdownList, setDepDropdownList] = useState(dependentsDropdown)


    // only show the dropdown according to the max dep limit
    useEffect(()=>{
        // console.log("Max Dep :: ",maxDep)
        if(maxDep){
            const depDropdownList = []
            dependentsDropdown.map((e, index) =>{
                if(index < maxDep + 1 ){
                    depDropdownList.push(e)
                }
            })
            setDepDropdownList(depDropdownList)
        }
    },[maxDep])

    useImperativeHandle(ref, () => ({
        // check the validations when this function called from the parent component
         addDependentsValidations(){

            let errors = {};
            let isValid = true;
    
            if (addDependents === null) {
                isValid = false;
                errors["addDependents"] = "Please select a dependent";
              }
          
            if (addDependents >= 1) {
            if (!dependent1FirstName) {
                isValid = false;
                errors["dependent1FirstName"] = "Please enter your name";
            }
            if (!dependent1LastName) {
                isValid = false;
                errors["dependent1LastName"] = "Please enter your name";
            }
            if (!dob1) {
                isValid = false;
                errors["dob1"] = "Please enter your Date of Birth";
            }
            }
            if (addDependents >= 2) {
            if (!dependent2FirstName) {
                isValid = false;
                errors["dependent2FirstName"] = "Please enter your name";
            }
            if (!dependent2LastName) {
                isValid = false;
                errors["dependent2LastName"] = "Please enter your name";
            }
            if (!dob2) {
                isValid = false;
                errors["dob2"] = "Please enter your Date of Birth";
            }
            }
            if (addDependents >= 3) {
            if (!dependent3FirstName) {
                isValid = false;
                errors["dependent3FirstName"] = "Please enter your name";
            }
            if (!dependent3LastName) {
                isValid = false;
                errors["dependent3LastName"] = "Please enter your name";
            }
            if (!dob3) {
                isValid = false;
                errors["dob3"] = "Please enter your Date of Birth";
            }
            }
            if (addDependents >= 4) {
            if (!dependent4FirstName) {
                isValid = false;
                errors["dependent4FirstName"] = "Please enter your name";
            }
            if (!dependent4LastName) {
                isValid = false;
                errors["dependent4LastName"] = "Please enter your name";
            }
            if (!dob4) {
                isValid = false;
                errors["dob4"] = "Please enter your Date of Birth";
            }
            }
            if (addDependents >= 5) {
            if (!dependent5FirstName) {
                isValid = false;
                errors["dependent5FirstName"] = "Please enter your name";
            }
            if (!dependent5LastName) {
                isValid = false;
                errors["dependent5LastName"] = "Please enter your name";
            }
            if (!dob5) {
                isValid = false;
                errors["dob5"] = "Please enter your Date of Birth";
            }
            }
            if (addDependents >= 6) {
            if (!dependent6FirstName) {
                isValid = false;
                errors["dependent6FirstName"] = "Please enter your name";
            }
            if (!dependent6LastName) {
                isValid = false;
                errors["dependent6LastName"] = "Please enter your name";
            }
            if (!dob6) {
                isValid = false;
                errors["dob6"] = "Please enter your Date of Birth";
            }
            }
        
            if (addDependents >= 7) {
            if (!dependent7FirstName) {
                isValid = false;
                errors["dependent7FirstName"] = "Please enter your name";
            }
            if (!dependent7LastName) {
                isValid = false;
                errors["dependent7LastName"] = "Please enter your name";
            }
            if (!dob7) {
                isValid = false;
                errors["dob7"] = "Please enter your Date of Birth";
            }
            }

            if (addDependents >= 8) {
                if (!dependent8FirstName) {
                    isValid = false;
                    errors["dependent8FirstName"] = "Please enter your name";
                }
                if (!dependent8LastName) {
                    isValid = false;
                    errors["dependent8LastName"] = "Please enter your name";
                }
                if (!dob8) {
                    isValid = false;
                    errors["dob8"] = "Please enter your Date of Birth";
                }
            }
    
            if (addDependents >= 9) {
                if (!dependent9FirstName) {
                    isValid = false;
                    errors["dependent9FirstName"] = "Please enter your name";
                }
                if (!dependent9LastName) {
                    isValid = false;
                    errors["dependent9LastName"] = "Please enter your name";
                }
                if (!dob9) {
                    isValid = false;
                    errors["dob9"] = "Please enter your Date of Birth";
                }
            }

            setErrors(errors);
            return isValid;
        },
        
        // send the dependents data when parent component called this method
        getDependentsData(){
            let memberDetails = [];
            if (addDependents >= 1) {
              let tempData = {
                first_name: dependent1FirstName,
                last_name: dependent1LastName,
                dob: new Date(dob1),
              };
              memberDetails.push(tempData);
            }
            if (addDependents >= 2) {
              let tempData = {
                first_name: dependent2FirstName,
                last_name: dependent2LastName,
                dob: new Date(dob2),
              };
              memberDetails.push(tempData);
            }
            if (addDependents >= 3) {
              let tempData = {
                first_name: dependent3FirstName,
                last_name: dependent3LastName,
                dob: new Date(dob3),
              };
              memberDetails.push(tempData);
            }
            if (addDependents >= 4) {
              let tempData = {
                first_name: dependent4FirstName,
                last_name: dependent4LastName,
                dob: new Date(dob4),
              };
              memberDetails.push(tempData);
            }
            if (addDependents >= 5) {
              let tempData = {
                first_name: dependent5FirstName,
                last_name: dependent5LastName,
                dob: new Date(dob5),
              };
              memberDetails.push(tempData);
            }
            if (addDependents >= 6) {
              let tempData = {
                first_name: dependent6FirstName,
                last_name: dependent6LastName,
                dob: new Date(dob6),
              };
              memberDetails.push(tempData);
            }
            if (addDependents >= 7) {
              let tempData = {
                first_name: dependent7FirstName,
                last_name: dependent7LastName,
                dob: new Date(dob7),
              };
              memberDetails.push(tempData);
            }
            if (addDependents >= 8) {
                let tempData = {
                  first_name: dependent8FirstName,
                  last_name: dependent8LastName,
                  dob: new Date(dob8),
                };
                memberDetails.push(tempData);
            }
            if (addDependents >= 9) {
                let tempData = {
                  first_name: dependent9FirstName,
                  last_name: dependent9LastName,
                  dob: new Date(dob9),
                };
                memberDetails.push(tempData);
            }

            return memberDetails
        },

        // send the dependents count to parent component when parent component called this method 
        getDependentCount(){
            var count = addDependents
            return count
        }

    }));
    
    const colourStyles = {
        menu: (provided, state) => ({
            ...provided,
            borderRadius: '7.49px',
            cursor: 'pointer',
            boxShadow: '0 2.25px 7.49px 0 rgba(0, 59, 77, 0.102)',
            padding: '0 5px',
        }),
        
        option: (styles, { data, isDisabled, isFocused, isSelected, placeholder}) => {
            return {
              ...styles,
              backgroundColor: isDisabled ? null : isSelected ? '#006f9026' : isFocused ? "#006f9026" : null,
              color: isDisabled ? '#f5f5f5' : isSelected ? '#003B4D' : isFocused ? "#003B4D" : "#003B4D",
              fontSize: "14px",
              fontWeight: "700",
              transition: "all 0.35s ease",
			  cursor: 'pointer',
			  borderRadius: '5px',
            };
        },
		placeholder: (styles) => ({ ...styles, color: '#003b4d80' }),
    }

    return (
        // <div className=" addDependentSelectWrap" data-testid="add_dep_component">
        <div className="addDepCheckoutWrap" data-testid="add_dep_component">
            <div className={classNames(styles.selectWrapper, "")}>
                <SearchSelect
                    id="dependents"
                    placeholder="Select &hellip;"
                    styles={colourStyles}
                    className={classNames("memPlanSelect",
                        errors.addDependents
                          ? "fcError"
                          : ""
                    )}
                    options={depDropdownList}
                    onChange={(event) => {
                        setAddDependents(event.value)
                        handleDepCount(event.value)
                    }}
                />
                
                {/* custom dropdown to test the component*/}
                {/* compoenent this code below (test runs successfully through this custom dropdown) */}
                {/* <select data-testid="dropdown_select" onChange={(e)=>{
                    setAddDependents(e.target.value)
                }}>
                    
                    {
                        dependentsDropdown.map((e)=> {
                            return <option key={e.value} value={e.value}>{e.label}</option>
                        })
                    }

                </select> */}

                {errors?.addDependents && (
                    <small className="support-error">
                    {errors.addDependents}
                    </small>
                )}
            </div>
            
            <div className="addInputHolder">
                    
                {addDependents >= 1 && (
                <DependentInput
                    firstNameID="dependent1FirstName"
                    bindFirstName={bindDependent1FirstName}
                    lastNameID="dependent1LastName"
                    bindLastName={bindDependent1LastName}
                    dobID="dob1"
                    dobValue={dob1}
                    bindDob={bindDOB1}
                    errors={errors}
                />
                )}
    
                {addDependents >= 2 && (
                <DependentInput
                    firstNameID="dependent2FirstName"
                    bindFirstName={bindDependent2FirstName}
                    lastNameID="dependent2LastName"
                    bindLastName={bindDependent2LastName}
                    dobID="dob2"
                    dobValue={dob2}
                    bindDob={bindDOB2}
                    errors={errors}
                />
                )}
    
                {addDependents >= 3 && (
                <DependentInput
                    firstNameID="dependent3FirstName"
                    bindFirstName={bindDependent3FirstName}
                    lastNameID="dependent3LastName"
                    bindLastName={bindDependent3LastName}
                    dobID="dob3"
                    dobValue={dob3}
                    bindDob={bindDOB3}
                    errors={errors}
                />
                )}
    
                {addDependents >= 4 && (
                <DependentInput
                    firstNameID="dependent4FirstName"
                    bindFirstName={bindDependent4FirstName}
                    lastNameID="dependent4LastName"
                    bindLastName={bindDependent4LastName}
                    dobID="dob4"
                    dobValue={dob4}
                    bindDob={bindDOB4}
                    errors={errors}
                />
                )}
    
                {addDependents >= 5 && (
                <DependentInput
                    firstNameID="dependent5FirstName"
                    bindFirstName={bindDependent5FirstName}
                    lastNameID="dependent5LastName"
                    bindLastName={bindDependent5LastName}
                    dobID="dob5"
                    dobValue={dob5}
                    bindDob={bindDOB5}
                    errors={errors}
                />
                )}
    
                {addDependents >= 6 && (
                <DependentInput
                    firstNameID="dependent6FirstName"
                    bindFirstName={bindDependent6FirstName}
                    lastNameID="dependent6LastName"
                    bindLastName={bindDependent6LastName}
                    dobID="dob6"
                    dobValue={dob6}
                    bindDob={bindDOB6}
                    errors={errors}
                />
                )}
    
                {addDependents >= 7 && (
                <DependentInput
                    firstNameID="dependent7FirstName"
                    bindFirstName={bindDependent7FirstName}
                    lastNameID="dependent7LastName"
                    bindLastName={bindDependent7LastName}
                    dobID="dob7"
                    dobValue={dob7}
                    bindDob={bindDOB7}
                    errors={errors}
                />
                )}
    
                {addDependents >= 8 && (
                <DependentInput
                    firstNameID="dependent8FirstName"
                    bindFirstName={bindDependent8FirstName}
                    lastNameID="dependent8LastName"
                    bindLastName={bindDependent8LastName}
                    dobID="dob8"
                    dobValue={dob8}
                    bindDob={bindDOB8}
                    errors={errors}
                />
                )}
                
                {addDependents >= 9 && (
                <DependentInput
                    firstNameID="dependent9FirstName"
                    bindFirstName={bindDependent9FirstName}
                    lastNameID="dependent9LastName"
                    bindLastName={bindDependent9LastName}
                    dobID="dob9"
                    dobValue={dob9}
                    bindDob={bindDOB9}
                    errors={errors}
                />
                )}
            </div>

        </div>
    )
})

export default AddDependentsInput
