import React from 'react'
import styles from "../../../styles/module/join-now.module.scss";
import classNames from "classnames";

const DependentInput = ({ firstNameID, bindFirstName, lastNameID, bindLastName, dobID, dobValue, bindDob, errors }) => (
    <div
      className={classNames(
        styles.dependentContainer,
        "d-flex justify-content-between pt-3"
      )}
    >
      <div className={classNames(styles.dependentForm, "adInputWrap")}>
        <label className="d-block hLabel">
            First Name
        </label>
        <input 
          placeholder="First name"
          id={firstNameID}
          className={classNames("form-input",
          errors[firstNameID]
            ? "fcError"
            : "",
          )}
          {...bindFirstName}
        />
      </div>
  
      <div className={classNames(styles.dependentForm, "adInputWrap")}>
        <label className="d-block hLabel">
            Last Name
        </label>
        <input
          placeholder="Last name"
          id={lastNameID}
          className={classNames("form-input",
          errors[lastNameID]
            ? "fcError"
            : "",
           )}
          {...bindLastName}
        />
      </div>
      
      <div className={classNames(styles.dependentForm, "adInputWrap")}>
        <label className="d-block hLabel">
            Date of Birth
        </label>
        <input
          type="date"
          id={dobID}
          value={dobValue}
          className={classNames("form-input",
            errors[dobID]
            ? "fcError"
            : "",
           )}
          {...bindDob}
        />
      </div>
    </div>
  );

  export default DependentInput;
