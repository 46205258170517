import React from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../styles/module/successModal.module.scss";
import parse from 'html-react-parser';

const SuccessModal = (props) => {
  const { onClose, open, msg} = props;

  const handleClose = () => {
    onClose(false);
    window.location.replace('/dashboard')
  };
  return (
    <div data-testid="success_modal">
      <Modal
        show={open}
        size="lg"
        className={styles.popupContainer}
        centered
        onHide={handleClose}>
        <Modal.Header className={styles.hedaerAlign}>
          <Modal.Title
            data-testid="success_heading"
            id="contained-modal-title-vcenter"
            className="adddependent_addHeading__vTFZX"
          >
            {props.heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBodyContent} data-testid="success_msg">
          {parse(msg)}
        </Modal.Body>
        <Modal.Footer className={styles.footerStyles}>
          <button type="submit" 
            // className="btn btnPrimary"
            className={styles.btnStylesCancel}
            onClick={()=>{
              handleClose()
            }}>
              Continue
          </button>
        </Modal.Footer>
      </Modal>
    </div>
    
  );
};

export default SuccessModal;
