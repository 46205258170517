import React, {useEffect} from 'react';
import { Image } from "cloudinary-react";
import { Button } from "react-bootstrap";
import logouticn from "../../assets/images/logout.svg";
import renewmem from "../../assets/images/renew-mem.svg";
import upgrademem from "../../assets/images/upgrade-mem.svg";
import logout_json from "../../config/logout/logout.json";
import Cookies from "js-cookie";
import "../../assets/css/logout.css";
import {useAuth} from '../../api/services/auth';
import useStorage from "../../hooks/useStorage";

const Logout = (props) => {
	const { deleteStoredItem } = useStorage();
	useEffect(()=>{
		if(Cookies.get("membersyToken")){
			Cookies.remove("membersyToken");
		}
		deleteStoredItem('bannerHide');
	},[])

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Logout | membersy";  
	}, []);

	return (
		<div className="mainContainer">
			<div className="mainSubContainer">
				<Image src={logouticn} alt="logout-icon" />
				<p className="mainHeading">You have sucessfully logged out</p>
				<Button id ='bck_btn' className="backButton btn btnPrimary" href="/login">
					Back to Login Page
				</Button>   
			</div>
			<p className="onPara">
				On behalf of your participating dental office, we would like to thank you for being a subscriber of the {logout_json.variables.plan_name}. To maintain optimal oral health, the American Dental Association (ADA) recommends regular dental visits, at intervals determined by your dentist. The frequency of your regular dental visits should be tailored by your dentist to accommodate for your current oral health status and health history.
			</p>
			<div className="upgradeContainer">
				<div className="leftContainer">
					<div className="innerContainer">
						<p className="smallIcon">
							<Image src={renewmem} alt="Renew Subscription" />
						</p>
						<p className="mainHeading">Renew your membership: </p>
						<p className="mainPara">
							Your annual subscription will auto-renew every 12 months unless
							you notify us of your intent to cancel. You'll receive future
							renewal notifications via direct mail, email, and a phone call.
						</p>
					</div>
				</div>
				<div className="rightContainer">
					<div className="innerContainer">
						<p className="smallIcon">
							<Image src={upgrademem} alt="Upgrade Subscription" />
						</p>
						<p className="mainHeading">Upgrade your subscription:</p>
						<p className="mainPara">
							You can add additional family members to your subscription
							anytime by logging into the online 
							<a href="/login" >
								<span className="link">member portal</span>
							</a>
							{
							` or calling ${logout_json.variables.logout_phone_number} to speak with a dedicated member
							support specialist.`}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Logout;
