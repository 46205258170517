const useStorage = () => {

    const setItemInStorage = (key, value)  => {
        if(typeof window !== 'undefined') {
            window.sessionStorage.setItem(key, JSON.stringify(value))
        }
    };

    const getStoredItem = (key)  => {
        let value = null
        if(typeof window !== 'undefined') {
            value = JSON.parse(window.sessionStorage.getItem(key))
        }
        return value
    };

    const deleteStoredItem = (key)  => {
        if(typeof window !== 'undefined') {
            window.sessionStorage.removeItem(key);
        }
    };

    return {
        setItemInStorage, getStoredItem, deleteStoredItem
    };
};

export default useStorage;