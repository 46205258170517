import React, { useState, useEffect } from "react";
import styles from "./style.css";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";

const PaymentDetails = (props) => {
  const [userError, setUserError] = useState();
  const [stripeError, setStripeError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  
  const cardElementOptions = {
    style: {
      base: {
        color: stripeError?.message ? "#EF4444" : "#353537",
        fontWeight: 400,
        fontFamily: "Avenir",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        
        ":-webkit-autofill": {
          color: stripeError?.message ? "#EF4444" : "#353537",
        },
        "::placeholder": {
          color: stripeError?.message ? "#EF4444" : "#8A8B8C",
          fontWeight: "400",
          fontSize: "16px",
          textTransform: "capitalize"
        },
        fonts: {
          family: "Avenir",
          src:
            "url(../../../../../assets/fonts/avenirltpromedium.woff)",
          weight: "500",
        },
      },
      invalid: {
        iconColor: "#EF4444",
        color: "#EF4444",
      },
    },
    hidePostalCode: true,
    hideIcon: true
  };

  const handleStripe = async () => {
    const cardElement = elements.getElement(CardElement);

    props.setLoader(true)
    const { source, error } = await stripe.createSource(cardElement, {
      type: "card",
      currency: "usd",
      usage: "reusable",
    });
    props.setCardSource(source);
    if (error) {
      setUserError(null);
      setStripeError(error);
      props.setLoader(false)
    } else {
      setStripeError({});
    }
  };

  useEffect(() => {
    if (props.formSubmitted) {
      handleStripe();
    }
  }, [props.formSubmitted]);

  const showUserError = (event) => {
    if (!event.error) {
      setUserError()
    }
    else if (event.error) {
      setUserError(event.error)
    }
  }
  
  return (
    <div data-testid="payment_details" className={`${stripeError?.message ? "fcError" : ""}`}>
      <h3 className="hLabel">Card Number</h3>
      <div className="formWrapper">
        <CardElement
          placeholder="Card Number"
          options={cardElementOptions}
          onChange={(event) => showUserError(event)}
          className={`stripeInput ${styles.stripeInput} ${stripeError?.message ? "fcError" : ""} `}
        />
      </div>
      {(userError === null && stripeError) && (
        <small className="support-error">{stripeError?.message}</small>
      )}
    </div>
  );
};

export default PaymentDetails;
