import React, { useState, useEffect } from "react";
import "./Footer.css";
import footerLogo from "../../../assets/images/MembersyLogoWhite.svg";
import footerLogoNew from "../../../assets/images/Membersy_Logo_New_White.svg";
import classNames from "classnames";

const Footer = () => {
	const getCurrentYear = () => {
		return new Date().getFullYear();
	};

	const checkPath = window.location.pathname;
	const [paymentRebranding, SetPaymentRebranding] = useState(false);

	useEffect(() => {
		if (checkPath === '/updatebilling' || checkPath === '/updatebilling-success') {
			SetPaymentRebranding(true);
		}
	}, [])

  	return (
		<>
			<div className={classNames(
				'ftAreaWrap',
				(process.env.REACT_APP_PAYMNET_REBRANDING === 'true' && paymentRebranding) && 'rebranding'
			)}
			>
				<aside className="footerAside">
					<div className="container">
						<div className="ftLogo">
							<em className="mlTitle d-block text-center">powered by</em>
							<a href="https://membersy.com">
								<img 
									src={(process.env.REACT_APP_PAYMNET_REBRANDING === 'true' && paymentRebranding) ? footerLogoNew : footerLogo} 
									className="img-fluid" alt="Membersy"
								/>
							</a>
						</div>
						<div className="descrip">
							<p>Dental membership plans are NOT INSURANCE. Plan members pay periodic membership fees in exchange for access to discounts on certain identified dental services rendered by participating providers in accordance with the plan fee schedule. Plan members are obligated to pay dental providers directly for services rendered. Plan details, retail fees and member savings may vary by plan, provider and/or dental office location, please see specific plan terms and conditions for details. Dental membership plans are not qualified health plans under the Affordable Care Act, and do not meet the minimum creditable coverage requirements under M.G.L.c. 111M and 956 CMR 5.00. Plan administered by Membersy LLC, a discount medical plan operator: P.O. Box 163990, Austin, TX 78716-3990; with the exception of dental membership plans offered to consumers in the State of California, which are administered by The CDI Group, Inc., a discount specialized health care plan with offices located at 601 E. Daily Dr., Ste. 215, Camarillo, CA 93010. Plan is not affiliated with or endorsed by any state insurance department. To obtain additional information, please call <a className="cLink" href="tel:8775454188" target="_blank" rel="noopener noreferrer">(877) 545-4188</a> or email questions to <a className="cLink" href="mailto:hello@membersy.com" target="_blank" rel="noopener noreferrer">hello@membersy.com</a>.</p>
						</div>
					</div>
				</aside>
				<footer id="pageFooter" className="text-center text-md-start">
					<div className="container">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-7 order-md-2">
								<ul className="list-unstyled d-md-flex justify-content-end ftList">
									<li>
										<a href="https://membersy.com/marketplace/how-it-works" target="_blank" rel="noopener noreferrer">FAQ</a>
									</li>
									<li>
										<a href="https://membersy.com/marketplace/support" target="_blank" rel="noopener noreferrer">Support</a>
									</li>
									<li>
										<a href="https://membersy.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
									</li>
									<li>
										<a href="https://membersy.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
									</li>
								</ul>
							</div>
							<div className="col-12 col-md-6 col-lg-5 order-md-1">
								<div className="tgRow">
									<div className="copyRightTxt p-0">
										<p>&copy; {getCurrentYear()} - Membersy LLC - All Rights Reserved</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</>
  	);
};

export default Footer;
