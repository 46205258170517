import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import BodyClass from "../../bodyClass";

const Layout = ({ props, children }) => {
    return (
        <div>
            <BodyClass />
            <Header />
                <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
